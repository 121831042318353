import React, { memo, useEffect, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TextField,
  IconButton,
  Grid,
  Autocomplete,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import CheckAndReturn from "../../utility/CheckAndReturn";

//FUNCTION
function AddVoucherList({
  customerList,
  handleAutocompleteChange,
  handleInputChange,
  handleAddCustomer,
  handleDeleteCustomer,
  customers,
  errors,
  handleInvoiceChange,
  payment_mode,
}) {
  return (
    <>
      {/* <div>
                <motion.div
                    style={{
                        width: '100%',
                        backgroundColor: 'transparent',
                        paddingTop: 20,
                        paddingBottom: 0,
                        borderRadius: '10px',
                        border: 'none',
                        margin: '10px 0px 10px 10px',
                    }}
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    <div> */}
      {/* <Grid container>
                            <Link to='/customers'>
                                <Tooltip title={'Add Customers'}>
                                    <AddIcon sx={{ cursor: 'pointer', color: 'white', backgroundColor: 'grey', margin: '5px' }} justifyContent="flex-end" />
                                </Tooltip>
                            </Link>
                        </Grid> */}
      {customerList.map((item, index) => (
        <div key={item.id}>
          <Grid
            container
            alignItems={"center"} //backgroundColor={'#f2f0fc'}
            spacing={1}
            key={item.id}
            sx={{ mb: 0, p: 1 }}
          >
            <Grid item xs={12} sm={6} md={3} columnSpacing={0}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={
                  item.customer_id
                    ? customers.find(
                        (x) => x.id.toString() === item.customer_id.toString()
                      )?.fname
                    : ""
                }
                options={customers.map(
                  (option) => option.fname + "~" + option.address
                )}
                onChange={(e) => handleAutocompleteChange(e, item.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      backgroundColor: "white",
                    }}
                    size="small"
                    label={item.address ?? "Customer"}
                    variant="standard"
                    name="customer_id"
                    value={
                      item.customer_id
                        ? customers.find(
                            (x) =>
                              x.id.toString() === item.customer_id.toString()
                          )?.fname
                        : "a"
                    }
                    onChange={(e) => handleInputChange(e, item.id)}
                    InputLabelProps={{ ...params.InputProps }}
                    error={
                      errors[`${index}`] !== undefined
                        ? !!errors[`${index}`].customer_id
                        : false
                    }
                  />
                )}
              />
            </Grid>
            {item.invoiceList !== null &&
              item.invoiceList !== undefined &&
              // <Grid item xs={12} sm={6} md={2}>
              //     <TextField disabled fullWidth size='small' variant='standard' label="No Invoice" sx={{ backgroundColor: 'white' }} />
              // </Grid>
              item.invoiceList.length > 0 && (
                <>
                  <Grid item xs={12} sm={6} md={2}>
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      name="invoice_number"
                      value={item.invoice_number}
                      options={item.invoiceList.map(
                        (option) => option.invoice_number
                      )}
                      onChange={(e, value) =>
                        handleInvoiceChange(value, item.id)
                      }
                      sx={{ backgroundColor: "white" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="invoice_number"
                          value={item.invoice_number}
                          variant="standard"
                          label="Invoice"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      variant="standard"
                      label="Inv Amt"
                      name="invoice_amount"
                      value={
                        item.invoice_number
                          ? CheckAndReturn.roundToInteger(
                              item.invoiceList.find(
                                (x) =>
                                  x.invoice_number.toString() ===
                                  item.invoice_number.toString()
                              ).total_amount ?? 0
                            )
                          : 0
                      }
                      sx={{ backgroundColor: "white" }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </>
              )}
            {payment_mode !== undefined &&
              payment_mode !== null &&
              payment_mode === 1 && (
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    sx={{
                      backgroundColor: "white", //, border: '1px solid #c4c4c4'
                    }}
                    size="small"
                    label="Trans. Id"
                    variant="standard"
                    type="text"
                    fullWidth
                    name="transaction_id"
                    value={item.transaction_id}
                    onChange={(e) => handleInputChange(e, item.id)}
                    //error={errors[`${index}`] !== undefined ? !!errors[`${index}`].transaction_id : false}
                  />
                </Grid>
              )}
            <Grid item xs={12} sm={6} md={1}>
              <TextField
                sx={{
                  backgroundColor: "white", //, border: '1px solid #c4c4c4'
                }}
                size="small"
                label="Amount"
                variant="standard"
                type="number"
                placeholder="0"
                fullWidth
                name="amount"
                InputLabelProps={{ shrink: true }}
                value={item.amount}
                onChange={(e) => handleInputChange(e, item.id)}
                error={
                  errors[`${index}`] !== undefined
                    ? !!errors[`${index}`].amount
                    : false
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Stack direction={"row"}>
                <Tooltip title={"Add Customer"}>
                  <IconButton onClick={handleAddCustomer}>
                    <AddBoxIcon fontSize="medium" sx={{ color: "green" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Delete Customer"}>
                  <IconButton
                    hidden={customerList.length > 1 ? false : true}
                    onClick={() => handleDeleteCustomer(item.id)}
                  >
                    <DeleteIcon fontSize="medium" sx={{ color: "#d43529" }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </div>
      ))}
      {/* </div>
                </motion.div>
            </div> */}
    </>
  );
}

export default memo(AddVoucherList);
