import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  InputAdornment,
  TextField,
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import NestedTable from './NestedTable';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import configServ from "../../../services/config";
import { useSelector } from "react-redux";
import LoaderNew from "../../Loader/LoaderNew";

const RoleTable = ({ handleEdit, isChanged, pagination = true }) => {
  //#region code
  const { user_id, is_super, is_admin } = useSelector(
    (state) => state.GlobalVariables
  );
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [roleList, setRoleList] = useState([]);
  const [filterRoleList, setFilterRoleList] = useState([]);
  const [searchBy, setSearchBy] = useState("name");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRoles();
  }, [isChanged]);

  const fetchRoles = async () => {
    try {
      const result = await configServ.getRoles({ admin_id: user_id });
      if (result.status === 200) {
        setRoleList(result.data);
        setFilterRoleList(result.data);
        setCount(result.data.length);
      } else {
        setRoleList([]);
        setFilterRoleList([]);
        setCount(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const headers = ["NAME", "EDITABLE", "ACTION"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? filterRoleList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filterRoleList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };
  const evenRowStyle = {
    background: "white",
  };

  const oddRowStyle = {
    background: "#f5f5f5",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === "") {
        data = roleList;
      } else if (searchBy === "name") {
        data = roleList.filter((x) =>
          x.name.toLowerCase().includes(search.toLowerCase())
        );
      }
      setFilterRoleList(data);
      setCount(data.length);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoaderNew />
      </Box>
    );
  } else {
    return (
      <>
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          style={{ paddingLeft: 10, paddingBottom: 10, paddingTop: 0 }}
          spacing={1}
          direction={"row"}
        >
          <Grid item xs={12} md={6} ml={4} justifyContent={"flex-start"}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Role List: {count}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Box sx={{ minWidth: 120, marginLeft: "10px" }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  defaultValue={"fname"}
                  onChange={handleSearchBy}
                  inputProps={{
                    name: "searchBy",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value={"name"}>Role</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            mr={{ xs: 0, md: 4 }}
            justifyContent={"flex-end"}
          >
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: "grey" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ px: 5 }} elevation={0}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index} style={headerCellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    ...rowStyle,
                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                  }}
                >
                  <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.is_super !== 1 ? (
                      <CancelIcon color="error" />
                    ) : (
                      <CheckCircleIcon color="success" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        borderRadius: 10,
                        width: "fit-content",
                        transition: "100ms",
                        cursor: "pointer",
                        padding: 0.5,
                        ":hover": { backgroundColor: "#99999955" },
                        ...((is_super !== 1 || row.is_hidden === 1) && {
                          pointerEvents: "none",
                          opacity: 0.5,
                        }), // Disable if is_super is not 1 or role.is_hidden is 1
                      }}
                      display={Boolean(is_super || is_admin) ? "block" : "none"}
                    >
                      <Tooltip title="Edit" arrow>
                        <EditIcon
                          color="primary"
                          onClick={() => {
                            if (
                              (is_super || is_admin) &&
                              row.is_hidden !== 1
                            ) {
                              handleEdit(row); // Call handleEdit only if conditions are met
                            }
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </>
    );
  }
};

export default RoleTable;
