import React, { useEffect, useState } from "react";
import {
    Stack,
    Grid,
    TextField,
    Button,
    IconButton
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import configServ from "../../../../services/config";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { useSelector } from "react-redux";

const EInvoice = ({ handleClose, edata }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({});
    const [company, setCompany] = useState({});
    const [stcd, setStcd] = useState('');
    const [items, setItems] = useState([]);

    // const handleChange = (e) => {
    //     const { name, value } = e.target
    //     setFormData((state) => ({
    //         ...state,
    //         [name]: value
    //     }))
    // }
    // getStateCodeByname

    const currentDate = () => {
        const currentDate = new Date();

        // Get the current year, month, day, etc.
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
        const day = currentDate.getDate();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        return `${day}/${month}/${year}`
    }

    const getItemsReady = () => {
        const itemData = edata.items.map((item, index) => ({
            "slNo": index + 1,
            "prdDesc": item.item.description,
            "isServc": "N",
            "hsnCd": item.item.hsncode,
            "barcde": null,
            "qty": item.qty,
            "freeQty": null,
            "unit": item.item.unit,
            "unitPrice": item.item.rate_unit,
            "totAmt": item.amount,
            "discount": item.discount,
            "preTaxVal": 1,
            "assAmt": item.amount,
            "gstRt": item.item.tax_rate,
            "igstAmt": item.IGST_amount,
            "cgstAmt": item.CGST_amount,
            "sgstAmt": item.SGST_amount,
            "cesRt": 5,
            "cesAmt": 498.94,
            "cesNonAdvlAmt": 10,
            "stateCesRt": 12,
            "stateCesAmt": 1197.46,
            "stateCesNonAdvlAmt": 5,
            "othChrg": 10,
            "totItemVal": parseFloat(item.amount) + parseFloat(item.IGST_amount) + parseFloat(item.CGST_amount) + parseFloat(item.SGST_amount),
            "ordLineRef": null,
            "orgCntry": null,
            "prdSlNo": "12345"
        }))
        setItems(itemData)
    }

    const getStateCodeByName = async (name) => {
        try {
            const result = await configServ.getStateCodeByname({ 'State': name });
            // console.log(result);
            return result
        } catch (err) {
            console.log(err)
        }
    }

    useState(async () => {
        if (edata.customer.State) {
            // console.log(edata.customer.State)
            try {
                const result = await configServ.getStateCodeByname({ 'State': edata.customer.State });
                // console.log(result);
                setStcd(result)
            } catch (err) {
                console.log(err)
            }
        }
    }, [edata]);

    useEffect(() => {
        fetchCompany()
        getItemsReady()
    }, []);

    const fetchCompany = async () => {
        try {
            const result = await configServ.getCompanyDetails({ 'company_id': company_id });
            setCompany(result)
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleSubmit = async () => {
        const dataToSend = {
            "apiUseId": "nsdlTest",
            "apiPass": "Test@123",
            "invoicePayloadRequest": {
                "version": "1.1",
                "irn": "",
                "cnlRsn": "",
                "cnlRem": "",
                "ewbNo": 0,
                "cancelRsnCode": 0,
                "cancelRmrk": "",
                "tranDtls": {
                    "taxSch": "GST",
                    "supTyp": "B2B",
                    "regRev": "Y",
                    "igstOnIntra": "N"
                },
                "docDtls": {
                    "typ": "INV",
                    "no": "SAN-2",
                    "dt": currentDate()
                },
                "sellerDtls": {
                    "gstin": company.tax,
                    "lglNm": company.CompanyName,
                    "trdNm": company.CompanyName,
                    "addr1": company.Address,
                    "addr2": null,
                    "loc": null,
                    "pin": company.Pincode,
                    "stcd": await getStateCodeByName(company.State),
                    "ph": company.MobileNo,
                    "em": company.CompanyEmail
                },
                "buyerDtls": {
                    "gstin": edata.customer.gstin,
                    "lglNm": edata.customer.contact_name,
                    "trdNm": edata.customer.contact_name,
                    "pos": null,
                    "addr1": edata.customer.address,
                    "addr2": null,
                    "loc": null,
                    "pin": edata.customer.Pin,
                    "stcd": stcd,
                    "ph": edata.customer.mobile,
                    "em": edata.customer.email
                },
                "itemList": items,
                "valDtls": {
                    "assVal": 9978.84,
                    "cgstVal": 0,
                    "sgstVal": 0,
                    "igstVal": 1197.46,
                    "cesVal": 508.94,
                    "stCesVal": 1202.46,
                    "discount": 10,
                    "othChrg": 20,
                    "rndOffAmt": 0.3,
                    "totInvVal": 12908,
                    "totInvValFc": 12897.7
                },
                "ewbDtls": {
                    "irn": "",
                    "transId": "",
                    "transName": "",
                    "distance": 0,
                    "transDocNo": "",
                    "transDocDt": "",
                    "vehNo": "",
                    "vehType": "",
                    "transMode": ""
                }
            }
        }
        const testData = {
            "apiUseId": "nsdlTest",
            "apiPass": "Test@123",
            "invoicePayloadRequest": {
                "version": "1.1",
                "irn": "",
                "cnlRsn": "",
                "cnlRem": "",
                "ewbNo": 0,
                "cancelRsnCode": 0,
                "cancelRmrk": "",
                "tranDtls": {
                    "taxSch": "GST",
                    "supTyp": "B2B",
                    "regRev": "Y",
                    "igstOnIntra": "N"
                },
                "docDtls": {
                    "typ": "INV",
                    "no": "SAN-2",
                    "dt": currentDate()
                },
                "sellerDtls": {
                    "gstin": company.tax,
                    "lglNm": company.CompanyName,
                    "trdNm": null,
                    "addr1": company.Address,
                    "addr2": null,
                    "loc": "GANDHINAGAR",
                    "pin": company.Pincode,
                    "stcd": await getStateCodeByName(company.State),
                    "ph": company.MobileNo,
                    "em": company.CompanyEmail
                },
                "buyerDtls": {
                    "gstin": edata.customer.gstin,
                    "lglNm": edata.customer.contact_name,
                    "trdNm": edata.customer.contact_name,
                    "pos": "12",
                    "addr1": edata.customer.address,
                    "addr2": "kuvempu layout",
                    "loc": "GANDHINAGAR",
                    "pin": edata.customer.Pin,
                    "stcd": stcd,
                    "ph": edata.customer.mobile,
                    "em": edata.customer.email
                },
                "itemList": [
                    {
                        "slNo": "1",
                        "prdDesc": "Rice",
                        "isServc": "N",
                        "hsnCd": "1001",
                        "barcde": "123456",
                        "qty": 100.345,
                        "freeQty": 10,
                        "unit": "BAG",
                        "unitPrice": 99.545,
                        "totAmt": 9988.84,
                        "discount": 10,
                        "preTaxVal": 1,
                        "assAmt": 9978.84,
                        "gstRt": 12.0,
                        "igstAmt": 1197.46,
                        "cgstAmt": 0,
                        "sgstAmt": 0,
                        "cesRt": 5,
                        "cesAmt": 498.94,
                        "cesNonAdvlAmt": 10,
                        "stateCesRt": 12,
                        "stateCesAmt": 1197.46,
                        "stateCesNonAdvlAmt": 5,
                        "othChrg": 10,
                        "totItemVal": 12897.7,
                        "ordLineRef": "3256",
                        "orgCntry": "AG",
                        "prdSlNo": "12345"
                    }
                ],
                "valDtls": {
                    "assVal": 9978.84,
                    "cgstVal": 0,
                    "sgstVal": 0,
                    "igstVal": 1197.46,
                    "cesVal": 508.94,
                    "stCesVal": 1202.46,
                    "discount": 10,
                    "othChrg": 20,
                    "rndOffAmt": 0.3,
                    "totInvVal": 12908,
                    "totInvValFc": 12897.7
                },
                "ewbDtls": {
                    "irn": "",
                    "transId": "",
                    "transName": "",
                    "distance": 0,
                    "transDocNo": "",
                    "transDocDt": "",
                    "vehNo": "",
                    "vehType": "",
                    "transMode": ""
                }
            }
        }
        try {
            const result = await configServ.createEInvoice(testData);
            // console.log(result);
        }
        catch (err) {
            console.log(err)
        }
    }

    const clear = () => {
        setFormData({})
    }
    //#endregion

    return (
        <div>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            size='small'
                            label='IRN Number'
                            name='irn_number'
                            // onChange={handleChange}
                            value={formData.irn_number || ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            size='small'
                            label='ACK Number'
                            name='ack_number'
                            // onChange={handleChange}
                            value={formData.irn_number || ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            type="date"
                            size='small'
                            label='Date'
                            name='date'
                            // onChange={handleChange}
                            value={formData.date || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction='row'
                            justifyContent='flex-end'
                            spacing={2}
                        >
                            {/* <Tooltip title='Reset'>
                                <IconButton onClick={clear} >
                                    <RotateLeftIcon />
                                </IconButton>
                            </Tooltip> */}
                            <Button
                                onClick={() => { handleSubmit(); /*handleClose()*/ }}
                                autoFocus
                                variant='contained'
                                size="large"
                                sx={{
                                    minWidth: {
                                        md: 200
                                    },
                                    width: {
                                        xs: '100%',
                                        md: '15%'
                                    },
                                    fontSize: '0.7rem'
                                }}
                            >
                                Generate E-Invoice
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>

        </div>
    )
}
export default EInvoice