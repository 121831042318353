import React, { memo, useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  FormControlLabel,
  Stack,
  MenuItem,
  Menu,
  TableSortLabel,
  Select,
} from "@mui/material";
import SearchBox from "../../Search/SearchBox";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-hot-toast";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useDispatch, useSelector } from "react-redux";
import configServ from "../../../services/config";
import AlertDialog from "../../AlertDialog/AlertDialog";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import useDeviceDetect from "../../Detect/useDeviceDetect ";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SalesOrderPopUp from "./SalesOrderPopUp";
import Swal from "sweetalert2";
import { rSetShowFab } from "../../../redux/reducers/GlobalVariables";
import Calculation from "../../../utility/Calculations";
import { useNavigate } from "react-router-dom";
import LoaderNew from "../../Loader/LoaderNew";
import DispatchPopup from "../Dispatch/DispatchPopup";
import DispatchSaleOrder from "../Dispatch/DispatchSaleOrder";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useDebounce from "../../hooks/useDebounce";
import { useLocation } from "react-router-dom";
import SalesDispatchDialog from "./SalesDispatchDialog";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import MobileView from "../../views/MobileView";
import DesktopView from "../../views/DesktopView";
import { FmdGood } from "@mui/icons-material";
import MultipleMarkersMap from "../../map/MultipleMarkersMap";
import MapWithMarkers from "../../map/MapWithMarkers";

//FUNCTION
const SalesOrderTable = ({ isChanged, handleEditData, pagination = true }) => {
  //#region code
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company_id, user_id, is_super, is_admin, permissions, companyAddress, companyState, companyCountry } = useSelector(
    (state) => state.GlobalVariables
  );
  const { company_type } = useSelector((state) => state.admin);
  const location = useLocation();
  const {
    isApprovedLocation = false,
    defaultStatus = "all",
    orderData = "",
    searchByName = "partyname",
  } = location.state || {};
  // headers drowpdown option
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [status, setStatus] = useState(defaultStatus);

  const rowsPerPageOptions = [5, 20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState(orderData);
  const debounceSearch = useDebounce(search, 1000);
  const [isApproved, setIsApproved] = useState(isApprovedLocation);
  // const [isApprovedChecked, setIsApprovedChecked] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderDataList, setOrderDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [open, setOpen] = useState(false);
  const [openGstDialog, setOpenGstDialog] = useState(false);
  const currentDate = Calculation.GetCurrentDate();
  // isapproved part
  // Add a state to manage selected checkboxes
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [openDispatch, setOpenDispatch] = useState(false);
  const [dispatchDetail, setDispatchDetail] = useState(null);
  const [statusChecked, setStatusChecked] = useState(false);
  const [searchBy, setSearchBy] = useState(searchByName);
  //const dateRef = useRef(null);
  const remarksRef = useRef(null);

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");

  // dispatch dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedUserData, setSelectedUserData] = React.useState(null);
  const [openMap, setOpenMap] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [companyCoordinate, setCompanyCoordinate] = useState({ lat: 23.3441, lng: 85.3096 })

  const handleStatusClose = () => {
    setStatusAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleStatusSelect = (newStatus) => {
    setStatus(newStatus); // Update displayed status
    setStatusAnchorEl(null); // Close dropdown after selection
  };

  const fetchCompanyGeoLocation = async () => {
    try {
      const address = `${companyAddress}, ${companyState}, ${companyCountry}`;
      const position = await configServ.fetchGeocode(address, companyState, companyCountry);
      setCompanyCoordinate(position);
    }
    catch (e) {
      console.log(e);
    }
  }

  const fetchGeoLocation = async (address, state, country) => {
    try {
      const position = await configServ.fetchGeocode(address, state, country);
      return position; // { lat, lng }
    }
    catch (error) {
      console.error(`Error fetching location for address "${address}":`, error);
      return null; // Return null if geocoding fails for an address
    }
  };

  const fetchGeoLocations = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        order_status: "processed",
      };

      const res = await configServ.getProcessedOrderCustomers(dataToSend);

      if (res.status === 200) {
        const result = await Promise.all(
          res.data.map(async (item) => {
            const position = await fetchGeoLocation(item.full_address, item.State, item.country);
            return {
              id: item.id,
              name: item.fname,
              position,
            };
          })
        );

        setCoordinates(result); // Return or use the result as needed
      }
    }
    catch (e) {
      console.error("Error fetching geo locations:", e);
    }
  };

  useEffect(() => {
    fetchCompanyGeoLocation();
    fetchGeoLocations();
  }, []);

  const closeMap = () => {
    setOpenMap(false);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const sortOrder = isAsc ? "desc" : "asc";
    setOrder(sortOrder);
    if (property !== null && property !== "") {
      setOrderBy(property);
    }
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle 'Select All' checkbox change
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]); // Unselect all
    } else {
      setSelectedRows(paginatedData.map((row) => row.id)); // Select all IDs
    }
    setSelectAll(!selectAll);
  };
  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state
  };

  // Save selected data
  const handleSaveSelected = async () => {
    setLoading(true);
    const dateValue = selectedDate; //dateRef.current.value;

    // Check if the date is filled
    if (!dateValue) {
      toast.error("Please select a date before submitting.");
      return; // Stop the function execution if the date is not provided
    }

    const remarksValue = remarksRef.current.value || null;

    // Filter the selected rows and create the update object
    const selectedData = selectedRows
      .map((selectedId) => {
        const currentRow = paginatedData.find((row) => row.id === selectedId);

        if (currentRow) {
          return {
            id: currentRow.id, // Use the selected row's ID
            updated_at: dateValue, // Use the selected date
            remarks: remarksValue, // Use the remarks, or null if empty
          };
        }
        return null;
      })
      .filter((update) => update !== null); // Filter out any null entries

    if (selectedData.length > 0) {
      const dataToSend = {
        company_id: company_id,
        user_id: user_id,
        sales: selectedData,
      };

      try {
        // Assuming you're using a service to send the data to the API
        const res = await configServ.approveSaleOrders(dataToSend);
        if (res.status === 200) {
          toast.success("Selected data updated successfully!");
          // Refresh or fetch the updated data
          fetchSalesOrderList();
          handleClose(); // Close the dialog after successful submission
        } else {
          toast.error("An error occurred while updating the data.");
          console.error("Failed to update selected data", res);
        }
      } catch (error) {
        console.error("An error occurred while saving selected data", error);
        toast.error("An error occurred while saving the data.");
      } finally {
        setOpenGstDialog(false);
        setLoading(false);
      }

      // Clear the selection after saving
      setSelectedRows([]); // Clear selected rows
      setSelectAll(false); // Reset select all state
    } else {
      toast.error("No rows selected.");
    }
  };

  // Model Started
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const handleClickOpen = () => {
    dispatch(rSetShowFab(false));
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenGstDialog(true); // Updated to use new state
        // Keep showFab false if confirmed
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
        dispatch(rSetShowFab(true)); // Set showFab to true when 'Don't save' is clicked
      } else if (result.isDismissed) {
        // Handle the case where the dialog is dismissed (cancelled)
        dispatch(rSetShowFab(true));
      }
    });
  };

  const handleClose1 = () => {
    setSelectedDate(currentDate);
    setOpenGstDialog(false); // Updated to use new state
  };
  // model end

  // isApproved end

  const [selectedProductData, setSelectedProductData] = useState([]);
  // handleVisibilityIcon started
  const handleVisibilityIcon = (row) => {
    // Assuming row.products contains the product details for that specific row
    const filteredProducts = row.items; // Filter the specific product
    setSelectedProductData(filteredProducts); // Set the filtered product data
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
    setSelectedUserData(null);
  };
  const handleStatusToggle = () => {
    setStatusChecked((prevChecked) => {
      const newChecked = !prevChecked;
      fetchSalesOrderList(newChecked); // Pass updated value to fetchSalesOrderList
      return newChecked;
    });
  };

  const headers = [
    { label: "CUSTOMER", id: "partyname" },
    { label: "ORDER_N0.", id: "order_no" },
    { label: "DATE", id: "order_date" },
    { label: "AMOUNT", id: "total_amount" },
    {
      label: (
        <Box display="flex" alignItems="center">
          <Button
            onClick={handleStatusClick}
            style={{ color: "white", fontWeight: "bold" }}
          >
            {status === "all" ? "STATUS" : status}
            {isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>
          <Menu
            anchorEl={statusAnchorEl}
            open={Boolean(statusAnchorEl)}
            onClose={handleStatusClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem onClick={() => handleStatusSelect("all")}>
              Status
            </MenuItem>
            {isApproved && (
              <>
                <MenuItem onClick={() => handleStatusSelect("processing")}>
                  Processing
                </MenuItem>
                <MenuItem onClick={() => handleStatusSelect("processed")}>
                  Processed
                </MenuItem>
              </>
            )}
          </Menu>
        </Box>
      ),
      id: "",
    },
    ...(company_type === "product" ? [{ label: "DISPATCH", id: "" }] : []),
    ...(is_admin ? [{ label: "APPROVED", id: "approved" }] : []),
    { label: "ACTION", id: "" },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchSalesOrderList();
  }, [isChanged, page]);

  useEffect(() => {
    if (page === 0) {
      fetchSalesOrderList();
    } else {
      setPage(0);
    }
  }, [isApproved, status, rowsPerPage, debounceSearch, orderBy, order]);

  // useEffect(() => {
  //   setIsApproved(isApprovedLocation);
  //   setStatus(defaultStatus);
  // }, [isApprovedLocation, defaultStatus]);
  useEffect(() => {
    // console.log("isApproved:", isApproved);
    // console.log("status:", status);
  }, [isApproved, status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? orderDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : orderDataList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
    //px: 0,
    py: 1,
    justifyContent: "center",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const fetchSalesOrderList = async (statusChecked) => {
    try {
      setLoading(true);
      scrollToTop();

      const dataToSend = {
        company_id: company_id,
        user_id: user_id,
        isApproved: isApproved,
        is_admin: is_admin,
        order_status: status, // "processing" when checked, "" when unchecked
        page: page, // API expects 1-based page numbers
        pageSize: rowsPerPage, // Number of records per page
        searchBy: searchBy, // Either "partyname" or "billtoaddress"
        search: search,
        sortColumn: orderBy,
        sortOrder: order,
      };

      const res = await configServ.salesOrderList(dataToSend);
      if (res.status === 200) {
        const result = res.data.map((item) => ({
          ...item,
          dispatch: item.dispatch && item.dispatch.dispatch ? true : false,
        }));

        setOrderDataList(result);
        setOrderList(result);
        setCount(res.total);
      } else {
        setOrderDataList([]);
        setOrderList([]);
        setCount(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleIsApprovedChange = () => {
    reset();
    setIsApproved(!isApproved);
    if (!isApproved) {
      setSelectAll(false); // Reset "Select All" if "Is Approved" is checked
      setSelectedRows([]); // Clear selected rows when "Is Approved" is checked
    }
  };

  const handlePrint = (params) => {
    printOrder(params);
  };

  const printOrder = async (order_id) => {
    const data = {
      order_id: order_id,
    };
    dispatch(triggerLoader());
    try {
      const result = await configServ.printSalesOrder(data);
      const blobFile = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blobFile);
      window.open(url);
    } catch (e) {
      console.log(e);
      handleOpenDialog("Print failed");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const handleDate = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleOrderToInvoice = (params) => {
    try {
      const data = params;
      navigate("/invoice", { state: { orderToInvoice: data } });
    } catch (e) {
      console.log(e);
    }
  };
  const handleOpenMap = (param) => {
    // console.log("data=", param.customer);
    const latitude = param.customer?.latitude; // Correct syntax
    const longitude = param.customer?.longitude; // Correct syntax
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleDispatch = (params) => {
    try {
      const data = params;
      setDispatchDetail(data);
      openDispatchPopup();
    } catch (e) {
      console.log(e);
    }
  };

  const handlePopFunction = (params) => {
    // alert("This item is already dispatched!");
    const data = params;
    // console.log("order_no=", data.order_no);
    setSelectedUserData(data);
    setOpenDialog(true);
  };

  const openDispatchPopup = () => {
    setOpenDispatch(true);
  };

  const closeDispatchPopup = () => {
    fetchSalesOrderList();
    setOpenDispatch(false);
    setDispatchDetail(null);
  };

  const handleCustomerClick = (params) => {
    try {
      const data = params;
      navigate("/account-master-entry/customers", { state: data });
    } catch (e) {
      console.log(e);
    }
  };

  const reset = () => {
    setStatus("all");
    handleStatusClose(); // Optionally close the menu if it's open
  };

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />

      {/* Dispatch */}
      <DispatchSaleOrder
        openDispatch={openDispatch}
        closeDispatchPopup={closeDispatchPopup}
        dispatchDetail={dispatchDetail}
      />

      {/* dispatch details pop */}
      <SalesDispatchDialog
        open={openDialog}
        handleClose={handleClose}
        order_no={selectedUserData?.order_no}
      />

      {/* Modal/Dialog for Product Details */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Product Details</DialogTitle>
        <SalesOrderPopUp
          handleClose={handleClose}
          selectedProductData={selectedProductData}
        />
      </Dialog>

      {/* <MapWithMarkers /> */}

      <MultipleMarkersMap openMap={openMap} closeMap={closeMap} companyCoordinate={companyCoordinate} coordinates={coordinates} />

      {/* search starts here */}
      <Grid container
        alignItems="center"
        justifyContent="flex-start"
        style={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={2}
          display="flex"
          alignItems="center"
          justifyContent={"flex-start"}
          flexWrap="wrap"
        >
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: "bold",
              //mr: 2,
            }}
          >
            Sales Orders: {count}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}
          display="flex"
          alignItems="center"
          justifyContent={"flex-start"}
          flexWrap="wrap"
        >
          <Button disabled={Boolean(coordinates.length === 0)} variant="contained" color="success" startIcon={<FmdGood />} onClick={() => setOpenMap(true)}>
            Map
          </Button>
        </Grid>

        <Grid item xs={12} md={2}>
          <Box sx={{ minWidth: 120, marginLeft: "10px" }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                value={searchBy}
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"partyname"}>Customer Name</option>
                <option value={"order_no"}>Order No.</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={2}>
          <SearchBox search={search} setSearch={setSearch} />
        </Grid>

        <Grid item xs={12} md={2}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isApproved}
                onChange={handleIsApprovedChange}
              />
            }
            label="Approved"
            sx={{
              //ml: 2,
              fontSize: { xs: "14px", md: "10px" },
            }}
          />

          {selectedRows.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                //ml: { xs: 2, md: 2 },
                width: { xs: "auto", md: "auto" },
                //padding: { xs: "6px 16px", md: "6px 20px" },
                textTransform: "none",
                lineHeight: 1.5,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Approve
            </Button>
          )}
        </Grid>

        {isApproved && (
          <Grid item xs={12} md={2}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              display: { xs: "flex", md: "none" }, // Show only on small screens
            }}
          >
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                value={status} // Current status value
                onChange={(e) => handleStatusSelect(e.target.value)} // Update status on selection
                label="Status"
                size="small"
                sx={{ background: "white", height: 50 }}
              >
                {["processing", "processed", "all"].map(
                  (statusOption, index) => (
                    <MenuItem key={index} value={statusOption}>
                      {statusOption.charAt(0).toUpperCase() +
                        statusOption.slice(1)}{" "}
                      {/* Capitalize */}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Select All Checkbox */}
        {/* Conditionally render the "Select All" checkbox if "Is Approved" is not checked */}
        {!isApproved && Boolean(is_admin) && orderList.length > 0 === true && (
          <Grid item xs={12} md={2} display="flex" justifyContent="flex-end">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              }
              label="Select All"
              sx={{
                ml: 2,
                fontSize: { xs: "14px", md: "16px" },
              }}
            />
          </Grid>
        )}
        {/* <Grid item xs={12} md={2} display="flex" justifyContent="flex-end">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              }
              label="Select All"
              sx={{
                ml: 2,
                fontSize: { xs: "14px", md: "16px" },
              }}
            />
          </Grid> */}
      </Grid>

      {/* search ends here */}

      {/* mobile view sorting only */}
      <Grid
        container
        alignItems={"center"}
        spacing={1}
        mt={1}
        sx={{
          display: { xs: "flex", md: "none" }, // Show on small screens, hide on medium and larger screens
        }}
        padding={2}
      >
        <Grid item xs={6} justifyContent={"center"}>
          <FormControl fullWidth size="small">
            <InputLabel>Sort By</InputLabel>
            <Select
              fullWidth
              size="small"
              name="order_by"
              label="Sort By"
              value={orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
              sx={{ background: "white", height: 50 }}
            >
              {headers
                .filter((item) =>
                  ["partyname", "order_no", "order_date"].includes(item.id)
                )
                .map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} justifyContent={"center"}>
          <FormControl fullWidth size="small">
            <InputLabel>Order</InputLabel>
            <Select
              fullWidth
              size="small"
              name="order"
              label="Order"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              sx={{ background: "white", height: 50 }}
            >
              <MenuItem key={1} value={"asc"}>
                {"Ascending"}
              </MenuItem>
              <MenuItem key={2} value={"desc"}>
                {"Descending"}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <MobileView>
        {" "}
        <Grid container spacing={2}>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <LoaderNew />
            </Box>
          ) : orderDataList.length > 0 ? (
            orderDataList.map((row, rowIndex) => (
              <Grid item xs={12} key={rowIndex}>
                <Card sx={{ p: 2, mb: 2 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {page * rowsPerPage + rowIndex + 1}.{" "}
                      <span
                        className="linkStyle"
                        onClick={() => handleCustomerClick(row.partyname)}
                      >
                        <strong>{row.partyname}</strong>-{row.billtoaddress}
                      </span>
                    </Typography>
                    <Typography>
                      <strong>Order No: </strong> {row.order_no}
                    </Typography>
                    <Typography>
                      <strong>Order Date: </strong>
                      {CheckAndReturn.transformToDate2(
                        row.order_date
                      ).toString() || "N/A"}
                    </Typography>
                    <Typography>
                      <strong>Total Amount: </strong> ₹{row.total_amount}
                    </Typography>
                    <Typography>
                      <strong> Status: </strong> {row.order_status}
                    </Typography>
                    {company_type === "product" && (
                      <Typography>
                        <strong>Dispatch: </strong>
                        {Boolean(row.is_dispatch) === true ? (
                          <span style={{ color: "green" }}>dispatched</span>
                        ) : (
                          <span style={{ color: "red" }}>pending</span>
                        )}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Box
                      sx={{
                        display: Boolean(
                          is_super || is_admin || permissions?.includes(11)
                        )
                          ? "block"
                          : "none", // Show/Hide based on the condition
                      }}
                    >
                      <Tooltip title="Print" placement="top">
                        <IconButton onClick={() => handlePrint(row.id)}>
                          <PrintTwoToneIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      display={
                        Boolean(
                          is_super || is_admin || permissions?.includes(12)
                        )
                          ? "block"
                          : "none"
                      }
                    >
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          disabled={
                            (row.order_status === "processing" &&
                              Boolean(is_admin) === false) ||
                            row.order_status === "processed"
                          }
                          onClick={() => handleEditData(row)}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: Boolean(
                          is_super || is_admin || permissions?.includes(13)
                        )
                          ? "block"
                          : "none", // Show/Hide based on the condition
                      }}
                    >
                      <Tooltip title="See Details" arrow>
                        <IconButton onClick={() => handleVisibilityIcon(row)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: Boolean(
                          is_super || is_admin || permissions?.includes(14)
                        )
                          ? "block"
                          : "none", // Show/Hide based on the condition
                      }}
                    >
                      <Tooltip title="Create Invoice" arrow>
                        <IconButton
                          onClick={() => handleOrderToInvoice(row)}
                          disabled={
                            !(
                              row.order_status === "processing" &&
                              Boolean(is_admin)
                            )
                          }
                        >
                          <ReceiptIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: Boolean(
                          is_super || is_admin || permissions?.includes(14)
                        )
                          ? "block"
                          : "none", // Show/Hide based on the condition
                      }}
                    >
                      <Tooltip title="Map Location" arrow>
                        <IconButton
                          onClick={() => handleOpenMap(row)}
                          disabled={
                            row.customer.latitude == null ||
                            row.customer.longitude == null
                          }
                          color={
                            row.customer.latitude == null ||
                              row.customer.longitude == null
                              ? "disabled"
                              : "success"
                          }
                          style={{
                            cursor:
                              row.customer.latitude == null ||
                                row.customer.longitude == null
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          <GpsFixedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    {(row.order_status === "processed" &&
                      Boolean(is_admin || permissions?.includes(17)) &&
                      company_type === "product") === true && (
                        <Box>
                          <Tooltip>
                            <IconButton
                              color={row.is_dispatch ? "success" : "warning"}
                              onClick={() =>
                                row.is_dispatch
                                  ? handlePopFunction(row)
                                  : handleDispatch(row)
                              }
                            >
                              <LocalShippingIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                  </CardActions>
                  <CardActions align="left">
                    <Checkbox
                      disabled={isApproved || Boolean(!is_admin)}
                      checked={
                        isApproved === false
                          ? selectedRows.includes(row.id)
                          : true
                      }
                      onChange={() => handleCheckboxChange(row.id)}
                    />
                    <label htmlFor="">Approve</label>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12}>
                <Box textAlign={"center"}>
                  <Typography variant="body1" color={"red"}>
                    No data found.
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </Grid>
        {orderDataList && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </MobileView>

      <DesktopView>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh", // Set full height
            // display: { xs: "none", sm: "none", lg: "block" },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                {headers.map((header) => (
                  <TableCell
                    key={header.id}
                    sortDirection={orderBy === header.id ? order : false}
                    sx={headerCellStyle}
                  >
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : "asc"}
                      onClick={() => handleRequestSort(header.id)}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                    <div>
                      <LoaderNew />
                    </div>
                  </TableCell>
                </TableRow>
              ) : orderDataList.length > 0 ? (
                orderDataList.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      ...rowStyle,
                      ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                    }}
                  >
                    <TableCell sx={{ py: 0 }}>
                      {page * rowsPerPage + rowIndex + 1}
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <span
                        className="linkStyle"
                        onClick={() => handleCustomerClick(row.partyname)}
                      >
                        <strong>{row.partyname}</strong>-{row.billtoaddress}
                      </span>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>{row.order_no}</TableCell>
                    <TableCell sx={{ py: 0 }}>
                      {CheckAndReturn.transformToDate2(
                        row.order_date
                      ).toString() || "N/A"}
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>₹{row.total_amount}</TableCell>
                    <TableCell sx={{ py: 0 }}>{row.order_status}</TableCell>
                    {company_type === "product" && (
                      <TableCell sx={{ py: 0 }}>
                        {Boolean(row.is_dispatch) === true ? (
                          <span style={{ color: "green" }}>dispatched</span>
                        ) : (
                          <span style={{ color: "red" }}>pending</span>
                        )}
                      </TableCell>
                    )}
                    {Boolean(is_admin || permissions?.includes(22)) ===
                      true && (
                        <TableCell sx={{ py: 0 }}>
                          <Checkbox
                            disabled={isApproved || Boolean(!is_admin)}
                            checked={
                              isApproved === false
                                ? selectedRows.includes(row.id)
                                : true
                            }
                            onChange={() => handleCheckboxChange(row.id)}
                          />
                        </TableCell>
                      )}
                    {/* <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell> */}
                    <TableCell sx={{ py: 0 }}>
                      <Box
                        sx={{
                          borderRadius: 10,
                          width: "fit-content",
                          transition: "100ms",
                          cursor: "pointer",
                          p: 0,
                          m: 0,
                        }}
                      >
                        <Stack direction={"row"}>
                          <Box
                            sx={{
                              display: Boolean(
                                is_super ||
                                is_admin ||
                                permissions?.includes(12)
                              )
                                ? "block"
                                : "none", // Show/Hide based on the condition
                            }}
                          >
                            <Tooltip title="Print" placement="top">
                              <IconButton onClick={() => handlePrint(row.id)}>
                                <PrintTwoToneIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box
                            display={
                              Boolean(
                                is_super ||
                                is_admin ||
                                permissions?.includes(12)
                              )
                                ? "block"
                                : "none"
                            }
                          >
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                disabled={
                                  (row.order_status === "processing" &&
                                    Boolean(is_admin) === false) ||
                                  row.order_status === "processed"
                                }
                                onClick={() => handleEditData(row)}
                              >
                                <EditIcon
                                  color={
                                    (row.order_status === "processing" &&
                                      Boolean(is_admin) === false) ||
                                      row.order_status === "processed"
                                      ? ""
                                      : "primary"
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box
                            sx={{
                              display: Boolean(
                                is_super ||
                                is_admin ||
                                permissions?.includes(13)
                              )
                                ? "block"
                                : "none", // Show/Hide based on the condition
                            }}
                          >
                            <Tooltip title="See Details" arrow>
                              <IconButton
                                onClick={() => handleVisibilityIcon(row)}
                              >
                                <VisibilityIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              display: Boolean(
                                is_super ||
                                is_admin ||
                                permissions?.includes(14)
                              )
                                ? "block"
                                : "none", // Show/Hide based on the condition
                            }}
                          >
                            <Tooltip title="Create Invoice" arrow>
                              <IconButton
                                onClick={() => handleOrderToInvoice(row)}
                                disabled={
                                  !(
                                    row.order_status === "processing" &&
                                    Boolean(is_admin)
                                  )
                                }
                              >
                                <ReceiptIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              display: Boolean(
                                is_super ||
                                is_admin ||
                                permissions?.includes(15)
                              )
                                ? "block"
                                : "none", // Show/Hide based on the condition
                            }}
                          >
                            <Tooltip title="Map Location" arrow>
                              <IconButton
                                onClick={() => handleOpenMap(row)}
                                disabled={
                                  row.customer.latitude == null ||
                                  row.customer.longitude == null
                                }
                                color={
                                  row.customer.latitude == null ||
                                    row.customer.longitude == null
                                    ? "disabled"
                                    : "success"
                                }
                                style={{
                                  cursor:
                                    row.customer.latitude == null ||
                                      row.customer.longitude == null
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <GpsFixedIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          {(row.order_status === "processed" &&
                            Boolean(is_admin || permissions?.includes(17)) &&
                            company_type === "product") === true && (
                              <Box>
                                <Tooltip>
                                  <IconButton
                                    color={
                                      row.is_dispatch ? "success" : "warning"
                                    }
                                    onClick={() =>
                                      row.is_dispatch
                                        ? handlePopFunction(row)
                                        : handleDispatch(row)
                                    }
                                  >
                                    <LocalShippingIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                        </Stack>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : orderDataList.length > 0 ? (
                orderDataList.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      ...rowStyle,
                      ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                    }}
                  >
                    <TableCell sx={{ py: 0 }}>
                      {page * rowsPerPage + rowIndex + 1}
                    </TableCell>
                    <TableCell sx={{ py: 0 }}><span
                        className="linkStyle"
                        onClick={() => handleCustomerClick(row.partyname)}
                      >{row.partyname}</span>
                      </TableCell>
                    <TableCell sx={{ py: 0 }}>{row.order_no}</TableCell>
                    <TableCell sx={{ py: 0 }}>
                      {CheckAndReturn.transformToDate2(
                        row.order_date
                      ).toString() || "N/A"}
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>₹{row.total_amount}</TableCell>
                    <TableCell sx={{ py: 0 }}>{row.order_status}</TableCell>
                    {Boolean(is_admin) === true && (
                      <TableCell sx={{ py: 0 }}>
                        <Checkbox
                          disabled={isApproved || Boolean(!is_admin)}
                          checked={
                            isApproved === false
                              ? selectedRows.includes(row.id)
                              : true
                          }
                          onChange={() => handleCheckboxChange(row.id)}
                        />
                      </TableCell>
                    )}

                    <TableCell sx={{ py: 0 }}>
                      <Box
                        sx={{
                          borderRadius: 10,
                          width: "fit-content",
                          transition: "100ms",
                          cursor: "pointer",
                          padding: 0.5,
                        }}
                      >
                        <Stack direction={"row"}>
                          <Box
                            sx={{
                              display: Boolean(
                                is_super ||
                                is_admin ||
                                permissions?.includes(11)
                              )
                                ? "block"
                                : "none", // Show/Hide based on the condition
                            }}
                          >
                            <Tooltip title="Print" placement="top">
                              <IconButton onClick={() => handlePrint(row.id)}>
                                <PrintTwoToneIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box
                            display={
                              Boolean(is_super || is_admin) ? "block" : "none"
                            }
                          >
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                disabled={
                                  (row.order_status === "processing" &&
                                    Boolean(is_admin) === false) ||
                                  row.order_status === "processed"
                                }
                                onClick={() => handleEditData(row)}
                              >
                                <EditIcon
                                  color={
                                    (row.order_status === "processing" &&
                                      Boolean(is_admin) === false) ||
                                      row.order_status === "processed"
                                      ? ""
                                      : "primary"
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box>
                            <Tooltip title="See Details" arrow>
                              <IconButton
                                onClick={() => handleVisibilityIcon(row)}
                              >
                                <VisibilityIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box>
                            <Tooltip title="Create Invoice" arrow>
                              <IconButton
                                onClick={() => handleOrderToInvoice(row)}
                                disabled={
                                  !(
                                    row.order_status === "processing" &&
                                    Boolean(is_admin)
                                  )
                                }
                              >
                                <ReceiptIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box>
                            <Tooltip title="Map Location" arrow>
                              <IconButton>
                                <GpsFixedIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          {(row.order_status === "processed" &&
                            Boolean(is_admin) &&
                            company_type === "product") === true && (
                              <Box>
                                <Tooltip>
                                  <IconButton
                                    color="warning"
                                    onClick={() =>
                                      row.is_dispatch
                                        ? handlePopFunction(row)
                                        : handleDispatch(row)
                                    }
                                  >
                                    <LocalShippingIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                        </Stack>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Box textAlign={"center"}>
                      <Typography variant="body1" color={"red"}>
                        No data found.
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {orderDataList && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </DesktopView>

      <BootstrapDialog
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={openGstDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Sales Order
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose1}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form>
            <div>
              <TextField
                size="small"
                required
                type="date"
                label="Date"
                value={selectedDate}
                InputLabelProps={{ shrink: true }}
                onChange={handleDate}
              />
            </div>
            <div style={{ marginTop: "16px" }}>
              <TextField
                id="outlined-basic"
                label="Remarks"
                variant="outlined"
                defaultValue=""
                inputRef={remarksRef}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveSelected}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default memo(SalesOrderTable);
