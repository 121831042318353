import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Stack,
  Tooltip,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Typography,
  Card,
  Grid,
  TableSortLabel,
  FormControl,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import PrintIcon from "@mui/icons-material/Print";
import PaymentIcon from "@mui/icons-material/Payment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustNestedTable from "./CustNestedTable";
import { motion, AnimatePresence } from "framer-motion";
import MiniLoader from "../MiniLoader/MiniLoader";
import InvoiceOrientation from "../../../Components/Dialog/InvoiceOrientation";
import { useSelector } from "react-redux";
import LoaderNew from "../../../Components/Loader/LoaderNew";
import configServ from "../../../services/config";
// data picker started
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import { LicenseInfo } from "@mui/x-license";
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
// data picker end
export default function CustInvoiceTable({}) {
  LicenseInfo.setLicenseKey(
    "e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
  );
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const { id } = useSelector((state) => state.GlobalVariables);
  const { company_type } = useSelector((state) => state.admin);
  const [page, setPage] = React.useState(0);
  const rowsPerPageOptions = [1, 50, 100];
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[1]);
  const [expand, setExpand] = React.useState(false);
  const [expandData, setExpandData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [printData, setPrintData] = React.useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleExpand = (data) => {
    setExpand(!expand);
    setExpandData(data);
  };
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchTableData(searchTerm, property, isAsc ? "desc" : "asc");
  };

  const handlePrint = async (inv) => {
    const data = {
      id: inv.id,
      invoice_no: inv.invoice_number,
      user_id: user_id,
      company_id: company_id,
    };
    setOpen(true);
    setPrintData(data);
  };
  const handleSearch = () => {
    fetchTableData(searchTerm);
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedDateRange("");
    fetchTableData();
  };
  const fetchTableData = async (
    searchTerm = "",
    sortColumn = "id",
    sortOrder = "asc",
    startDate = null,
    endDate = null
  ) => {
    try {
      setLoading(true);
      const dataToSend = {
        user_id: id,
        search: searchTerm,
        sortColumn,
        sortOrder,
        startDate, // Add start date to the request
        endDate, // Add end date to the request
      };
      const result = await configServ.GetCustomerInvoiceList(dataToSend);
      if (result.status === 200) {
        setTableData(result.data);
      } else {
        setTableData([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // Update useEffect if date range changes
  useEffect(() => {
    const [selectedStartDate, selectedEndDate] = selectedDateRange;
    fetchTableData(
      searchTerm,
      orderBy,
      order,
      selectedStartDate ? selectedStartDate.format("YYYY-MM-DD") : null,
      selectedEndDate ? selectedEndDate.format("YYYY-MM-DD") : null
    );
  }, [orderBy, order, selectedDateRange]); // Add selectedDateRange as a dependency
  const headers = [
    { id: "invoice_number", label: "Invoice Number" },
    { id: "date", label: "Invoice Date" },
    { id: "voucher_type", label: "Voucher Type" },
    { id: "total_amount", label: "Total Amount" },
    { id: "payment", label: "Payment" },
    ...(company_type === "product"
      ? [{ id: "dispatch", label: "DISPATCH" }]
      : []),
    { id: "action", label: "Action" }, // Action column won't need sorting
  ];

  const headerCellStyle = {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = tableData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
        padding={2}
      >
       
        <Grid item xs={12} sm={6} md={3}>
          <Select
            value="Invoice Number"
            sx={{ width: "100%", height: 50 }}
            disabled // Static selection for "Invoice Number" as requested
          >
            <MenuItem value="Invoice Number">Invoice Number</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            placeholder="Search by Invoice Number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                height: 50, // Apply height to the input box
              },
            }}
          />
        </Grid>
        <Grid item xs={11} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={selectedDateRange}
              onChange={(newValue) => {
                const [startDate, endDate] = newValue;
                // console.log(
                //   "Start Date:",
                //   startDate ? startDate.format("YYYY-MM-DD") : "null"
                // );
                // console.log(
                //   "End Date:",
                //   endDate ? endDate.format("YYYY-MM-DD") : "null"
                // ); // Logs the selected date range
                setSelectedDateRange(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                  <TextField
                    {...endProps}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <IconButton onClick={handleReset} sx={{ color: "green" }}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
      {/* mobile view sorting only */}
      <Grid
        container
        alignItems={"center"}
        spacing={1}
        mt={1}
        sx={{
          display: { xs: "flex", md: "none" }, // Show on small screens, hide on medium and larger screens
        }}
        padding={2}
      >
        <Grid item xs={6} justifyContent={"center"}>
          <FormControl fullWidth size="small">
            <InputLabel>Sort By</InputLabel>
            <Select
              fullWidth
              size="small"
              name="order_by"
              label="Sort By"
              value={orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
              sx={{ background: "white", height: 50 }}
            >
              {headers.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} justifyContent={"center"}>
          <FormControl fullWidth size="small">
            <InputLabel>Order</InputLabel>
            <Select
              fullWidth
              size="small"
              name="order"
              label="Order"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              sx={{ background: "white", height: 50 }}
            >
              <MenuItem key={1} value={"asc"}>
                {"Ascending"}
              </MenuItem>
              <MenuItem key={2} value={"desc"}>
                {"Descending"}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <InvoiceOrientation open={open} setOpen={setOpen} data={printData} />
      {expand && (
        <CustNestedTable data={expandData} open={expand} setOpen={setExpand} />
      )}
      <TableContainer
        component={Paper}
        sx={{
          display: { xs: "block", sm: "block", lg: "flex" },
          flexDirection: "column",
          minHeight: "100vh",
          mt: 1,
        }}
      >
        {/* Table View for Larger Screens */}
        <Table stickyHeader sx={{ display: { xs: "none", lg: "table" } }}>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyle}>S.No.</TableCell>
              {headers.map((header) => (
                <TableCell
                  key={header.id}
                  sortDirection={orderBy === header.id ? order : false}
                  sx={headerCellStyle}
                >
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : "asc"}
                    onClick={() => handleRequestSort(header.id)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={headers.length + 1}
                  sx={{ textAlign: "center", padding: 2 }}
                >
                  <LoaderNew />
                </TableCell>
              </TableRow>
            ) : paginatedData.length > 0 ? (
              paginatedData.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={
                    index % 2 === 0
                      ? { background: "#f5f5f5" }
                      : { background: "white" }
                  }
                >
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{row.invoice_number}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.voucher_type}</TableCell>
                  <TableCell>{row.total_amount}</TableCell>
                  <TableCell>{row.tcs_amount}</TableCell>
                  {company_type === "product" && (
                    <TableCell>
                      {row.dispatch ? (
                        <span style={{ color: "green" }}>'Dispatched'</span>
                      ) : (
                        <span style={{ color: "red" }}>'Pending'</span>
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Print">
                        <PrintIcon
                          onClick={() => handlePrint(row)}
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                      <Tooltip title="Payment">
                        <PaymentIcon sx={{ cursor: "pointer" }} />
                      </Tooltip>
                      <Tooltip title="View">
                        <VisibilityIcon
                          onClick={() => handleExpand(row)}
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headers.length + 1}
                  sx={{ textAlign: "center" }}
                >
                  Data Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* Card View for Mobile Screens */}
        <Box display={{ xs: "block", lg: "none", padding: 2 }}>
          {loading ? (
            <Box sx={{ textAlign: "center", padding: 2 }}>
              <LoaderNew />
            </Box>
          ) : paginatedData.length > 0 ? (
            paginatedData.map((row, index) => (
              <Card
                key={row.id}
                sx={{
                  mb: 2,
                  backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                  boxShadow: 2,
                  p: 2,
                }}
              >
                <Typography variant="subtitle1">
                  S.No: {page * rowsPerPage + index + 1}
                </Typography>
                <Typography>Invoice Number: {row.invoice_number}</Typography>
                <Typography>Date: {row.date}</Typography>
                <Typography>Voucher Type: {row.voucher_type}</Typography>
                <Typography>Total Amount: {row.total_amount}</Typography>
                <Typography>TCS Amount: {row.tcs_amount}</Typography>
                {company_type === "product" && (
                  <Typography sx={{ color: row.dispatch ? "green" : "red" }}>
                    {row.dispatch ? "Dispatched" : "Pending"}
                  </Typography>
                )}
                <Stack direction="row" spacing={1} mt={1}>
                  <Tooltip title="Print">
                    <PrintIcon
                      onClick={() => handlePrint(row)}
                      sx={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                  <Tooltip title="Payment">
                    <PaymentIcon sx={{ cursor: "pointer" }} />
                  </Tooltip>
                  <Tooltip title="View">
                    <VisibilityIcon
                      onClick={() => handleExpand(row)}
                      sx={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Stack>
              </Card>
            ))
          ) : (
            <Box sx={{ textAlign: "center", padding: 2 }}>Data Not Found</Box>
          )}
        </Box>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
