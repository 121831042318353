import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Paper, Stack, Typography, Grid } from "@mui/material";
import { SessionVerificationAdmin } from "../Components/SessionManagement/SessionManagementAdmin";
import Header from "../Components/header/Header/Header";
import Sidebar from "../Components/sidebar/SideBar";
import useDeviceDetect from "../Components/Detect/useDeviceDetect ";
import SideBarRedesign from "../Components/sidebar/SideBarRedesign";
import FloatingActionButton from "./FloatingActionButton/FloatingActionButton";
import { useDispatch } from "react-redux";
import { rSetShowFab } from "../redux/reducers/GlobalVariables";
import { Link, Outlet, useLocation } from "react-router-dom";
import Footer from "../Components/Footer/Footer";

const AdminLayout = () => {
  const { isMobile } = useDeviceDetect();
  const dispatch = useDispatch();
  const location = useLocation(); // Get current location
  //#region code
  const open = useSelector((state) => state.sideExpand.open);
  const { showFab, isLogin } = useSelector((state) => state.GlobalVariables);

  useEffect(() => {
    dispatch(rSetShowFab(true));
  }, [location]);
  //#endregion
  
  return (
    <>
      <SessionVerificationAdmin />

      {/* {isMobile ? ( */}
        <Box display={{xs:'block',sm:'none'}}>
          {" "}
          <SideBarRedesign />
          {showFab && <FloatingActionButton />} {/* Conditionally render FAB */}
        </Box>
       {/* ) : ( */}
        <Box display={{xs:'none',sm:'block'}}>
          {" "}
          <Header />
          <Sidebar />
        </Box>
       {/* )} */}

      {/* {isMobile ? (
        <>
          {" "} */}
          <Box display={{xs:'block',sm:'none'}}>
            {/* Adjust the Main component to account for the sidebar */}
            <Box
              sx={{
                flexGrow: 1,
                marginLeft: open ? "0px" : "0px", // Adjust based on sidebar state
                transition: "margin 0.2s ease", // Smooth transition
                padding: "16px", // Add padding to content
                minHeight: "77vh",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  mt: 1,
                  mx: 0,
                  my: 0,
                  //display: "flex",
                  //flexDirection: "column",
                  //justifyContent: "center",
                  //alignItems: "center",
                  px: 0,
                  pt: 10,
                  pb: 0,
                }}
              >
                <Outlet />
              </Paper>
            </Box>
          </Box>
          {/* Full Width Footer */}
          {/* <Footer
            sx={{
              width: "100vw", // Full viewport width
              backgroundColor: "#f5f5f5", // Footer background color
              textAlign: "center", // Center the content inside the footer
              // py: 2, // Padding for spacing
            }}
          /> */}
        {/* </>
      ) : (
        <> */}
          <Box
            display={{xs:'none',sm:'block'}}
            sx={{
              marginLeft: {
                xs: 7,
                sm: 8,
              },
              ...(open && { marginLeft: 33 }),
              transition: "200ms",
              minHeight: "77vh", // This ensures the content takes up enough space
              //display: "flex",
              //flexDirection: "column", // Use flexbox to arrange child elements
              //justifyContent: "space-between", // Space out the content and footer
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              //sx={{ flexGrow: 1 }} // Allow the Stack to grow and take available space
            >
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  mx: 5,
                  my: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  px: 3,
                  pt: 0,
                  pb: 5,
                }}
              >
                <Outlet />
              </Paper>
            </Stack>

            {/* Full Width Footer */}
            {/* <Footer
              sx={{
                position: "fixed", // Fixes the footer at the bottom
                bottom: 0, // Aligns the footer to the bottom
                left: 0, // Ensures the footer starts from the left edge
                width: "100vw", // Full viewport width
                backgroundColor: "#f5f5f5", // Footer background color
                textAlign: "center", // Center the content inside the footer
                py: 2, // Padding for spacing
                zIndex: 10, // Ensures it stays above other content
              }}
            /> */}
          </Box>
        {/* </>
      )} */}
    </>
  );
};

export default AdminLayout;
