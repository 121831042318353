import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom"; // Assuming you are using react-router
import logo from "../../Components/Assets/images/siteLogoWhite.svg";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useSelector, useDispatch } from "react-redux";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";
import Logout from "../Logout/Logout";
import SellIcon from "@mui/icons-material/Sell";

export default function CustomerSideBarReBuild({ open, toggleSidebar }) {
  const [isLogutDialogOpen, setIsLogutDialogOpen] = React.useState(false);

  const { company_type } = useSelector((state) => state.admin);
  const handleLogout = () => {
    console.log("hello");
    setIsLogutDialogOpen(!isLogutDialogOpen);
  };
  return (
    <>
      <Logout open={isLogutDialogOpen} setOpen={setIsLogutDialogOpen} />
      <Drawer
        open={open}
        onClose={toggleSidebar}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "rgb(51,51,51)", // Set entire drawer background to black
            color: "white", // Ensure text color is white throughout
          },
        }}
      >
        <List>
          {/* Logo at the top */}
          <ListItem>
            <img
              src={logo} // Replace with your logo URL
              alt="Company Logo"
              width="150"
              style={{
                margin: "16px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }} // Center the logo
            />
          </ListItem>

          {/* Menu Items */}
          <ListItem
            button
            component={Link}
            to="/customer-dashboard"
            onClick={toggleSidebar}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/customer-invoice"
            onClick={toggleSidebar}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Invoice" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/product"
            onClick={toggleSidebar}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText
              primary={company_type === "service" ? "Services" : "Products"}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/Purchase-Order"
            onClick={toggleSidebar}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Purchase Order" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/customer-payment"
            onClick={toggleSidebar}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Payment" />
          </ListItem>
          <div hidden={company_type === "product"}>
            <ListItem
              button
              component={Link}
              to="/customer-sales-subscription"
              onClick={toggleSidebar}
            >
              <ListItemIcon sx={{ color: "white" }}>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Sales Subscription" />
            </ListItem>
          </div>

          <ListItem
            button
            component={Link}
            to="/customer-profile"
            onClick={toggleSidebar}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/customer-change-password"
            onClick={toggleSidebar}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <PasswordIcon />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/customer-outstanding-reports"
            onClick={toggleSidebar}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <SellIcon />
            </ListItemIcon>
            <ListItemText primary="My Ledgers" />
          </ListItem>

          <ListItem
            onClick={() => {
              toggleSidebar();
              handleLogout();
            }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
