import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

//Admin routes
import Home from "./pages/homePage/Home";
import AdminLogin from "./pages/loginPage/AdminLogin";
import MasterEntery from "./pages/masterEntery/MasterEntry";
//nested
import Batch from "./Components/masterEntry/Batch";
import Category from "./Components/masterEntry/Category";
import Group from "./Components/masterEntry/Group";
import Units from "./Components/masterEntry/Units";
import Brand from "./Components/masterEntry/Brand";
import HSN from "./Components/masterEntry/HSN";
import Item from "./Components/masterEntry/Item";
import Dashboard from "./pages/dashboardPage/Dashboard";
import Loader from "./Components/Loader/Loader";
import Profile from "./pages/Profile/Profile";
import Configuration from "./pages/Configuration/Configuration";
import Customers from "./pages/Customers/Customers";
import Supplier from "./pages/Supplier/Supplier";
import AccountMaster from "./pages/AccountMaster/AccountMaster";
import Transaction from "./pages/Transaction/Transaction";
//nested
import DebitNote from "./pages/Transaction/DebitNote/DebitNote";
import CreditNote from "./pages/Transaction/CreditNote/CreditNote";
import PurchaseBill from "./pages/Transaction/PurchaseBill/PurchaseBill";
import PurchaseOrder from "./pages/Transaction/PurchaseOrder/PurchaseOrder";
import OutstandingReport from "./pages/Transaction/OutstandingReport/OutstandingReport";
import CreateReceipts from "./pages/Transaction/CreateReceipts/CreateReceipts";
import Invoice from "./pages/Invoice/Invoice";
import SalesOrder from "./pages/SalesOrder/SalesOrder";
import ItemAdvance from "./Components/masterEntry/ItemEditOptions/ItemAdvance";
import Location from "./pages/Location/Location";
import Subscription from "./pages/Subscription/Subscription";
import SubscriptionOrder from "./pages/Subscription/SubscriptionOrder";
import SubscriptionDispatch from "./pages/Subscription/SubscriptionDispatch";
import Catalog from "./pages/Catalog/Catalog";
import LocationAdvance from "./pages/Location/LocationAdvance/LocationAdvance";
import Welcome from "./pages/Welcome/Welcome";
import SuperCategory from "./pages/SuperCategory/SuperCategory";
import AdminPay from "./pages/Transaction/OutstandingReport/AdminPay";
import CityMaster from "./pages/CityMaster/CityMaster";
import Registration from "./pages/Registration/Registration";
import AccessMaster from "./pages/AccessMaster/AccessMaster";
import ProductInfo from "./pages/ProductInfo/ProductInfo";
import PaymentAndReceipt from "./pages/Transaction/Voucher/Voucher";
import UnregisterPayment from "./pages/UnregisterPayment/UnregisterPayment";
import NotFound from "./Components/NotFound/NotFound";
import Dispatch from "./pages/Dispatch/Dispatch";
import AdminLayout from "./Layout/AdminLayout";
import JournalEntry from "./pages/Transaction/JournalEntry/JournalEntry";
import AdminReport from "./pages/Report/AdminReport";
import GstInvoiceReport from "./Components/report/GstInvoiceReport";
import RegistrationForm from "./pages/RegistrationForm/RegistrationForm";
import GstTransactionReport from "./Components/report/GstTransactionReport";
import AccountMasterEntry from "./pages/masterEntery/AccountMasterEntry";
import StockUpdate from "./Components/Card/MaterEntryCards/StockUpdate";
import SideBarRedesign from "./Components/sidebar/SideBarRedesign";
import SalesReport from "./Components/report/SalesReport/SalesReport";
import SalesSummaryReport from "./Components/report/SalesReport/SalesSummaryReport";
import SalesSummary from "./Components/report/SalesReport/SalesSummary";
import UserLayout from "./Layout/CustomerLayout/UserLayout";
import AllLedgersReport from "./pages/Report/allLedgersReport/AllLedgersReport";

//Customer Routes
import CustDashboard from "./Customer/Pages/CustDashboard/CustDashboard";
import CustInvoice from "./Customer/Pages/CustInvoice/CustInvoice";
import CustProfile from "./Customer/Pages/CustProfile/CustProfile";
import CustChangePassword from "./Customer/Pages/CustChangePassword/CustChangePassword";
import CustSalesSubscription from "./Customer/Pages/CustSalesSubscription/CustSalesSubscription";
import CustPayment from "./Customer/Pages/CustPayment/CustPayment";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import Notify from "./Customer/Pages/Notify/Notify";
import Product from "./Customer/Pages/Product/Product";
import CustCart from "./Customer/Pages/CustCart/CustCart";
import ProductDetails from "./Customer/Pages/Product/ProductDetails";
import CustPurchaseOrder from "./Customer/Pages/CustPurchaseOrder/CustPurchaseOrder";
import Pay from "./Customer/Pages/CustPayment/Pay";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import UserHistoryTable from "./pages/UserHistory/UserHistoryTable";
import OutstandingTable from "./Customer/Pages/Outstanding Report/OutstandingTable";
import TrialBalanceReport from "./Components/report/trialBalance/TrialBalanceReport";
import LeadsPage from "./pages/leads/LeadsPage";

//FUNCTION
function App() {
  const isLoaderActive = useSelector((state) => state.loader.isLoaderActive);

  return (
    <>
      <Toaster />
      {isLoaderActive && <Loader />}

      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/Registration-Form" element={<RegistrationForm />} />
        <Route path="/Siderbarredisgn" element={<SideBarRedesign />} />

        //#region ADMIN ROUTES
        {/* ADMIN ROUTES Begin */}

        <Route path="/admin-login" element={<AdminLogin />} />
        {/* <Route path='/register' element={<Register />} /> */}
        <Route path="/forgetPassword" element={<ForgetPassword />} />

        <Route element={<AdminLayout />}>

          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/master-entry" element={<MasterEntery />}>
            <Route path="batch" element={<Batch />} />
            <Route path="category" element={<Category />} />
            <Route path="group" element={<Group />} />
            <Route path="units" element={<Units />} />
            <Route path="brand" element={<Brand />} />
            <Route path="hsn" element={<HSN />} />
            <Route path="item" element={<Item />} />
            <Route path="item/advance" element={<ItemAdvance />} />
            <Route path="super-category" element={<SuperCategory />} />
            <Route path="location" element={<Location />} />
            <Route path="catalog" element={<Catalog />} />
          </Route>

          <Route path="/invoice" element={<Invoice />} />
          <Route path="/sales-order" element={<SalesOrder />} />
          <Route path="/subscriptions" element={<Subscription />} />

          <Route path="/account-master-entry" element={<AccountMasterEntry />}>
            <Route path="account-master" element={<AccountMaster />} />
            <Route path="customers" element={<Customers />} />
            <Route path="leads" element={<LeadsPage />} />
            <Route path="supplier" element={<Supplier />} />
          </Route>

          <Route path="/transaction" element={<Transaction />}>
            <Route path="create-vouchers" element={<PaymentAndReceipt />} />
            <Route path="journal-entry" element={<JournalEntry />} />
            <Route path="debit-note" element={<DebitNote />} />
            <Route path="credit-note" element={<CreditNote />} />
            <Route path="purchase-bill" element={<PurchaseBill />} />
            <Route path="purchase-order" element={<PurchaseOrder />} />
            <Route path="create-receipts" element={<CreateReceipts />} />
            <Route path="stockUpdate" element={<StockUpdate />} />
            <Route path="dispatch" element={<Dispatch />} />
            <Route path="Gst-transaction-report" element={<GstTransactionReport />} />
          </Route>

          <Route path="/report" element={<AdminReport />}>
            <Route path="outstanding-report" element={<OutstandingReport />} />
            <Route path="gst-invoice-report" element={<GstInvoiceReport />} />
            <Route path="sales-report" element={<SalesReport />} />
            <Route path="sales-summary-report" element={<SalesSummaryReport />} />
            <Route path="sales-summary" element={<SalesSummary />} />
            <Route path="trial-balance-report" element={<TrialBalanceReport />} />
            <Route path="all-ledgers" element={<AllLedgersReport />} />
            <Route path="user-log-history" element={<UserHistoryTable />} />

          </Route>

          <Route path="/register-company" element={<Registration />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/access-master" element={<AccessMaster />} />
    
          <Route path="/subscription" element={<Subscription />} />

          <Route path="/subscriptions-order" element={<SubscriptionOrder />} />

          <Route path="/subscriptions-dispatch" element={<SubscriptionDispatch />} />

          <Route path="/unregister-payment" element={<UnregisterPayment />} />

          <Route path="/change-password" element={<ChangePassword />} />

        {/* <Route path="/accessMaster" element={<AccessMaster />} />
        <Route path="/updateProduct" element={<UpdateProduct />} /> */}

          {/* <Route path="/report" element={<Report />}>
          <Route path="srs" element={<SRS />} />
          <Route path="sri" element={<SRI />} />
          <Route path="customer-report" element={<CustomerReport />} />
          <Route path="order-report" element={<OrderReport />} />
          <Route path="purchase-report" element={<PurchaseReport />} />
        </Route> */}

        </Route>

        {/* ADMIN ROUTES End */}
        //#endregion

        <Route path="/location/advance" element={<LocationAdvance />} />
        
        <Route
          path="/transaction/outstanding-report/pay"
          element={<AdminPay />}
        />
        <Route path="/city" element={<CityMaster />} />


        //#region CUSTOMER ROUTES
        {/* Customer Routes Begin */}

        <Route path="/" element={<Welcome />} />

        <Route path="/login" element={<Home />} />

        <Route element={<UserLayout />}>
          <Route path="/customer-dashboard" element={<CustDashboard />} />
          <Route path="/customer-invoice" element={<CustInvoice />} />
          <Route path="/product" element={<Product />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/Purchase-Order" element={<CustPurchaseOrder />} />
          <Route path="/customer-payment" element={<CustPayment />} />
          <Route path="/customer-payment/pay" element={<Pay />} />
          <Route path="/customer-profile" element={<CustProfile />} />
          <Route path="/cart" element={<CustCart />} />
          <Route path="/customer-sales-subscription" element={<CustSalesSubscription />} />
          <Route path="/customer-change-password" element={<CustChangePassword />} />
          <Route path="/customer-Notifications" element={<Notify />} />
          <Route path="/customer-outstanding-reports" element={<OutstandingTable />} />
        </Route>

        {/* Customer Routes End */}
        //#endregion

        {/* QR Routes */}
        <Route path="/product-info" element={<ProductInfo />} />
      </Routes>
    </>
  );
}

export default App;
