import { DisabledByDefault, RotateLeft, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckAndReturn from "../../utility/CheckAndReturn";
import configServ from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../redux/reducers/LoaderTrigger";
import Calculation from "../../utility/Calculations";
import CancelIcon from "@mui/icons-material/Cancel";

// FUNCTION
const LeadsForm = ({ open, setOpen, editData = null, setEditData }) => {
  //#region code
  const dispatch = useDispatch();
  const { company_id, user_id } = useSelector((state) => state.GlobalVariables);
  const initialFormData = {
    lead_date: Calculation.GetCurrentDate(),
    is_active: true,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [validationErrors, setValidationErrors] = useState({});
  const [category, setCategory] = useState([]);

  useEffect(() => {
    if (editData !== null) {
      setFormData(editData);
    }
  }, [editData]);

  const fetchCategory = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: null,
        search: null,
        per_page: null,
        page: null,
      };
      const res = await configServ.getcategory(dataToSend);
      if (res.status === 200) {
        const categoryResponse = res.data.filter((x) => x.status === 1);
        setCategory(categoryResponse);
      } else {
        setCategory([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (open) {
      fetchCategory();
    }
  }, [open]);

  const handleChange = (e) => {
    try {
      const { name, type, value, checked } = e.target;

      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));

      if (validationErrors[name]) {
        setValidationErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validate = async () => {
    try {
      let errors = {};
      if (CheckAndReturn.checkError(formData.name)) {
        errors.name = "required";
      }
      if (CheckAndReturn.checkError(formData.mobile)) {
        errors.mobile = "required";
      }
      if (CheckAndReturn.checkError(formData.lead_date)) {
        errors.lead_date = "required";
      }
      if (CheckAndReturn.checkError(formData.enquiry_for)) {
        errors.enquiry_for = "required";
      }
      if (CheckAndReturn.checkError(formData.source)) {
        errors.source = "required";
      }
      setValidationErrors(errors);
      return Object.values(errors).every((error) => error === "");
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    try {
      dispatch(showLoader());
      e.preventDefault();
      const isValid = await validate();
      if (isValid === false) {
        return isValid;
      }
      const dataToSend = {
        ...formData,
        user_id,
        company_id,
      };
      if (editData === null) {
        const res = await configServ.createLead(dataToSend);
        if (res.status === 200) {
          handleClose();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.message || "Lead created successfully.",
          });
        }
      } else if (editData !== null) {
        const res = await configServ.updateLead(dataToSend);
        if (res.status === 200) {
          handleClose();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.message || "Lead updated successfully.",
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleReset = () => {
    try {
      setFormData(initialFormData);
      setValidationErrors({});
      setEditData(null);
      setCategory([]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    try {
      handleReset();
      setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };
  const handleCut = () => {
    // setIsCreate(!isCreate);
    setOpen(false);
    handleReset();
  };

  //#endregion

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ backgroundColor: "#e3f3fc", padding: 0 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <IconButton sx={{ fontSize: "36px" }} onClick={handleClose}>
              <DisabledByDefault color="error" fontSize="36px" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#e3f3fc" }}>
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <Grid container alignItems={"center"} spacing={2} my={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  label="Name"
                  name="name"
                  value={formData.name || ""}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white" }}
                  error={!!validationErrors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  label="Mobile"
                  name="mobile"
                  value={formData.mobile || ""}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white" }}
                  error={!!validationErrors.mobile}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  label="Address"
                  name="address"
                  value={formData.address || ""}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="date"
                  fullWidth
                  size="small"
                  label="Date"
                  name="lead_date"
                  value={formData.lead_date || ""}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white" }}
                  InputLabelProps={{ shrink: true }}
                  error={!!validationErrors.lead_date}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel error={!!validationErrors.enquiry_for}>
                    Enquiry For
                  </InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    label="Enquiry For"
                    name="enquiry_for"
                    value={formData.enquiry_for || ""}
                    onChange={handleChange}
                    sx={{ backgroundColor: "white" }}
                    error={!!validationErrors.enquiry_for}
                  >
                    <MenuItem key={0} value={""}>
                      --Select--
                    </MenuItem>
                    {category.map((item, index) => (
                      <MenuItem key={index} value={item.categoryname}>
                        {item.categoryname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel error={!!validationErrors.source}>
                    Source
                  </InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    label="Source"
                    name="source"
                    value={formData.source || ""}
                    onChange={handleChange}
                    sx={{ backgroundColor: "white" }}
                    error={!!validationErrors.source}
                  >
                    <MenuItem key={0} value={""}>
                      --Select--
                    </MenuItem>
                    <MenuItem key={1} value={"WhatsApp"}>
                      WhatsApp
                    </MenuItem>
                    <MenuItem key={2} value={"Facebook"}>
                      Facebook
                    </MenuItem>
                    <MenuItem key={3} value={"LinkedIn"}>
                      LinkedIn
                    </MenuItem>
                    <MenuItem key={3} value={"News Articles"}>
                      News Articles
                    </MenuItem>
                    <MenuItem key={3} value={"Other"}>
                      Other
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  label="Remark"
                  name="remarks"
                  value={formData.remarks || ""}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid container item xs={12} md={6} justifyContent={"flex-start"}>
                <FormControlLabel
                  control={<Checkbox />}
                  name="is_active"
                  checked={Boolean(formData.is_active)}
                  onChange={handleChange}
                  label="Status"
                  sx={{ fontWeight: "bold" }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                justifyContent={"space-between"}
              >
                <Grid item xs={5.5}>
                  {editData === null ? (
                    <>
                      <Button
                        type="reset"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        startIcon={<RotateLeft />}
                      >
                        Reset
                      </Button>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={handleCut}
                        sx={{
                          borderRadius: 2, // Rounded corners
                          px: 2, // Horizontal padding
                          py: 1, // Vertical padding
                          backgroundColor: "red", // Base red background
                          color: "white", // Icon color
                          "&:hover": {
                            backgroundColor: "darkred", // Darker red on hover for better contrast
                          },
                          boxShadow: 2, // Add a subtle shadow for depth
                          ml: 1, // Margin left to separate from other elements
                          transition: "all 0.3s ease", // Smooth transition for hover effect
                        }}
                        title="close"
                      >
                        <CancelIcon sx={{ fontSize: 18 }} />{" "}
                      </IconButton>
                    </>
                  )}
                </Grid>
                <Grid item xs={5.5}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<Save />}
                  >
                    {editData === null ? "Save" : "Update"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LeadsForm;
