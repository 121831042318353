import React, { useState, useEffect } from "react";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import CustHeader from '../../../Components/header/Header/CustHeader'
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import Footer from "../../../Components/Footer/Footer";
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion'
import CustSalesSubsTable from "../../Component/CustSalesSubscription/CustSalesSubsTable";
import configServ from "../../../services/config";

const CustSalesSubscription = () => {
    //#region code
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open);
    const [tableData, setTableData] = useState([]);

    const fetchTableData = async () => {
        try {
            const result = await configServ.getsubs({ "user_id": user_id })
            // console.log(result)
            setTableData(result)
        } catch (err) {
            console.log(err)
        }
    }

    const rows = [
        {
            id: 1,
            category_name: 'Primary',
            description: '',
            derive_unit: '',
            conversion_factor: '',
            status: true
        }
    ]

    useEffect(() => {
        fetchTableData()
    }, []);
    //#endregion

    return (
        <>
            <motion.h4 style={{ textAlign: 'center' }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1 }}
            >
                Subscription List
            </motion.h4>
            <Stack direction='column' justifyContent='flex-start' width='100%' my={2}>
                <Divider />
            </Stack>
            {rows !== undefined && (<CustSalesSubsTable rows={tableData} />)}
            <Footer />
        </>
    )
};
export default CustSalesSubscription;