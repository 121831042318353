import React, { memo } from "react";
import {
    TextField,
    Autocomplete,
    Grid,
    Box,
    IconButton,
    Typography,
} from "@mui/material";
import { Delete, Queue } from "@mui/icons-material";
import CheckAndReturn from "../../utility/CheckAndReturn";

// FUNCTION
const SubscriptionProduct = ({ products, setProducts, itemData }) => {
    //#region code
    const calculateAmounts = (rate, discount, tax, quantity) => {
        const discountedRate = CheckAndReturn.roundToInteger(rate - (rate * discount) / 100);
        const taxAmount = CheckAndReturn.roundToInteger((discountedRate * tax) / 100);
        const totalAmount = CheckAndReturn.roundToInteger((discountedRate + taxAmount) * quantity);

        return { tax_amount: taxAmount, total_amount: totalAmount };
    };

    const addRow = () => {
        setProducts((prevRows) => [
            ...prevRows,
            { item_id: null, rate: 0, discount: 0, tax: 0, quantity: 1, unit: null, tax_amount: 0, total_amount: 0 },
        ]);
    };

    const removeRow = (index) => {
        if (products.length > 1) {
            setProducts((prevRows) => prevRows.filter((_, i) => i !== index));
        }
    };

    const handleItemChange = (index, value) => {
        if (!value) {
            setProducts((prevRows) =>
                prevRows.map((row, i) =>
                    i === index
                        ? { item_id: null, rate: 0, discount: 0, tax: 0, quantity: 1, unit: null, tax_amount: 0, total_amount: 0 }
                        : row
                )
            );
            return;
        }

        const { item_id, rate, unit, discount, tax } = value;
        const quantity = products[index].quantity || 1;
        const { tax_amount, total_amount } = calculateAmounts(rate, discount, tax, quantity);

        setProducts((prevRows) =>
            prevRows.map((row, i) =>
                i === index
                    ? { item_id, rate, discount, tax, quantity, unit, tax_amount, total_amount }
                    : row
            )
        );
    };

    const handleFieldChange = (index, field, value) => {
        const updatedValue = value === "" ? "" : parseFloat(value) || 0;

        setProducts((prevRows) => {
            const updatedRow = { ...prevRows[index], [field]: updatedValue };

            // Use 0 for calculations if the value is an empty string
            const rate = parseFloat(updatedRow.rate) || 0;
            const discount = parseFloat(updatedRow.discount) || 0;
            const tax = parseFloat(updatedRow.tax) || 0;
            const quantity = parseFloat(updatedRow.quantity) || 0;

            const { tax_amount, total_amount } = calculateAmounts(rate, discount, tax, quantity);

            return prevRows.map((row, i) =>
                i === index
                    ? { ...updatedRow, tax_amount, total_amount }
                    : row
            );
        });
    };

    //#endregion

    return (
        <Box>
            <Grid container spacing={2} mt={0.2}>
                <Grid container item xs={12} ml={1} mb={1}>
                    <Typography variant="body1" fontWeight={600}>Item Detail</Typography>
                </Grid>
                {products.map((row, index) => (
                    <React.Fragment key={index}>
                        <Grid container alignItems={'center'} spacing={1} ml={1.5} mb={1}>
                            <Grid item xs={12} md={3}>
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    name="item_id"
                                    options={itemData}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.item_id === value.item_id}
                                    value={itemData.find((item) => item.item_id === row.item_id) || null} // Ensure value is controlled
                                    onChange={(event, value) => handleItemChange(index, value)}
                                    renderInput={(params) => (
                                        <TextField
                                            name="item_id"
                                            {...params}
                                            label="Item"
                                            fullWidth
                                            sx={{ backgroundColor: "white" }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <TextField
                                    label="Rate(₹)"
                                    name="rate"
                                    value={row.rate}
                                    type="number"
                                    onChange={(e) =>
                                        handleFieldChange(index, "rate", e.target.value)
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <TextField
                                    label="Disc(%)"
                                    name="discount"
                                    value={row.discount}
                                    type="number"
                                    onChange={(e) =>
                                        handleFieldChange(index, "discount", e.target.value)
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <TextField
                                    label="Tax(%)"
                                    name="tax"
                                    value={row.tax}
                                    type="number"
                                    onChange={(e) =>
                                        handleFieldChange(index, "tax", e.target.value)
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <TextField
                                    label={row.unit? row.unit:"QTY"}
                                    name="quantity"
                                    value={row.quantity}
                                    type="number"
                                    onChange={(e) =>
                                        handleFieldChange(index, "quantity", e.target.value)
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    label="Tax Amt(₹)"
                                    name="tax_amount"
                                    value={row.tax_amount.toFixed(2)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    sx={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    label="Total Amt(₹)"
                                    name="total_amount"
                                    value={row.total_amount.toFixed(2)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    sx={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}
                                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <IconButton
                                    onClick={addRow}
                                    sx={{
                                        backgroundColor: "white",
                                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                                        marginRight: 1,
                                    }}
                                >
                                    <Queue color="info" />
                                </IconButton>
                                <IconButton
                                    onClick={() => removeRow(index)}
                                    sx={{
                                        backgroundColor: "white",
                                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                                    }}
                                    disabled={products.length === 1}
                                >
                                    <Delete color="error" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
};

export default memo(SubscriptionProduct);
