import React, { useEffect, useState } from "react";
import QRCode from 'qrcode.react';
import {
    Typography,
    Stack,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Box,
    Card,
    CardMedia,
    CircularProgress,
    IconButton,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import PrintIcon from '@mui/icons-material/Print';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import configServ from "../../../services/config";
import { useSelector } from "react-redux";
import AlertDialog from "../../AlertDialog/AlertDialog";
import AddLineItem from "./AddLineItem";
import DeactivateLineItem from "./DeactivateLineItem";
import PrintLineItem from "../../print/PrintLineItem";
import PrintLineItems from "../../print/PrintLineItems";

//FUNCTION
const LineItemList = ({ itemData, isChangedItem, pagination = true }) => {
    //#region code
    const { company_id, user_id, is_admin, is_super } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [5, 10, 50];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [lineItems, setLineItems] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [isChanged, setIsChanged] = useState({});
    const [deactivateOpen, setDeactivateOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [openPrintQR, setOpenPrintQR] = useState(false);
    const [openPrintQRs, setOpenPrintQRs] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchBatch = async () => {
        try {
            const res = await configServ.getBatch({ "company_id": company_id });
            if (res.status === 200) {
                setBatchList(res.data);
            }
            else {
                setBatchList([]);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchBatch();
    }, []);

    const fetchLineItems = async () => {
        try {
            setLoading(true);
            const data = {
                "company_id": company_id,
                "product_id": itemData.id
            };
            const result = await configServ.getLineItems(data);
            if (result.status === 200) {
                setLineItems(result.data);
            }
            else {
                setLineItems([]);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchLineItems();
    }, [isChanged]);

    useEffect(() => {
        fetchLineItems();
    }, [isChangedItem]);

    const headers = ['QR CODE', 'UUID', 'BATCH NO.', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, lineItems.length - page * rowsPerPage);

    const paginatedData = pagination
        ? lineItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : lineItems;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5', // Set the header background color to blue
        color: 'white',
        padding: '5px'
    };

    const evenRowStyle = {
        background: 'white',
    };

    const oddRowStyle = {
        background: '#f5f5f5',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleBatch = (params) => {
        try {
            if (params) {
                const batch = batchList.filter(x => x.id === params)[0];
                return batch.batch_name;
            }
            else {
                return 'N/A';
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleQRPrint = async (params) => {
        try {
            setSelectedItem({
                "product_line_item_id": params.id,
                "product_id": params.product_id,
                "product_name": itemData.item_name,
                "batch_id": params.batch,
                "company_id": company_id,
                "uuid": params.uuid,
                "deactivated_by": user_id
            });
            setOpenPrintQR(true);
        }
        catch (e) {
            console.error(e);
        }
    };

    const handleQRsPrint = () => {
      try{
        setOpenPrintQRs(true);
      }
      catch(e){
        console.log(e);
      }
    }
    
    const handleUpdate = (params) => {
        try {
            setSelectedItem({
                "product_line_item_id": params.id,
                "product_id": params.product_id,
                "product_name": itemData.item_name,
                "batch_id": params.batch,
                "company_id": company_id,
                "uuid": params.uuid,
                "deactivated_by": user_id
            });
            setDeactivateOpen(true);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleDeactivate = () => {
        try {
            setDeactivateOpen(false);
            setSelectedItem({});
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleChange = () => {
        try {
            setIsChanged(!isChanged);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePrintClose = () => {
        setOpenPrintQR(false);
        setOpenPrintQRs(false);
    }
    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "200px" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <>
                <AlertDialog
                    open={dialogOpen}
                    setOpen={handleCloseDialog}
                    title="Alert"
                    message={dialogContent}
                    buttonTitle="Ok"
                />

                {/* Print QR */}
                <Dialog
                    open={openPrintQR}
                    onClose={handlePrintClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <div style={{ margin: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <PrintLineItem selectedItem={selectedItem} handleClose={handlePrintClose} />
                        </div>
                    </DialogContent>
                </Dialog>

                {/* Print QRs */}
                <Dialog
                    fullScreen
                    open={openPrintQRs}
                    onClose={handlePrintClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <div style={{ margin: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <PrintLineItems lineItems={lineItems} itemData={itemData} handleClose={handlePrintClose} />
                        </div>
                    </DialogContent>
                </Dialog>

                {/* <Dialog
                    fullScreen
                    open={openPrintQRs}
                    onClose={handlePrintClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <div style={{ margin: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <PrintLineItems lineItems={lineItems} itemData={itemData} handleClose={handlePrintClose} />
                        </div>
                    </DialogContent>
                </Dialog> */}

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    width='100%'
                >
                    <DeactivateLineItem deactivateOpen={deactivateOpen} handleDeactivate={handleDeactivate} selectedItem={selectedItem} isChanged={handleChange} />

                    <Grid container alignItems={'center'} justifyContent={'space-between'} sx={{backgroundColor: 'black', color:'white'}}>
                        <Grid container item xs={12} md={6} justifyContent={"center"} padding={1}>
                            <Typography variant="button"><b>Product Name:  {itemData.item_name}</b></Typography>
                        </Grid>
                        <Grid container item xs={12} md={6} justifyContent={"center"} padding={1}>
                            <Tooltip title="Settings" placement="top" arrow>
                                <IconButton>
                                    <SettingsIcon sx={{color:'white'}} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Print" placement="top" arrow>
                                <IconButton onClick={handleQRsPrint}>
                                    <PrintIcon sx={{color:'white'}} />
                                </IconButton>
                            </Tooltip>

                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} sx={{ minWidth: '90vw' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={headerCellStyle}>S.No.</TableCell>
                                    {headers.map((header, index) => (
                                        <TableCell key={index} style={headerCellStyle}>
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}
                                        sx={{
                                            ...rowStyle,
                                            ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                            p: 0
                                        }}
                                    >
                                        <TableCell sx={{}}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                        <TableCell sx={{ p: 0 }}>
                                            <IconButton>
                                                <QrCode2Icon sx={{ fontSize: '3rem' }} />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell sx={{ p: 0 }}>{row.uuid}</TableCell>
                                        <TableCell sx={{ p: 0 }}>{handleBatch(row.batch)}</TableCell>
                                        <TableCell sx={{ p: 0 }}>
                                            <Box display={Boolean(is_super || is_admin) ? 'block' : 'none'}>
                                                <Tooltip title='Edit' arrow>
                                                    <IconButton onClick={() => handleUpdate(row)}>
                                                        <EditIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Print' arrow>
                                                    <IconButton onClick={() => handleQRPrint(row)}>
                                                        <PrintIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 1 * emptyRows }}>
                                        <TableCell colSpan={headers.length + 2} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {pagination && (
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                component="div"
                                count={lineItems.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </TableContainer>

                </Stack>

            </>
        )
    }
}
export default LineItemList;