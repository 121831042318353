import React, { useState, useEffect } from "react";
import Footer from "../../../Components/Footer/Footer";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import CustHeader from "../../../Components/header/Header/CustHeader";
import { motion } from "framer-motion";

import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import CustPaymentTable from "../../Component/CustPayment/CustPaymentTable";
import configServ from "../../../services/config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "animate.css";
import { useLocation } from "react-router-dom";

const CustPayment = () => {
  //#region code
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const [tableData, setTableData] = useState([]);
  const [miniLoader, setMiniLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { payment = "null" } = location.state || {};

  const fetchTableData = async () => {
    setMiniLoader(true);
    try {
      const dataToSend = {
        customer_id: user_id,
        company_id: company_id,
      };
      const result = await configServ.getCustomerUnregisterPayments(dataToSend);
      // console.log(result.data);
      setTableData(result.data);
      setMiniLoader(false);
    } catch (err) {
      console.log(err);
      setMiniLoader(false);
    }
  };
  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    if (payment === "yes") {
      // Show SweetAlert when the page loads
      Swal.fire({
        title: "Welcome!",
        html: `
          <p>Your payment request has been successfully submitted and is currently pending approval. Thank you for your patience!</p>
          <p>आपका भुगतान अनुरोध सफलतापूर्वक जमा कर दिया गया है और यह अनुमोदन के लिए लंबित है। आपकी सहनशीलता के लिए धन्यवाद!</p>
        `,
        icon: "info",
        showClass: {
          popup: "animate__animated animate__fadeInUp animate__faster",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutDown animate__faster",
        },
      });

      // Fetch table data
      fetchTableData();
    }
  }, [payment]);

  const goToPay = () => {
    navigate("pay");
  };
  //#endregion

  return (
    <>
      <motion.h4
        style={{ textAlign: "center" }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.1 }}
      >
        Payment List
      </motion.h4>
      <Stack width="100%" direction="row" justifyContent="flex-start">
        <Button variant="contained" onClick={goToPay}>
          Pay
        </Button>
      </Stack>
      <Stack direction="column" justifyContent="flex-start" width="100%" my={2}>
        <Divider />
      </Stack>
      {tableData !== undefined && (
        <CustPaymentTable tableData={tableData} miniLoader={miniLoader} />
      )}

      {/* <Footer /> */}
    </>
  );
};
export default CustPayment;
