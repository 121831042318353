import React, { memo, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Checkbox,
  Box,
  InputAdornment,
  Grid,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  CircularProgress,
  TableSortLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import configServ from "../../../services/config";
import SearchBox from "../../Search/SearchBox";
import useDebounce from "../../hooks/useDebounce";
import LoaderNew from "../../Loader/LoaderNew";
import { useLocation, useNavigate } from "react-router-dom";

//FUNCTION
const VoucherTable = ({ handleEditData, isChanged }) => {
  //#region code
  const { user_id, company_id, is_super, is_admin, permissions } = useSelector(
    (state) => state.GlobalVariables
  );

  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [dispatchList, setDispatchList] = useState([]);
  const [searchBy, setSearchBy] = useState("customer_name");
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleCustomerClick = (params) => {
    try {
      const data = params;
      navigate("/account-master-entry/customers", { state: data });
    } catch (e) {
      console.log(e);
    }
  };

  const handleInvoiceClick = (params) => {
    try {
      const data = params;
      navigate("/invoice", { state: { invoiceData: data } });
    } catch (e) {
      console.log(e);
    }
  };
  const handleOrderNoClick = (params) => {
    try {
      const data = params;
      navigate("/sales-order", {
        state: {
          orderData: data,
          isApprovedLocation: true,
          searchByName: "order_no",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchDispatchList();
  }, [isChanged, page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    if (page === 0) {
      fetchDispatchList();
    } else {
      setPage(0);
    }
  }, [debounceSearch]);

  const fetchDispatchList = async () => {
    try {
      setLoading(true);
      const dataToSend = {
        company_id: company_id,
        search_by: searchBy,
        search: debounceSearch,
        per_page: rowsPerPage,
        page: page,
        sort_by: orderBy,
        order,
      };
      const res = await configServ.getDispatch(dataToSend);
      if (res.status === 200) {
        setDispatchList(res.data);
        setCount(res.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const headers = [
    { id: "fname", label: "Customer" },
    { id: "invoice_no", label: "Invoice No" },
    { id: "order_no", label: "Order No" },
    { id: "transporter_name", label: "Transporter" },
    { id: "Date", label: "Date" },
    { id: "amount", label: "Amount" },
  ];

  const headerCellStyle = {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const bodyCellStyle = {
    py: 0,
    my: 1,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (params) => {
    //const data = dispatchList.find(params);
    handleEditData(params);
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //#endregion

  return (
    <Paper sx={{ width: "100%" }}>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ mb: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={6} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Dispatch List: {count}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                defaultValue={"customer_name"}
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"customer_name"}>Customer</option>
                {/* <option value={'voucher_type'}>Voucher Type</option> */}
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} justifyContent={"flex-end"}>
          <SearchBox search={search} setSearch={setSearch} />
        </Grid>
      </Grid>

      {/* desktop view table */}

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh", // Set full height
          display: { xs: "none", sm: "none", lg: "block" },
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyle}>S.No.</TableCell>
              {headers.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={headerCellStyle}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell sx={headerCellStyle}>Action</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableRow>
              <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                <div>
                  <LoaderNew />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              {dispatchList.length > 0 ? (
                dispatchList.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        ...rowStyle,
                        ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell sx={bodyCellStyle}>
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell sx={bodyCellStyle}>
                        <span
                          className="linkStyle"
                          onClick={() => handleCustomerClick(row.fname)}
                        >
                          {row.fname} -{" "}
                        </span>

                        <span style={{ color: "gray" }}>{row.address}</span>
                      </TableCell>
                      <TableCell sx={bodyCellStyle}>
                        <span
                          className="linkStyle"
                          onClick={() => handleInvoiceClick(row.invoice_no)}
                        >
                          {row.invoice_no}
                        </span>
                      </TableCell>
                      <TableCell sx={bodyCellStyle}>
                        <span
                          className="linkStyle"
                          onClick={() => handleOrderNoClick(row.order_no)}
                        >
                          {" "}
                          {row.order_no}
                        </span>{" "}
                      </TableCell>
                      <TableCell sx={bodyCellStyle}>
                        {row.transporter_name}
                      </TableCell>
                      <TableCell sx={bodyCellStyle}>
                        {CheckAndReturn.transformToDate3(row.Date)}
                      </TableCell>
                      <TableCell sx={bodyCellStyle}>₹{row.amount}</TableCell>
                      <TableCell sx={bodyCellStyle}>
                        <Box
                          sx={{
                            borderRadius: 10,
                            width: "fit-content",
                            transition: "100ms",
                            cursor: "pointer",
                            padding: 0.5,
                            ":hover": { backgroundColor: "#99999955" },
                          }}
                          onClick={() => handleEdit(row)}
                          display={
                            Boolean(
                              is_super || is_admin || permissions.includes(41)
                            )
                              ? "block"
                              : "none"
                          }
                        >
                          <EditIcon color="primary" />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                    <Box sx={{ color: "red", fontWeight: 600 }}>
                      No data found.
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* mobile view table */}
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "block", sm: "block", md: "none", lg: "none" }, // Show on small devices
        }}
      >
        {loading ? ( // Step 3: Check if loading
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: "center",
                marginTop: 2,
              }}
            >
              <LoaderNew /> {/* Loading spinner */}
            </Box>
          </Grid>
        ) : dispatchList.length > 0 ? (
          dispatchList.map((row, index) => (
            <Grid item xs={12} key={row.id}>
              <Paper elevation={2} sx={{ padding: 2 }}>
                <Stack direction="column" spacing={2}>
                  <Box sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    {index + 1}.{" "}
                    <span
                      className="linkStyle"
                      onClick={() => handleCustomerClick(row.fname)}
                    >
                      {row.fname} -{" "}
                    </span>
                    <span style={{ color: "gray" }}>{row.address}</span>
                  </Box>
                  <Box>
                    <strong>Invoice No:</strong>{" "}
                    <span
                      className="linkStyle"
                      onClick={() => handleInvoiceClick(row.invoice_no)}
                    >
                      {row.invoice_no}
                    </span>
                  </Box>
                  <Box>
                    <strong>Order No:</strong>
                    <span
                      className="linkStyle"
                      onClick={() => handleOrderNoClick(row.order_no)}
                    >
                      {row.order_no}
                    </span>
                  </Box>
                  <Box>
                    <strong>Transporter Name:</strong> {row.transporter_name}
                  </Box>
                  <Box>
                    <strong>Date:</strong>{" "}
                    {CheckAndReturn.transformToDate3(row.Date)}
                  </Box>
                  <Box>
                    <strong>Amount:</strong> ₹{row.amount}
                  </Box>
                  <Box
                    display={
                      Boolean(is_super || is_admin || permissions?.includes(41))
                        ? "block"
                        : "none"
                    }
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                    >
                      <EditIcon
                        sx={{
                          borderRadius: 10,
                          transition: "100ms",
                          cursor: "pointer",
                          ":hover": { backgroundColor: "#99999955" },
                        }}
                        color="primary"
                        onClick={() => handleEdit(row)}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Box
              sx={{
                color: "red",
                fontWeight: 600,
                textAlign: "center",
                marginTop: 2,
              }}
            >
              No data found.
            </Box>
          </Grid>
        )}
      </Grid>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default memo(VoucherTable);
