import React, { useEffect, useState } from "react";
import {
    Typography,
    Stack,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Box
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import configServ from "../../../../services/config";
// import AddItemSize from "./AddItemSize";
import Neighbourhood from "./Neighbourhood";
import { useSelector } from "react-redux";

const NeighbourhoodList = ({ itemData, pagination = true, isEdited, setIsEdited }) => {

    const { company_id, is_admin, is_super } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [neighbourhoodList, setNeighbourhoodList] = useState([]);
    const [editData, setEditData] = useState({});
    const [openAddSize, setOpenAddSize] = useState(false)

    const handleCloseSize = () => {
        setOpenAddSize(false);
    };

    const handleOpenSize = () => {
        setOpenAddSize(true);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    const fetchNeighbourhood = async () => {
        try {
            const result = await configServ.getNeighbourhood({'location_id':itemData.id})
            // console.log(result)
            setNeighbourhoodList(result)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchNeighbourhood()
    }, [isEdited])


    const headers = ['NAME', 'TYPE', 'DESCRIPTION', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, neighbourhoodList.length - page * rowsPerPage);

    const paginatedData = pagination
        ? neighbourhoodList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : neighbourhoodList;

    const headerCellStyle = {
        fontSize: 13, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5',
        color: 'white',
    };

    const handleEdit = (data) => {
        setEditData(data)
        setOpenAddSize(true)
        // console.log(data)
    }



    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
            >
                <Neighbourhood itemData={itemData} handleClose={handleCloseSize} open={openAddSize} editData={editData} isEdited={isEdited} setIsEdited={setIsEdited} />

                <TableContainer component={Paper} sx={{ px: 5, minWidth: '70vw' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell>{row.place}</TableCell>
                                    <TableCell>{row.neighbourhood_type?.name}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>
                                        <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}
                                            onClick={() => { handleOpenSize(); handleEdit(row) }}
                                            display={Boolean(is_super || is_admin) ? 'block' : 'none'}
                                        >
                                            <Tooltip title='Edit' arrow>
                                                <EditIcon />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 1 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={neighbourhoodList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>

            </Stack>

        </>
    )
}
export default NeighbourhoodList