import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import configServ from "../../../services/config";
import StateList from "../../../Components/List/StateList";
import ProfilePicture from "../../../Components/Assets/images/profilePicture.jpg";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-hot-toast";

function CustProfile() {
  const { user_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const [formData, setFormData] = useState({});
  const [isEditable, setIsEditable] = useState(false); // Track if the form is editable
  const [profileImage, setProfileImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    // Check if file is selected
    if (!file) {
      toast.error("No file selected!");
      return;
    }

    // Validate file type
    const validTypes = ["image/jpeg", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      toast.error("Only JPG and JPEG images are allowed!");
      return;
    }

    // Validate file size (200KB = 200 * 1024 bytes)
    const maxSize = 200 * 1024;
    if (file.size > maxSize) {
      toast.error("File size must not exceed 200KB!");
      return;
    }

    try {
      // Convert to Base64 if validation passes
      const base64Image = await convertToBase64(file);
      setProfileImage(file);
      setSelectedImage(base64Image);
      toast.success("Image uploaded successfully!");
    } catch (error) {
      toast.error("Failed to upload image. Please try again.");
    }
  };

  const submitHandler = async () => {
    try {
      const dataToSend = {
        ...formData,
        profile: selectedImage,
        user_id: user_id,
      };
      // console.log("formData=", dataToSend);
      const response = await configServ.updateCustomerProfileDetails(
        dataToSend
      );
      if (response.status === 200) {
        await fetchUser();
        toast.success("profile updated successfully");
        clear();
      }
    } catch (error) {
      toast.error("Failed to update profile details");
    }
  };
  const clear = () => {
    setProfileImage(null); // Clear the file input state
    setSelectedImage(null); // Clear the Base64 preview
  };

  const fetchUser = async () => {
    try {
      const dataToSend = {
        user_id: user_id,
      };
      const res = await configServ.profiledetail(dataToSend);
      if (res.status === 200) {
        setFormData(res.data);
      } else {
        setFormData({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <Box>
        <Stack
          direction={{
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
          }}
          justifyContent="space-around"
          alignItems={{ md: "flex-start", sm: "center", xs: "center" }}
          sx={{
            padding: 2,
          }}
          spacing={2}
        >
          <Paper
            elevation={1}
            sx={{
              width: {
                md: "55%",
              },
              padding: 4,
            }}
          >
            <Typography color="#333" variant="body" fontSize="large">
              My account
            </Typography>
            <br />
            <Divider />
            <br />
            <Stack direction="column" spacing={2} sx={{ alignItems: "left" }}>
              <Typography
                variant="body"
                fontSize="small"
                color="text.secondary"
              >
                USER INFORMATION
              </Typography>
              <br />
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Username"
                  name="username"
                  value={formData.user_name || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: true, // Toggle readOnly based on isEditable
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Email Address"
                  type="email"
                  name="email"
                  value={formData.email || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: true, // Toggle readOnly based on isEditable
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Name"
                  name="name"
                  value={formData.name || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: !isEditable, // Toggle readOnly based on isEditable
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Mobile"
                  type="number"
                  name="mobile"
                  value={formData.mobile || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: true, // Toggle readOnly based on isEditable
                  }}
                />
              </Stack>
              <Divider />
              <Typography
                variant="body"
                fontSize="small"
                color="text.secondary"
              >
                CONTACT INFORMATION
              </Typography>
              <br />
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Address"
                  name="address"
                  value={formData.address || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: !isEditable, // Toggle readOnly based on isEditable
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <FormControl fullWidth size="small">
                  <InputLabel>State</InputLabel>
                  <Select
                    disabled={!isEditable} // Disable when not editable
                    name="state"
                    label="Select"
                    value={formData.state || ""}
                    onChange={changeHandler}
                  >
                    {StateList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  size="small"
                  label="Pin"
                  type="number"
                  name="pincode"
                  value={formData.pincode || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: !isEditable, // Toggle readOnly based on isEditable
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  sx={{
                    width: {
                      xs: "50%",
                      sm: "30%",
                      md: "20%",
                    },
                  }}
                  onClick={() => {
                    if (isEditable) {
                      submitHandler(); // Call submitHandler if in editable mode
                    }
                    setIsEditable((prev) => !prev); // Toggle edit mode
                  }}
                >
                  {isEditable ? <SaveIcon /> : <EditIcon />}{" "}
                  {/* Use icons instead of text */}
                </Button>

                {isEditable && (
                  <Button
                    variant="outlined"
                    sx={{
                      width: {
                        xs: "50%",
                        sm: "30%",
                        md: "20%",
                      },
                      marginTop: 2,
                    }}
                    onClick={() => {
                      setIsEditable(false); // Exit edit mode
                      clear();
                      fetchUser(); // Reset form data to original values
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Stack>
            </Stack>
          </Paper>
          <Paper
            sx={{
              width: {
                md: "35%",
              },
              padding: 4,
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <img
                src={
                  selectedImage // Locally uploaded image preview (Base64)
                    ? selectedImage
                    : formData.image && formData.image !== "0" // Existing URL in formData
                    ? formData.image
                    : ProfilePicture // Default profile picture
                }
                alt="Profile"
                width="50%"
                style={{
                  borderRadius: 150,
                }}
              />

              <input
                type="file"
                style={{
                  border: "1px solid #33333355",
                  width: "80%",
                }}
                onChange={handleImageChange}
                disabled={!isEditable} // Disable input if not editable
                InputProps={{
                  readOnly: !isEditable, // Ensure it's read-only if not editable
                }}
              />
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </>
  );
}

export default CustProfile;
