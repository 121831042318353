import React, { memo, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
  Stack,
  TableSortLabel,
  Button,
  IconButton,
  CardContent,
  Card,
  CardActions,
  Select,
  MenuItem,
  Menu,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useEffect } from "react";
import { styled } from "@mui/system";
import configServ from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "../Search/SearchBox";
import CheckAndReturn from "../../utility/CheckAndReturn";
import { hideLoader, showLoader } from "../../redux/reducers/LoaderTrigger";
import useDebounce from "../hooks/useDebounce";
import FollowUpPopup from "../followUp/FollowUpPopup";
import bell_notification from "../Assets/images/bell_notification.gif";
import DownloadCustomersTablePdf from "../Download/DownloadCustomersTablePdf";
import DownloadCustomersTableExcel from "../Download/DownloadCustomersTableExcel";
import Swal from "sweetalert2";
import LoaderNew from "../Loader/LoaderNew";
import { PersonAdd } from "@mui/icons-material";
import DownloadLeadsPdf from "../Download/DownloadLeadsPdf";
import DownloadLeadExcel from "../Download/DownloadLeadExcel";

//FUNCTION
const LeadsTable = ({ handleEdit, isChanged }) => {
  //#region code
  const dispatch = useDispatch();

  const {
    company_id,
    companyName,
    companyAddress,
    companyState,
    companyCountry,
    is_super,
    is_admin,
    user_id,
    permissions
  } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [leadList, setLeadList] = useState([]);
  const [searchBy, setSearchBy] = useState("name");
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [followup, setFollowup] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false); // Controls PDF generation state
  const [companyLogo, setCompanyLogo] = useState("");
  const isMenuOpen = Boolean(anchorEl);
  const [filteredReport, setFilteredReport] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchLeadList();
  }, [isChanged, page]);

  useEffect(() => {
    if (page === 0) {
      fetchLeadList();
    }
    else {
      setPage(0);
    }
  }, [debounceSearch, rowsPerPage, followup]);

  const fetchLeadList = async () => {
    try {
      setLoading(true);
      const dataToSend = {
        company_id: company_id,
        search_by: searchBy,
        search: debounceSearch,
        per_page: rowsPerPage,
        page: page,
        sort_by: orderBy,
        order,
      };
      const res = await configServ.getLeads(dataToSend);
      if (res.status === 200) {
        setLeadList(res.data);
        setCount(res.total);
      } else {
        setLeadList([]);
        setCount(0);
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleDownloadExcel = async () => {
    await downloadExcel();
  };

  const fetchDownloadReport = async () => {
    try {
      // Prepare the data to send
      const dataToSend = {
        company_id: company_id,
        // If filteredReport is true, include the filters; otherwise, don't include them at all
        ...(filteredReport && { search_by: searchBy }),
        ...(filteredReport && { search: debounceSearch }),
        ...(filteredReport && { per_page: rowsPerPage }),
        ...(filteredReport && { page: page }),
      };

      // Call the API
      const res = await configServ.getLeads(dataToSend);
      if (res.status === 200) {
        const result = res.data;
        return result;
      }
      else {
        return null;
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  const downloadExcel = async () => {
    try {
      const result = await fetchDownloadReport();
      if (result) {
        await DownloadLeadExcel(result, companyName); // Ensure to pass customer data
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  const downloadPdf = async () => {
    try {
      const result = await fetchDownloadReport();
      if (result) {
        await DownloadLeadsPdf(companyName, result, companyLogo);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  const headers = [
    { id: "name", label: "NAME" },
    { id: "mobile", label: "PHONE" },
    { id: "lead_date", label: "DATE" },
    { id: "source", label: "SOURCE" },
    { id: "is_active", label: "STATUS" },
  ];

  const assignCompanyLogo = async () => {
    try {
      const result = await configServ.company_logo({ company_id: company_id });
      if (result && result.image) {
        // Assuming the result.image is already a base64 string
        setCompanyLogo(`data:image/png;base64,${result.image}`);
      } else {
        setCompanyLogo(null); // Set as null if no logo
      }
    } catch (err) {
      console.log("Error fetching company logo:", err);
      setCompanyLogo(null); // Handle error by clearing logo
    }
  };

  useEffect(() => {
    assignCompanyLogo();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f9f9ff",
    //boxShadow: theme.shadows[1],
  }));

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  // Download as PDF
  const downloadPDF = () => {
    downloadPdf()
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count);

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
    textAlign: "flex-start",
  };

  const cellStyle = {
    my: 0,
    textAlign: "flex-start",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const followUpRowStyle = {
    background: "#f7da00",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFollowUp = async (params) => {
    try {
      const data = {
        id: params.id,
        name: params.name,
        entity_type: "lead",
      };
      setFollowup(data);
    } catch (e) {
      console.log(e);
    }
  };

  const customerRef = useRef();
  const tableRef = useRef();

  const handlePrintData = () => {
    setIsPrint(true);
  };

  useEffect(() => {
    if (isPrint === true) {
      handlePrint();
      setIsPrint(false);
    }
  }, [isPrint]);

  const handlePrint = useReactToPrint({
    content: () => customerRef.current,
    documentTitle: "Customers",
  });

  const handleBellNotification = async (params) => {
    try {
      await handleFollowUp(params);
      const dataToSend = {
        follow_up_id: params.follow_up_id,
      };
      const res = await configServ.stopFollowUpNotification(dataToSend);
      if (res.status === 200) {
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (params) => {
    try {
      dispatch(showLoader());
      const dataToSend = {
        "openingamount": 0,
        "type": "Standard",
        "country": companyCountry,
        "State": companyState,
        "is_credit_days": false,
        "status": true,
        "send_mail": false,
        "fname": params.name,
        "address": params.address || companyAddress,
        "mobile": params.mobile,
        "shipToAddress": params.address || companyAddress,
        "company_id": company_id,
        "user_id": user_id,
        "contact_name": params.name
      }
      const res = await configServ.addCustomer(dataToSend);
      if (res.status === 200) {
        const result = await configServ.deactivateLead(params);
        if (result.status === 200) {
          fetchLeadList();
        }
        Swal.fire({
          'icon': 'success',
          'title': 'Success',
          'text': res.message || 'Customer created successfully.'
        });
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      dispatch(hideLoader());
    }
  }

  const handleCreateCustomer = async (params) => {
    try {
      Swal.fire({
        title: "Do you want to create customer?",
        showDenyButton: true,
        confirmButtonText: "Create",
        denyButtonText: `Cancel`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          handleSubmit(params);
        }
        else if (result.isDenied) {

        }
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  //#endregion

  return (
    <>
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: 1200, // Set high z-index to block all interactions
          position: "fixed", // Ensure it covers the whole screen
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Follow Up */}
      <FollowUpPopup data={followup} closeFollowup={() => setFollowup(null)} />

      <Grid container alignItems="flex-end" justifyContent="flex-start"
        style={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid container item xs={12} md={2} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Lead List: {count}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={3}
          justifyContent={{ xs: "center", md: "flex-start" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Button
              disabled={Boolean(count === 0)}
              variant="contained"
              color="primary"
              onClick={handleMenuClick}
              startIcon={<DownloadIcon sx={{ ml: 2 }} />}
            ></Button>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filteredReport}
                      onClick={() => setFilteredReport(!filteredReport)}
                    />
                  }
                  label="With Filter"
                />
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleDownloadExcel();
                  handleMenuClose();
                }}
              >
                Excel Download
              </MenuItem>
              <MenuItem
                onClick={() => {
                  downloadPDF();
                  handleMenuClose();
                }}
              >
                PDF Download
              </MenuItem>
            </Menu>
            <Button
              variant="contained"
              onClick={handlePrintData}
              startIcon={<PrintIcon sx={{ ml: 2 }} />}
              title="Print"
              sx={{
                backgroundColor: "#349af1",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0868F8",
                  color: "white",
                },
                display: { xs: "flex", sm: "flex", lg: "none" }, // Show on mobile and tablet, hide on desktop
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                value={searchBy}
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"name"}>Name</option>
                <option value={"address"}>Address</option>
                <option value={"mobile"}>Mobile</option>
                <option value={"source"}>Source</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} justifyContent={"flex-end"}>
          <SearchBox search={search} setSearch={setSearch} />
        </Grid>
      </Grid>

      <Box display={{ xs: 'block', md: 'none' }} width={'100%'}>
        <Grid container alignItems={"center"} spacing={1} mt={1}>
          <Grid container item xs={6} md={6} justifyContent={"center"}>
            <FormControl fullWidth size="small">
              <InputLabel>Sort By</InputLabel>
              <Select
                fullWidth
                size="small"
                name="order_by"
                label="Sort By"
                value={orderBy}
                onChange={(e) => setOrderBy(e.target.value)}
                sx={{ background: "white" }}
              >
                {headers.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={6} md={6} justifyContent={"center"}>
            <FormControl fullWidth size="small">
              <InputLabel>Order</InputLabel>
              <Select
                fullWidth
                size="small"
                name="order"
                label="Order"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                sx={{ background: "white" }}
              >
                <MenuItem key={1} value={"asc"}>
                  {"Ascending"}
                </MenuItem>
                <MenuItem key={2} value={"desc"}>
                  {"Descending"}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={1} ref={customerRef}>
          {loading ? (
            <Box sx={{ textAlign: "center", mt: 4 }}>
              <LoaderNew />
            </Box>
          ) : (
            <>
              {(isPrint || isDownload) && (
                <>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {companyName}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    {companyAddress}, {companyState}, {companyCountry}
                  </Typography>
                </>
              )}

              {leadList.map((row, rowIndex) => (
                <StyledCard key={rowIndex}>
                  <CardContent sx={{ pb: 0 }}>
                    <Stack direction={"row"}>
                      {row.follow_up_date && (
                        <img
                          src={bell_notification}
                          alt=""
                          width={40}
                          onClick={() => handleBellNotification(row)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <Typography variant="body1" gutterBottom fontWeight={700}>
                        {page * rowsPerPage + rowIndex + 1}. {row.name} - <span style={{ color: 'grey' }}>{row.address}</span>
                      </Typography>
                    </Stack>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="body2">
                        <strong>Mobile:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {row.mobile}
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="body2">
                        <strong>Date:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {CheckAndReturn.transformToDate3(row.lead_date)}
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="body2">
                        <strong>Source:</strong>
                      </Typography><Typography variant="body2">
                        {row.source || ''}
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant="body2">
                        <strong>Status:</strong>{" "}
                      </Typography>
                      <Typography variant="body2">
                        {Boolean(row.is_active) === true ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Typography>
                    </Box>
                  </CardContent>
                  {isPrint === false && isDownload === false && (
                    <CardActions>
                      <Grid container spacing={1}>
                        <Grid item display={Boolean(is_super || is_admin || permissions?.includes(44)) ? 'block' : 'none'}>
                          <Box display={Boolean(is_super || is_admin || permissions?.includes(44)) ? 'block' : 'none'}>
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                onClick={() => {
                                  handleEdit(row);
                                  scrollToTop();
                                }}
                              >
                                <EditIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Follow Up" arrow>
                            <IconButton onClick={() => handleFollowUp(row)}>
                              <BookmarkIcon color="warning" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        {
                          (is_super || is_admin) &&
                          <Grid item>
                            <Tooltip title="Create Customer" arrow>
                              <IconButton disabled={!row.is_active} onClick={() => handleCreateCustomer(row)}>
                                <PersonAdd color={row.is_active ? "secondary" : "disabled"} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        }
                      </Grid>
                    </CardActions>
                  )}
                </StyledCard>
              ))}
            </>
          )}
        </Box>

        <Box mt={2}>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>

      <Box display={{ xs: 'none', md: 'block' }} width={'100%'}>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh", // Ensures minimum full height
            // maxHeight: "auto",  // This is implied by default
          }}
          ref={tableRef}
        >
          {(isPrint || isDownload) && (
            <>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {companyName}
              </Typography>
              <Typography sx={{ textAlign: "center" }}>
                {companyAddress}, {companyState}, {companyCountry}
              </Typography>
            </>
          )}
          <Box sx={{ flexGrow: 1, overflow: "auto", mt: 2 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellStyle}>S.No.</TableCell>
                  {headers.map((column) => (
                    <TableCell
                      key={column.id}
                      sortDirection={orderBy === column.id ? order : false}
                      sx={headerCellStyle}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {!isGeneratingPDF &&
                    isPrint === false &&
                    isDownload === false && (
                      <TableCell sx={headerCellStyle}>Action</TableCell>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading === false ? (
                  leadList.length > 0 ? (
                    leadList.map((row, rowIndex) => (
                      <TableRow
                        key={rowIndex}
                        sx={{
                          ...rowStyle,
                          ...(rowIndex % 2 === 0
                            ? evenRowStyle
                            : oddRowStyle),
                        }}
                      >
                        <TableCell sx={cellStyle}>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            mx={1}
                          >
                            {page * rowsPerPage + rowIndex + 1}
                            {row.follow_up_date && (
                              <img
                                src={bell_notification}
                                alt=""
                                width={30}
                                onClick={() => handleBellNotification(row)}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ ...cellStyle, textAlign: "left" }}>
                          {row.name} -{" "}
                          <span style={{ color: "gray" }}>
                            {row.address || ""}
                          </span>
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {row.mobile || ""}
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {CheckAndReturn.transformToDate3(row.lead_date)}
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {row.source || ''}
                        </TableCell>
                        {!isGeneratingPDF && (
                          <TableCell sx={cellStyle}>
                            {Boolean(row.is_active) === true ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <CancelIcon color="error" />
                            )}
                          </TableCell>
                        )}
                        {!isGeneratingPDF && (
                          <>
                            {isPrint === false && isDownload === false && (
                              <TableCell sx={cellStyle}>
                                <Stack direction={"row"} spacing={1}>
                                  <Box display={Boolean(is_super || is_admin || permissions?.includes(44)) ? 'block' : 'none'}>
                                    <Tooltip title="Edit" arrow>
                                      <IconButton>
                                        <EditIcon
                                          color="primary"
                                          onClick={() => {
                                            handleEdit(row);
                                            scrollToTop();
                                          }}
                                          sx={{ cursor: "pointer" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  <Box>
                                    <Tooltip title="Follow Up" arrow>
                                      <IconButton>
                                        <BookmarkIcon
                                          onClick={() => handleFollowUp(row)}
                                          color={"warning"}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  {
                                    (is_super || is_admin) &&
                                    <Box>
                                      <Tooltip title="Create Customer" arrow>
                                        <IconButton disabled={!row.is_active}>
                                          <PersonAdd
                                            onClick={() => handleCreateCustomer(row)}
                                            color={row.is_active ? "secondary" : "disabled"}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  }
                                </Stack>
                              </TableCell>
                            )}
                          </>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Box textAlign={"center"}>
                          <Typography variant="body1" color={"red"}>
                            No data found.
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                      <Box>
                        <LoaderNew />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>

        <TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </>
  );
};

export default memo(LeadsTable);
