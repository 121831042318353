import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Stack,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import configServ from "../../../services/config";
import Checkbox from "@mui/joy/Checkbox";
import AlertDialog from "../../AlertDialog/AlertDialog";
import { useDispatch } from "react-redux";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";

//FUNCTION
function AddItemImage({
  itemData,
  handleClose,
  open,
  editData,
  isEdited,
  setIsEdited,
}) {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const [imageToSend, setImageToSend] = useState("");
  const [formData, setFormData] = useState({
    status: true,
  });
  const [validationError, setValidationError] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    //Update form
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    // Reset the validation error when the user starts typing
    if (validationError[name]) {
      setValidationError((prevValidationError) => ({
        ...prevValidationError,
        [name]: "",
      }));
    }
  };

  const checkHandler = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  //image to base64 converter
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const removeDataUrlPrefix = (base64String) => {
    return base64String.split(",")[1];
  };

  // handling onchange for file
  const handleChangeFile = async (e) => {
    const { files } = e.target;

    if (files.length > 0) {
      const file = files[0];
      const fileSizeInKB = file.size / 1024; // Original file size in KB

      // Check if the file size is below the minimum required size
      if (fileSizeInKB < 120) {
        console.error("Image size is too small. Minimum size is 120KB.");
        toast.error("Image size is too small. Minimum size is 120KB.");
        return;
      }

      try {
        let finalFile = file; // Default to original file if no compression is needed

        if (fileSizeInKB > 500) {
          // Options for image compression
          const options = {
            maxSizeMB: 0.5, // Target size in MB (500KB = 0.5MB)
            maxWidthOrHeight: 500, // Set the maximum height or width
            useWebWorker: true,
          };

          // Compress the image
          const compressedFile = await imageCompression(file, options);
          const compressedFileSizeInKB = compressedFile.size / 1024;

          // Ensure the compressed file is above 200KB
          if (compressedFileSizeInKB >= 200) {
            finalFile = compressedFile;
            // console.log("Compressed file size (KB):", compressedFileSizeInKB); // Size in KB
          } else {
            console.error(
              "Compressed image size is below the acceptable limit of 200KB."
            );
            return;
          }
        }

        // Convert final image (compressed or original) to Base64
        const base64Image = await convertToBase64(finalFile);

        // Update state with the final image
        setSelectedImage(removeDataUrlPrefix(base64Image)); // Remove Data URL prefix if needed
        setImageToSend(base64Image); // Base64 string to send
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    }
  };

  const validate = async () => {
    const errors = {};
    if (!imageToSend) {
      errors.image = "required";
    }
    if (!formData.alt) {
      errors.alt = "required";
    }
    setValidationError(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleUpload = async () => {
    try {
      dispatch(triggerLoader());

      const isValid = await validate();
      if (isValid === false) {
        return false;
      }

      const dataToSend = {
        ...formData,
        product_id: itemData.id,
        image: imageToSend,
      };

      if (!isEdit) {
        const result = await configServ.AddBase64ItemImage(dataToSend);
        if (result.success) {
          setIsEdited(!isEdited);
          handleBoxClose();
          handleOpenDialog(
            "Success",
            "Image uploaded successfully.",
            "success"
          );
        } else {
          handleOpenDialog("Error", "Failed to upload image.", "error");
        }
      } else {
        const result = await configServ.updateItemImage(dataToSend);
        if (result.success) {
          setIsEdited(!isEdited);
          handleBoxClose();
          handleOpenDialog("Success", "Image updated successfully.", "success");
        } else {
          handleOpenDialog("Error", "Failed to update image.", "error");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(triggerLoader());
    }
  };

  const assignForEdit = async () => {
    if (editData !== undefined) {
      setFormData(editData);
      setSelectedImage(editData.image);
      setImageToSend(editData.image);
      setIsEdit(true);
    }
  };

  useEffect(() => {
    assignForEdit();
  }, [editData]);

  const handleBoxClose = () => {
    clear();
    handleClose();
  };

  const clear = () => {
    setFormData({
      status: true,
    });
    setValidationError({});
    setSelectedImage("");
    setImageToSend("");
    // setIsEdit(false)
  };

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <Dialog
        open={open}
        onClose={handleBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" color={"#1e61c5"}>
              {isEdit ? "Update" : "Create"} Product Image
            </Typography>
            <IconButton onClick={handleBoxClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {/* <Stack spacing={2}>
                        <Typography variant='body' color='text.secondary'>Choose image for {itemData.item_name}:</Typography>
                        <Stack direction='row' justifyContent='space-between' spacing={10}>
                            <Stack
                                spacing={2}
                                sx={{
                                    borderRadius: 2,
                                    border: '1px solid #33333333',
                                    padding: '2rem'
                                }}>
                                <TextField
                                    type='file'
                                    name='itemImage'
                                    onChange={handleChangeFile}
                                    size='small'
                                    file={formData.image}
                                    error={!!validationError.image}
                                />
                                <TextField
                                    required
                                    label='Alternate Text'
                                    size='small'
                                    name='alt'
                                    onChange={changeHandler}
                                    value={formData.alt}
                                    error={!!validationError.alt}
                                />
                                <Checkbox
                                    label="Status"
                                    name='status'
                                    onChange={checkHandler}
                                    checked={Boolean(formData.status)}
                                />
                            </Stack>
                            `<img
                                alt='Preview'
                                src={`data:image/png;base64,${selectedImage || ""}`}
                                style={{ marginRight: 30 }}
                                width={200}
                            />`
                        </Stack>
                    </Stack> */}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body" color="text.secondary">
                Choose image for {itemData.item_name}:
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  color: "red",
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  position: "relative",
                  display: "inline-block",
                  width: "100%",
                  "@keyframes marquee": {
                    "0%": { transform: "translateX(100%)" },
                    "100%": { transform: "translateX(-100%)" },
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    animation: "marquee 20s linear infinite", // Slower movement (20 seconds)
                  }}
                >
                  Image Minimum size is 120KB.
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                sx={{
                  border: "1px solid #33333333",
                  padding: "2rem",
                  borderRadius: 2,
                }}
              >
                <TextField
                  type="file"
                  name="itemImage"
                  onChange={handleChangeFile}
                  size="small"
                  file={formData.image}
                  error={!!validationError.image}
                />
                <TextField
                  required
                  label="Alternate Text"
                  size="small"
                  name="alt"
                  onChange={changeHandler}
                  value={formData.alt}
                  error={!!validationError.alt}
                />
                <Checkbox
                  label="Status"
                  name="status"
                  onChange={checkHandler}
                  checked={Boolean(formData.status)}
                />
              </Stack>
            </Grid>
            <Grid container item justifyContent={"center"} xs={12} md={6}>
              <img
                alt="Preview"
                src={`data:image/png;base64,${selectedImage || ""}`}
                style={{ width: "100%", maxWidth: 200, marginRight: 30 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpload}
            variant="contained"
            startIcon={isEdit ? <UpdateIcon /> : <UploadIcon />}
          >
            {isEdit ? "Update" : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddItemImage;
