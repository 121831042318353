import { DisabledByDefault, LocationOn, ReplyAll } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import GoogleMap from "google-maps-react-markers"; // Import GoogleMap from the library
import React, { useEffect, useRef, useState } from "react";
import { google_api_key } from "../../utility/Common";
import { useSelector } from "react-redux";

const MultipleMarkersMap = ({ openMap, closeMap, companyCoordinate, coordinates }) => {
  //#region code
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);

  useEffect(() => {
    if (openMap && coordinates.length > 0) {
      setMapReady(true);
    }
  }, [openMap]);

  // Called when the Google Map API is loaded
  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
  };

  // Handles marker clicks
  const onMarkerClick = (marker) => {
    mapRef.current.setCenter({ lat: marker.position.lat, lng: marker.position.lng });
  };

  // Map options
  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const dialogStyles = {
    width: isMobile ? "100%" : isTablet ? "80%" : "80%",
    height: isMobile ? "100%" : "80%",
    maxWidth: "none", // To allow custom width
    maxHeight: "none", // To allow custom height
  };

  const handleClose = () => {
    setMapReady(false);
    closeMap();
  }

  //#endregion

  return (
    <Dialog
      open={openMap}
      onClose={handleClose}
      fullScreen//={isMobile} // Full-screen for mobile devices
      //PaperProps={{ style: dialogStyles }} // Apply custom styles
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <IconButton sx={{ fontSize: "36px" }} title="back" onClick={handleClose}>
            <ReplyAll color="primary" fontSize="36px" />
          </IconButton>
          <Typography variant="h6" color="warning" fontWeight={700}>
            MAP
          </Typography>
          <IconButton sx={{ fontSize: "36px" }} title="close" onClick={handleClose}>
            <DisabledByDefault color="error" fontSize="35px" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ px: 3 }}>
        <Box style={{ height: isMobile ? "100%" : "100%", width: "100%" }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          {
            mapReady ?
              <GoogleMap
                apiKey={google_api_key} // Replace with your actual API key
                defaultCenter={{ lat: companyCoordinate.lat, lng: companyCoordinate.lng }} // Default center to Ranchi
                defaultZoom={5}
                options={mapOptions}
                onGoogleApiLoaded={onGoogleApiLoaded}
              >
                {coordinates.map((marker) => (
                  <div
                    key={marker.id}
                    style={{
                      position: "absolute",
                      transform: "translate(-50%, -100%)",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    lat={marker.position.lat}
                    lng={marker.position.lng}
                    onClick={() => onMarkerClick(marker)}
                  >
                    <IconButton title={marker.name}>
                      <LocationOn color="error" />
                    </IconButton>
                  </div>
                ))}
              </GoogleMap>
              :
              <CircularProgress />
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MultipleMarkersMap;
