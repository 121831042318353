import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import ProductCard from "./ProductCard";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import configServ from "../../../services/config";
import LoaderNew from "../../../Components/Loader/LoaderNew";

const Product = () => {
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const [item, setItem] = useState([]);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({
    filter_by_brand: "all",
    filter_by_category: "all",
  });
  const [search, setSearch] = useState("");
  const [view, setView] = useState("list");
  const [filterItems, setFilterItems] = useState({
    company_id: company_id,
    brand_id: "",
    category_id: "",
    search_item: "",
  });
  const [totalItems, setTotalItems] = useState(0);
  const [searchResult, setSearchResult] = useState(true);
  const [loader, setLoader] = useState(true);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const itemsPerPage = 9;
  const [catalog, setCatalog] = useState([]);

  const handleReset = () => {
    setSearch("");
    setFilterItems({
      ...filterItems,
      brand_id: "",
      category_id: "",
      search_item: "",
    });
    setCurrentPage(1);
    setItem([]); // Clear items for fresh load
    fetchItem(1); // Refetch items after reset from page 1
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilterItems((state) => ({ ...state, [name]: value }));
    setCurrentPage(1);
    setHasMore(true); // Allow fetching more items after reset
    setItem([]); // Clear items
    fetchItem(1); // Fetch from the first page based on new filter
  };

  const fetchItem = async (page = 1) => {
    try {
      if (!hasMore && page !== 1) return;

      if (totalItems === item.length && item.length > 0) {
        return;
      }

      const dataToSend = {
        ...filterItems,
        perPage: itemsPerPage,
        page,
      };

      setLoader(true);

      const result = await configServ.getItemDetailsByCompIdPage(dataToSend);
      if (result.success) {
        const newItems = result.paginationData;

        // Add only unique items to prevent duplication
        setItem((prevItems) =>
          page === 1
            ? newItems
            : [
                ...prevItems,
                ...newItems.filter(
                  (newItem) => !prevItems.some((item) => item.id === newItem.id)
                ),
              ]
        );

        setTotalItems(result.totalRecords);
        setHasMore(newItems.length === itemsPerPage);
        setSearchResult(true);
      } else {
        setSearchResult(false);
        setHasMore(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    // Check the screen size
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setView("grid"); // Set default view for small devices
      } else {
        setView("list"); // Set default view for larger devices
      }
    };

    // Call the function on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchCategory = async () => {
    try {
      const res = await configServ.getcategory({ company_id });
      if (res.status === 200) setCategory(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBrand = async () => {
    try {
      const res = await configServ.getBrand({ company_id });
      if (res.status === 200) setBrand(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchCatalogMenu = async () => {
    try {
      const res = await configServ.getCatalogFilter({ company_id });
      if (res.status === 200) setCatalog(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchBrand();
    fetchCatalogMenu();
  }, []);

  useEffect(() => {
    fetchItem();
  }, [filterItems, itemsPerPage]);

  const handleSearch = useCallback(
    (searchText) => {
      if (typingTimeout) clearTimeout(typingTimeout);
      const timeout = setTimeout(() => {
        setFilterItems((state) => ({ ...state, search_item: searchText }));
        setCurrentPage(1);
        setHasMore(true);
        setItem([]);
        fetchItem(1);
      }, 500);
      setTypingTimeout(timeout);
    },
    [typingTimeout]
  );

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    handleSearch(value);
  };

  // Trigger `fetchItem` on page load or when currentPage changes
  useEffect(() => {
    fetchItem(currentPage);
  }, [currentPage, filterItems]);

  // Handle Infinite Scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 100 &&
        !loader &&
        item.length < totalItems
      ) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loader, item, totalItems, currentPage]);
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        px={3}
      >
        {/* Filter & View Toggle */}
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          py={1}
        >
          <Grid item xs={6}>
            <Typography variant="body" color="text.secondary" fontWeight={700}>
              FILTER:
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            sx={{
              display: { xs: "none", sm: "flex" }, // Hide on xs, visible on sm and above
            }}
          >
            <RadioGroup
              row
              value={view}
              onChange={(e) => setView(e.target.value)}
            >
              <Tooltip
                title={view === "grid" ? "List View" : "Grid View"}
                arrow
              >
                <FormControlLabel
                  value={view === "grid" ? "list" : "grid"}
                  control={
                    <Radio
                      icon={
                        view === "grid" ? (
                          <FormatListBulletedIcon />
                        ) : (
                          <GridViewIcon />
                        )
                      }
                      checkedIcon={
                        view === "grid" ? (
                          <FormatListBulletedIcon />
                        ) : (
                          <GridViewIcon />
                        )
                      }
                      color="primary"
                    />
                  }
                />
              </Tooltip>
            </RadioGroup>
          </Grid>
          {/* Filter by Category & Brand */}
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Category</InputLabel>
              <Select
                fullWidth
                size="small"
                name="category_id"
                value={filterItems.category_id}
                onChange={handleFilter}
                sx={{ background: "white" }}
              >
                <MenuItem value="">All</MenuItem>
                {category.map((item) => (
                  <MenuItem key={item.categoryid} value={item.categoryid}>
                    {item.categoryname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Brand</InputLabel>
              <Select
                fullWidth
                size="small"
                name="brand_id"
                value={filterItems.brand_id}
                onChange={handleFilter}
                sx={{ background: "white" }}
              >
                <MenuItem value="">All</MenuItem>
                {brand.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.brand_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Catalog</InputLabel>
              <Select
                fullWidth
                size="small"
                name="catalog_id"
                value={filterItems.catalog_id}
                onChange={handleFilter}
                sx={{ background: "white" }}
              >
                <MenuItem value="">None</MenuItem>
                {catalog.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.catalog_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={6} justifyContent="flex-end">
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                label="Search"
                size="small"
                sx={{ background: "white" }}
                name="search_item"
                value={search}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleSearch(search)}>
                        <SearchIcon />
                      </IconButton>
                      <IconButton onClick={handleReset}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Products List */}
        <Grid container spacing={2} minHeight="50vh">
          {(searchResult || item.length > 0) &&
            item.map((item, index) => (
              <Grid
                key={index}
                item
                lg={view === "list" ? 12 : 4}
                md={view === "list" ? 12 : 6}
                sm={view === "list" ? 12 : 6}
                xs={12}
              >
                <ProductCard data={item} view={view} />
              </Grid>
            ))}
          {!searchResult && !loader && item.length === 0 && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <Typography
                variant="body1"
                color="error" // Red color for "No Results Found"
                textAlign="center"
                fontWeight="bold"
              >
                No Results Found
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* Loading Indicator */}
        {loader && <LoaderNew />}
      </Grid>
    </>
  );
};

export default Product;
