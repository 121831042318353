import React, { useState } from "react";
import SubscriptionCard from "../../Components/subscription/SubscriptionCard";
import SubscriptionsTable from "../../Components/subscription/SubscriptionsTable";

const Subscription = () => {
    //#region code
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState(null);

    const handleEdit = (params) => {
        const data = {
            id: params.id,
            customer_id: params.customer.id,
            subscription_plan: params.subscription_plan.id,
            subscription_date: params.subscription_date,
            frequency: params.frequency,
            due_days: params.due_days,
            post: Boolean(params.post),
            status: Boolean(params.status),
            tax_amount: params.tax_amount,
            total_amount: params.total_amount,
            products: params.product_subscriptions
        };
        setEditData(data);
    }

    //#endregion

    return (
        <>
            <SubscriptionCard editData={editData} setEditData={setEditData} isChanged={isChanged} setIsChanged={setIsChanged}/>
            <SubscriptionsTable isChanged={isChanged} handleEdit={handleEdit} />
        </>
    )
};
export default Subscription;