import React, { useState, useEffect, memo } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  InputLabel,
  InputAdornment,
  Typography,
} from "@mui/material";
import axios from "axios";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import configServ from "../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../AlertDialog/AlertDialog";
import CardHeadMenu from "../CardHeadMenu/CardHeadMenu";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { toast } from "react-hot-toast";

//FUNCTION
const AccountMasterCard = ({
  editData,
  setEditData,
  isChanged,
  setIsChanged,
}) => {
  //#region code
  const dispatch = useDispatch();
  const { user_id, company_id, is_super, companyCountry, companyState } =
    useSelector((state) => state.GlobalVariables);

  const formDataInitial = {
    country: companyCountry,
    state: companyState,
    tax_type: "",
    status: true,
  };
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [accountClassified, setAccountClassified] = useState([]);
  const [formData, setFormData] = useState(formDataInitial);
  const [validationErrors, setValidationErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [taxTypes, setTaxTypes] = useState([]);
  const [isTaxType, setIsTaxType] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setFormData(editData);
      setIsEdit(true);
      setIsCreate(true);
    }
  }, [editData]);

  const handlePopup = () => {
    handleReset();
    setIsCreate(!isCreate);
  };

  const getAllTaxTypes = async () => {
    try {
      const res = await configServ.getAllTaxTypes();
      if (res.status === 200) {
        setTaxTypes(res.data);
      } else {
        setTaxTypes([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCounties = async () => {
    try {
      const res = await configServ.getCountry();
      if (res.status === 200) {
        setCountryList(res.data);
      } else {
        setCountryList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (formData.country) {
      fetchStates();
    }
  }, [formData.country]);

  const fetchStates = async () => {
    try {
      const countryId = countryList.find(
        (x) => x.country === formData.country
      ).PK;
      const res = await configServ.getState({ country: countryId });
      if (res.status === 200) {
        setStateList(res.data);
      } else {
        setStateList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAccountClassified = async () => {
    try {
      const res = await configServ.getAccountClassified();
      if (res.status === 200) {
        setAccountClassified(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAccountClassified();
    fetchCounties();
    getAllTaxTypes();
  }, []);

  useEffect(() => {
    if (formData.country !== undefined) {
      fetchStates();
    }
  }, [formData]);

  const handleFormData = (event) => {
    const { name, value } = event.target;

    //Update form
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    // Reset the validation error when the user starts typing
    if (validationErrors[name]) {
      setValidationErrors((prevValidationError) => ({
        ...prevValidationError,
        [name]: "",
      }));
    }
  };

  useEffect(() => {
    if (formData.accountclassified) {
      const account = accountClassified.find(
        (x) => x.PK === parseInt(formData.accountclassified)
      );
      if (Boolean(account.tax_type) === true) {
        setIsTaxType(true);
      } else {
        setIsTaxType(false);
      }
    }
  }, [formData.accountclassified]);

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  // Regular expression pattern for validating email addresses
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateFormData = async () => {
    try {
      const errors = {};
      if (!formData.accountname) {
        errors.accountname = "Account Name is required.";
      }
      if (!formData.accountclassified) {
        errors.accountclassified = "Account Classified is required.";
      }
      if (formData.accountclassified && isTaxType === true) {
        if (!formData.tax_type) {
          errors.tax_type = "Tax Type is required.";
        }
      }
      if (!formData.contactName) {
        errors.contactName = "Contact Name is required.";
      }
      if (!formData.contactAddress) {
        errors.contactAddress = "Contact Address is required.";
      }
      if (!formData.country) {
        errors.country = "Country is required.";
      }
      if (!formData.state) {
        errors.state = "State is required.";
      }
      if (formData.email) {
        if (!emailPattern.test(formData.email)) {
          errors.email = "Invalid Email";
        }
      }
      setValidationErrors(errors);
      return Object.values(errors).every((error) => error === "");
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      dispatch(triggerLoader());
      const isValid = await validateFormData();
      if (isValid === false) {
        return false;
      }
      const dataToSend = {
        ...formData,
        company_id: company_id,
        user_id: user_id,
        is_super: is_super,
        qrImage:selectedImage,
      };

      if (!isEdit) {
        const res = await configServ.createAccountMaster(dataToSend);
        if (res.status === 200) {
          handleOpenDialog(
            "Success",
            res.message || "Account created successfully.",
            "success"
          );
          handleReset();
          setIsChanged(!isChanged);
        } else if (res.status === 400) {
          handleOpenDialog(
            "Warning",
            res.message || "Failed to create Account",
            "warning"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "Failed to create Account",
            "error"
          );
        }
      } else {
        const res = await configServ.updateAccountMaster(dataToSend);
        if (res.status === 200) {
          handleOpenDialog(
            "Success",
            res.message || "Account updated successfully.",
            "success"
          );
          handleReset();
          setIsChanged(!isChanged);
        } else {
          handleOpenDialog(
            "Error",
            res.message || "Failed to update Account.",
            "error"
          );
        }
      }
    } catch (e) {
      console.log(e);
      handleOpenDialog("Error", "An exception error has occurred.", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const handleReset = () => {
    setFormData(formDataInitial);
    setValidationErrors({});
    setIsEdit(false);
    setEditData({});
    setIsTaxType(false);
    setSelectedImage(null);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    // Check if file is selected
    if (!file) {
      toast.error("No file selected!");
      return;
    }

    // Validate file type
    const validTypes = ["image/jpeg", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      toast.error("Only JPG and JPEG images are allowed!");
      return;
    }

    // Validate file size (200KB = 200 * 1024 bytes)
    const maxSize = 200 * 1024;
    if (file.size > maxSize) {
      toast.error("File size must not exceed 200KB!");
      return;
    }

    try {
      // Convert to Base64 if validation passes
      const base64Image = await convertToBase64(file);
    //   setProfileImage(file);
      setSelectedImage(base64Image);
      toast.success("Image uploaded successfully!");
    } catch (error) {
      toast.error("Failed to upload image. Please try again.");
    }
  };
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <CardHeadMenu
        prefix={""}
        suffix={""}
        title={"ACCOUNT MASTER"}
        handlePopup={handlePopup}
        isEdit={isEdit}
        create={isCreate}
      />
      {isCreate && (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Grid
            container
            spacing={2}
            sx={{ backgroundColor: "#e3f3fc", p: 2, my: 1 }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "bolder" }}
                m={0}
                p={0}
              >
                <b>Account Detail</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                label={
                  <span>
                    Account Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                value={formData.accountname || ""}
                onChange={handleFormData}
                name="accountname"
                error={!!validationErrors.accountname}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel error={!!validationErrors.accountclassified}>
                  Account Classified<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  fufullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="accountclassified"
                  label={
                    <span>
                      Account Classified<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  value={formData.accountclassified || ""}
                  onChange={handleFormData}
                  error={!!validationErrors.accountclassified}
                >
                  <MenuItem value={""}>Select</MenuItem>
                  {accountClassified.map((item) => (
                    <MenuItem key={item.PK} value={item.PK}>
                      {item.accountclassified}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {isTaxType && (
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel error={!!validationErrors.tax_type}>
                    Tax Type<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    fufullWidth
                    size="small"
                    sx={{ backgroundColor: "white" }}
                    name="tax_type"
                    label={
                      <span>
                        Tax Type<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    value={formData.tax_type || ""}
                    onChange={handleFormData}
                    error={!!validationErrors.tax_type}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    {taxTypes.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                name="openingbalance"
                label="Opening Balance"
                type="number"
                value={formData.openingbalance || ""}
                onChange={handleFormData}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                name="contactName"
                label={
                  formData.accountclassified === 1 ? (
                    <span>
                      Branch Name<span style={{ color: "red" }}>*</span>
                    </span>
                  ) : (
                    <span>
                      Contact Name<span style={{ color: "red" }}>*</span>
                    </span>
                  )
                }
                value={formData.contactName || ""}
                onChange={handleFormData}
                error={!!validationErrors.contactName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                name="contactAddress"
                label={
                  formData.accountclassified === 1 ? (
                    <span>
                      Branch Address<span style={{ color: "red" }}>*</span>
                    </span>
                  ) : (
                    <span>
                      Contact Address<span style={{ color: "red" }}>*</span>
                    </span>
                  )
                }
                value={formData.contactAddress || ""}
                onChange={handleFormData}
                error={!!validationErrors.contactAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel error={!!validationErrors.country}>
                  Country<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="country"
                  label={
                    <span>
                      Country<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  value={formData.country || ""}
                  onChange={handleFormData}
                  error={!!validationErrors.country}
                >
                  <MenuItem value="">Select Country</MenuItem>
                  {countryList.map((item, index) => (
                    <MenuItem key={index} value={item.country}>
                      {item.country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel error={!!validationErrors.state}>
                  State<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="state"
                  label={
                    <span>
                      State*<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  value={formData.state || ""}
                  onChange={handleFormData}
                  error={!!validationErrors.state}
                >
                  <MenuItem value="">Select State</MenuItem>
                  {stateList.map((item, index) => (
                    <MenuItem key={index} value={item.State}>
                      {item.State}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                name="contactNumber"
                label="Contact Number"
                type="number"
                value={formData.contactNumber || ""}
                onChange={handleFormData}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                sx={{
                  backgroundColor: "white",
                }}
                name="email"
                label="Email"
                variant="outlined"
                value={formData.email || ""}
                onChange={handleFormData}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "red" }}>
                      {validationErrors.email}
                    </InputAdornment>
                  ),
                }}
                error={!!validationErrors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                sx={{ backgroundColor: "white" }}
                name="description"
                label={
                  formData.accountclassified === 1 ? (
                    <span>Account No</span>
                  ) : (
                    <span>Description</span>
                  )
                }
                value={formData.description || ""}
                onChange={handleFormData}
              />
            </Grid>
            {formData.accountclassified === 1 && (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="ifsc_code"
                  label="IFSC Code"
                  value={formData.ifsc_code || ""}
                  onChange={handleFormData}
                />
              </Grid>
            )}
            {formData.accountclassified === 1 && (
             
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                type="file"
                fullWidth
                size="small"
                sx={{
                  backgroundColor: "white",
                  '& input[type="file"]': { cursor: "pointer" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                name="qr_code"
                label="QR Code"
                onChange={handleImageChange}
                inputProps={{ accept: "image/*" }} // Only allow image uploads
              />
            </Grid>
            )}


            <Grid container spacing={1} mt={1}>
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={6}
                justifyContent={"flex-start"}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  name="status"
                  checked={Boolean(formData.status)}
                  onChange={handleChangeCheckbox}
                  label="Status"
                  sx={{ fontWeight: "bold" }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={6}
                justifyContent={"flex-end"}
              >
                <Button
                  type="reset"
                  variant="contained"
                  onClick={handleReset}
                  color="secondary"
                  startIcon={<RotateLeftIcon />}
                  sx={{ mx: 1 }}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  color={isEdit ? "success" : "primary"}
                  startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                  sx={{ mx: 1 }}
                >
                  {isEdit ? "Update" : "Save"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default memo(AccountMasterCard);
