import React, { useEffect, useState } from 'react';
import CardHeadMenu from '../Card/CardHeadMenu/CardHeadMenu';
import { useDispatch, useSelector } from 'react-redux';
import configServ from '../../services/config';
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import SubscriptionProduct from './SubscriptionProduct';
import { Close, RestartAlt, Save } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { hideLoader, showLoader } from '../../redux/reducers/LoaderTrigger';
import Calculation from '../../utility/Calculations';

// FUNCTION
const SubscriptionCard = ({ editData, setEditData, isChanged, setIsChanged }) => {
    //#region code
    const dispatch = useDispatch();
    const { company_id, user_id } = useSelector(state => state.GlobalVariables);
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const initialFormData = {
        customer_id: null,
        subscription_plan: null,
        subscription_date: Calculation.GetCurrentDate(),
        frequency: null,
        due_days: 10,
        post: true
    }
    const [formData, setFormData] = useState(initialFormData);
    const [validationError, setValidationError] = useState({});
    const initialProduct = [
        { item_id: null, rate: 0, discount: 0, tax: 0, quantity: 1, unit: null, tax_amount: 0, total_amount: 0 },
    ]
    const [products, setProducts] = useState(initialProduct);
    const [itemData, setItemData] = useState([]);

    useEffect(() => {
        if (editData !== null) {
            setIsCreate(true);
            setFormData({
                id: editData.id,
                customer_id: editData.customer_id,
                subscription_plan: editData.subscription_plan,
                subscription_date: editData.subscription_date,
                frequency: editData.frequency,
                due_days: editData.due_days,
                post: editData.post,
                tax_amount: editData.tax_amount,
                total_amount: editData.total_amount,
            });
            const result = editData.products.map((product) => ({
                id: product.id,
                item_id: product.item_id,
                rate: product.rate,
                quantity: product.quantity,
                tax: parseFloat(product.tax || 0),
                discount: parseFloat(product.discount || 0),
                tax_amount: parseInt(product.tax_amount || 0),
                total_amount: parseInt(product.total_amount)
            }));
            setProducts(result);
            setIsEdit(true);
        }
    }, [editData]);

    const fetchCustomers = async () => {
        try {
            const dataTosend = {
                company_id
            }
            const res = await configServ.get_customer(dataTosend);
            if (res.length > 0) {
                setCustomers(res);
            }
            else {
                setCustomers([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const fetchUnsubscribedCustomer = async () => {
        try {
            const dataToSend = {
                company_id
            }
            const res = await configServ.getUnsubscribedCustomer(dataToSend);
            if (res.status === 200) {
                setCustomers(res.data);
            }
            else {
                setCustomers([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchSubscriptionPlan = async () => {
        try {
            const res = await configServ.getActiveSubscriptionPlans();
            if (res.status === 200) {
                setSubscriptionPlans(res.data);
            }
            else {
                setSubscriptionPlans([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchFrequency = async () => {
        try {
            const res = await configServ.getFrequency();
            if (res.status === 200) {
                setFrequencies(res.data);
            }
            else {
                setFrequencies([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchItems = async () => {
        try {
            const dataToSend = {
                company_id
            }
            const res = await configServ.getItemList(dataToSend);
            if (res.status === 200) {
                if (res.data && res.data.length > 0) {
                    const result = res.data.filter((x) => x.status === 1).map((item => ({
                        item_id: item.id,
                        name: item.item_name,
                        unit: item.unit,
                        rate: parseFloat(item.rate_unit) || 0,
                        tax: parseFloat(item.tax_rate) || 0,
                        discount: parseFloat(item.discount) || 0,
                    })));
                    setItemData(result);
                }
            }
            else {
                setItemData([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchData = async () => {
        try {
            dispatch(showLoader());
            if (editData === null) {
                await fetchUnsubscribedCustomer();
            }
            else {
                await fetchCustomers();
            }
            await fetchSubscriptionPlan();
            await fetchFrequency();
            await fetchItems();
        }
        catch (e) {
            console.log(e);
        }
        finally {
            dispatch(hideLoader());
        }
    }

    useEffect(() => {
        if (isCreate && customers.length === 0 && itemData.length === 0 && subscriptionPlans.length === 0) {
            fetchData();
        }
    }, [isCreate]);

    const handleChange = async (e) => {
        try {
            const { name, value, type, checked } = e.target;
            setFormData((prev) => ({
                ...prev,
                [name]: type === "checkbox" ? checked : value
            }));

            if (validationError[name]) {
                setValidationError((prev) => ({
                    ...prev,
                    [name]: ""
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePopup = () => {
        try {
            if (isCreate === true) {
                handleReset();
            }
            setIsCreate(!isCreate);
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleValidation = async () => {
        try {
            let errors = {};
            if (!formData.customer_id) {
                errors.customer_id = "required";
            }
            if (!formData.subscription_plan) {
                errors.subscription_plan = "required";
            }
            if (!formData.subscription_date) {
                errors.subscription_date = "required";
            }
            if (!formData.frequency) {
                errors.frequency = "required";
            }
            if (!formData.due_days) {
                errors.due_days = "required";
            }
            setValidationError(errors);
            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async () => {
        try {
            dispatch(showLoader());
            const isValid = await handleValidation();
            if (!isValid) {
                return false;
            }
            const dataToSend = {
                company_id,
                user_id,
                ...formData,
                tax_amount: products.reduce((sum, product) => sum + (product.tax_amount || 0), 0),
                total_amount: products.reduce((sum, product) => sum + (product.total_amount || 0), 0),
                products
            }
            if (isEdit) {
                const res = await configServ.updateSubscription(dataToSend);
                if (res.status === 200) {
                    Swal.fire({
                        'icon': 'success',
                        'title': 'Success',
                        'text': res.message
                    });
                    setIsChanged(!isChanged);
                    handleClose();
                }
                else {
                    Swal.fire({
                        'icon': 'error',
                        'title': 'Error',
                        'text': 'Failed to subscribe customer.'
                    });
                }
            }
            else {
                const res = await configServ.createSubscription(dataToSend);
                if (res.status === 200) {
                    await fetchUnsubscribedCustomer();
                    Swal.fire({
                        'icon': 'success',
                        'title': 'Success',
                        'text': res.message
                    });
                    setIsChanged(!isChanged);
                    handleClose();
                }
                else {
                    Swal.fire({
                        'icon': 'error',
                        'title': 'Error',
                        'text': 'Failed to subscribe customer.'
                    });
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            dispatch(hideLoader());
        }
    }

    const handleReset = () => {
        setFormData(initialFormData);
        setProducts(initialProduct)
        setValidationError({});
        setIsEdit(false);
        setEditData(null);
    }

    const handleClose = () => {
        setIsCreate(false);
        handleReset();
    }

    //#endregion

    return (
        <>
            <CardHeadMenu
                prefix={"Inv"}
                suffix={"Order"}
                title={"Subscriptions"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />
            {
                isCreate &&
                <Grid container alignItems={'center'} spacing={1} sx={{
                    width: "100%",
                    backgroundColor: "#e3f3fc",
                    p: 2,
                    borderRadius: "10px",
                    border: "none",
                    margin: "10px",
                }}>
                    <Box width={'100%'} display={'flex'} alignItems={'flex-end'} justifyContent={'space-between'}>
                        <Grid container item xs={6} ml={1} alignItems={'flex-end'} justifyContent={'flex-start'}>
                            <Typography variant='body1' fontWeight={600}> Customer detail</Typography>
                        </Grid>
                        <Grid container item xs={6} alignItems={'flex-end'} justifyContent={'flex-end'}>
                            {
                                isEdit ?
                                    <IconButton title='close' sx={{ px: 1, py: 0, backgroundColor: "white", borderRadius: 3 }} onClick={handleClose}>
                                        <Close color='error' />
                                    </IconButton>
                                    :
                                    <IconButton title='reset' sx={{ px: 1, py: 0, backgroundColor: "white", borderRadius: 3 }} onClick={handleReset}>
                                        <RestartAlt color='secondary' />
                                    </IconButton>
                            }
                            <IconButton title={isEdit ? 'update' : 'save'} sx={{ px: 1, py: 0, backgroundColor: "white", borderRadius: 3 }} onClick={handleSubmit}>
                                <Save color={isEdit ? 'success' : 'primary'} />
                            </IconButton>
                        </Grid>
                    </Box>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            disabled={isEdit}
                            fullWidth
                            size="small"
                            name="customer_id"
                            options={customers}
                            getOptionLabel={(option) => option.fname} // Display customer name in the dropdown
                            isOptionEqualToValue={(option, value) => option.id === value?.id} // Avoid warning about value mismatch
                            value={customers.find((customer) => customer.id === formData.customer_id) || null} // Ensure value is controlled
                            onChange={(event, value) => {
                                const customEvent = {
                                    target: {
                                        name: "customer_id",
                                        value: value ? value.id : "",
                                    },
                                };
                                handleChange(customEvent); // Call the existing handleChange function with a custom event
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="customer_id"
                                    label="Customer"
                                    value={customers.find((customer) => customer.id === formData.customer_id) || null} // Ensure value is controlled
                                    sx={{ backgroundColor: "white" }}
                                    error={!!validationError.customer_id}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id="plan-input" error={!!validationError.subscription_plan}>Subscription Plan</InputLabel>
                            <Select
                                disabled={isEdit}
                                size='small'
                                fullWidth
                                labelId="plan-label"
                                id="plan-select"
                                name='subscription_plan'
                                value={formData.subscription_plan || ""}
                                label="Subscription Plan"
                                onChange={handleChange}
                                sx={{ backgroundColor: 'white' }}
                                error={!!validationError.subscription_plan}
                            >
                                {
                                    subscriptionPlans.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            disabled={isEdit}
                            type="date"
                            label="Subscription Date"
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="subscription_date"
                            value={formData.subscription_date}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            sx={{ backgroundColor: "white" }}
                            error={!!validationError.subscription_date}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id="plan-input-1" error={!!validationError.frequency}>Frequency</InputLabel>
                            <Select
                                size='small'
                                fullWidth
                                labelId="plan-label-1"
                                id="plan-select-1"
                                name='frequency'
                                value={formData.frequency || ""}
                                label="Frequency"
                                onChange={handleChange}
                                sx={{ backgroundColor: 'white' }}
                                error={!!validationError.frequency}
                            >
                                {
                                    frequencies.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Due Days'
                            name='due_days'
                            type='number'
                            value={formData.due_days || ''}
                            onChange={handleChange}
                            sx={{ backgroundColor: 'white' }}
                            error={!!validationError.due_days}
                        />
                    </Grid>
                    <SubscriptionProduct products={products} setProducts={setProducts} itemData={itemData} />
                    <Box width={'100%'} display={'flex'} alignItems={'flex-end'} justifyContent={'space-between'}>
                        <Grid container item xs={6} alignItems={'center'} justifyContent={'flex-start'}>
                            <FormControlLabel
                                control={<Checkbox />}
                                name="post"
                                checked={Boolean(formData.post)}
                                onChange={handleChange}
                                label="Is Post?"
                                sx={{ fontWeight: "bold" }}
                            />
                        </Grid>
                        <Grid container item xs={6} alignItems={'center'} justifyContent={'flex-end'} spacing={2}>
                            {
                                isEdit ?
                                    <IconButton sx={{ fontSize: '36px', backgroundColor: "white", borderRadius: 3, p: 0 }} title='close' onClick={handleClose}>
                                        <Close color='error' fontSize='36px' />
                                    </IconButton>
                                    :
                                    <IconButton sx={{ fontSize: '36px', backgroundColor: "white", borderRadius: 3, p: 0 }} title='reset' onClick={handleReset}>
                                        <RestartAlt color='secondary' fontSize='36px' />
                                    </IconButton>
                            }
                            <IconButton sx={{ fontSize: '36px', backgroundColor: "white", borderRadius: 3, p: 0, mx: 1 }} title={isEdit ? 'update' : 'save'} onClick={handleSubmit}>
                                <Save color={isEdit ? 'success' : 'primary'} fontSize='36px' />
                            </IconButton>
                        </Grid>
                    </Box>
                </Grid>
            }
        </>
    )
}

export default SubscriptionCard;
