import React, { useState } from "react";
import {
  Box,
  Container,
  Paper,
  CssBaseline,
  Avatar,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import VpnKeyOffIcon from "@mui/icons-material/VpnKeyOff";
import HomeIcon from "@mui/icons-material/Home";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom"; // For navigation to the home page
import OuterHeader1 from "../../Components/header/Header/OuterHeader1";
import configServ from "../../services/config";
import { toast } from "react-hot-toast";
import LoaderBackdrop from "../../Components/Loader/LoaderBackdrop";
import { useSelector } from "react-redux";
import bk from "../../Components/Assets/images/wbk.png";

const theme = createTheme();

const ForgetPassword = () => {
  const [step, setStep] = useState(1); // Tracks the current step
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(""); // State for email validation error
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const lightMode = useSelector((state) => state.GlobalVariables.lightMode);
  const [resetToken, setResetToken] = useState("");

  // Placeholder function for API calls

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for email validation
    if (!email) {
      return "Email is required.";
    } else if (!emailRegex.test(email)) {
      return "Please enter a valid email address.";
    }
    return ""; // No error
  };
  const handleSendOtp = async () => {
    const error = validateEmail(email);
    if (error) {
      setEmailError(error); // Set the error state to show in the UI
      return; // Exit function if validation fails
    }

    setEmailError(""); // Clear any previous errors
    const dataToSend = {
      email: email,
    };

    try {
      setOpenBackdrop(true);
      const result = await configServ.requestReset(dataToSend);

      if (result.status === 200) {
        toast.success("OTP sent to your email!");
        setStep(2); // Move to OTP verification step
      } else if (result.status === 404) {
        toast.error("Email not found. Please check your email and try again.");
      } else {
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } finally {
      setOpenBackdrop(false);
    }
  };

  const handleVerifyOtp = async () => {
    const dataToSend = {
      email: email,
      otp: otp,
    };
    try {
      setOpenBackdrop(true);
      const result = await configServ.verifyOtp(dataToSend);

      if (result.status === 200) {
        toast.success("OTP Verifiyed");
        setResetToken(result.reset_token)
        setStep(3); // Move to new password input step
      } else if (result.status === 404) {
        toast.error("Invalid or expired OTP.");
      } else {
        toast.error("Failed to Verifing OTP. Please try again.");
      }
    } catch (error) {
      // Handle specific error messages from the API or default to a generic message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } finally {
      setOpenBackdrop(false);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirmed Password do not match!");
      return;
    }

    const dataToSend = {
      email: email,
      new_password: newPassword,
      reset_token: resetToken,
    };
    try {
      setOpenBackdrop(true);
      const result = await configServ.resetPassword(dataToSend);

      if (result.status === 200) {
        toast.success("Password reset successfully");
        clear();
        navigate("/login")
      } else if (result.status === 404) {
        toast.error("User not found.");
      }
      else if (result.status === 400) {
        toast.error("UnAuthorised.");
      }
       else {
        toast.error(" Please try again.");
      }
    } catch (error) {
      // Handle specific error messages from the API or default to a generic message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } finally {
      setOpenBackdrop(false);
    }
  };
  const clear = () => {
    setStep(1);
    setEmail("");
    setOtp("");
    setNewPassword("");
    setConfirmPassword("");
    // console.log(email, otp);
  };

  return (
    <>
      <LoaderBackdrop open={openBackdrop} />
      {/* <OuterHeader1 /> */}
      <Box>
        <Button
          startIcon={<HomeIcon />}
          sx={{ position: "absolute", top: 16, left: 16 }}
          onClick={() => navigate("/")}
        >
          Home
        </Button>
        <Container maxWidth="sm">
          <Paper elevation={2} sx={{ padding: 5, marginTop: 8 }}>
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <VpnKeyOffIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {step === 1 && "Reset Password"}
                {step === 2 && "Verify OTP"}
                {step === 3 && "Set New Password"}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                {step === 1 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="E-Mail Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!emailError} // Show red outline if there is an error
                        helperText={emailError} // Show the validation error message
                      />
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleSendOtp()}
                    >
                      Send OTP
                    </Button>
                  </Grid>
                )}

                {step === 2 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="otp"
                        label="Enter OTP"
                        name="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleVerifyOtp()}
                    >
                      Verify OTP
                    </Button>
                  </Grid>
                )}

                {step === 3 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="newPassword"
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={
                          newPassword !== confirmPassword &&
                          confirmPassword.length > 0
                        }
                        helperText={
                          newPassword !== confirmPassword &&
                          confirmPassword.length > 0
                            ? "Passwords do not match!"
                            : ""
                        }
                      />
                    </Grid>

                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleResetPassword()}
                    >
                      Reset Password
                    </Button>
                  </Grid>
                )}
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default ForgetPassword;
