import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Swal from "sweetalert2";

const DownloadStockUpdate = (stockData, companyName,  setOpenBackdrop) => {
  const headers = ['S.No.', 'ITEM NAME', 'ITEM CODE', 'UNIT', 'ACTUAL QTY'];

  // Format data with S.No and required fields
  const data = stockData.map((item, index) => [
    (index + 1).toString(), // S.No. column
    item.item_name,
    item.item_code,
    item.unit,
    item.actualquantity.toString(), // Ensure `actualquantity` is a string
  ]);

  const doc = new jsPDF();

  // Show backdrop while generating the PDF
  setOpenBackdrop(true);

  // Add report title
  doc.setFontSize(18);
  doc.text('Stock Update Report', 105, 10, { align: 'center' }); // Centered title

  // Add company logo
//   if (logo) {
//     doc.addImage(logo, 'PNG', 5, 5, 10, 10); // Adjust size as needed
//   }

  // Add company name
  doc.setFontSize(15);
  doc.text(companyName, 17, 12); // Adjust positioning

  // Add table with headers and data
  doc.autoTable({
    startY: 20, // Adjust position below title and logo
    head: [headers],
    body: data,
  });

  // Add additional notes or total at the end
  const finalY = doc.autoTable.previous.finalY;
  doc.setFontSize(12);
  doc.text(`Report generated on: ${new Date().toLocaleDateString()}`, 14, finalY + 10);

  // Save the PDF
  const currentDate = new Date().toLocaleDateString().replace(/\//g, '-');
  doc.save(`StockUpdate_${currentDate}.pdf`);

  // Hide backdrop after PDF generation
  setOpenBackdrop(false);
    // Success popup
    Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your Excel file has been downloaded",
        showConfirmButton: false,
        timer: 1500,
      });
};

export default DownloadStockUpdate;
