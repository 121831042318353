import React, { useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import configServ from '../../services/config';
import ActionButtons from '../../Components/Buttons/ActionButtons ';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useEffect } from 'react';
import DataGridTable from '../../Components/DataGridTable/DataGridTable';
import LoaderNew from '../../Components/Loader/LoaderNew';

const CityTable = ({ handleEditData, isChanged }) => {
    const [cityDataList, setCityDataList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [loading, setLoading] = useState(true);
    const columns = [
        { field: 'sn', headerName: 'S.NO.', flex: 1 },
        { field: 'City', headerName: 'City', flex: 2 },
        { field: 'country', headerName: 'Country', flex: 2 },
        { field: 'state', headerName: 'State', flex: 2 },
        { field: 'longitude', headerName: 'Longitude', flex: 2 },
        { field: 'latitude', headerName: 'Latitude', flex: 2 },
        {
            field: 'action',
            headerName: 'Action',
            width: 130,
            renderCell: (params) => (
                <ActionButtons
                    handlePrint={handlePrint}
                    handleEdit={handleEdit}
                    id={params.id}
                    noPrint
                />
            ),
        },
    ];

    useEffect(() => {
        fetchCityList();
    }, [isChanged]);
    const abc = () => {
        // console.log(cityList)
    }

    const fetchCityList = async () => {
        try {
            const res = await configServ.getCity();
            const result = res.map((item, index) => ({
                sn: index + 1,
                id: item.id,
                City: item.city_name,
                state: item.state_id,
                longitude: item.longitude,
                latitude: item.latitude,
            }));
            // console.log(result)
            setCityDataList(res || []);
            setCityList(result || []);
            setLoading(false);
        }
        catch (error) {
            // console.log(error);
            setCityDataList([]);
            setCityList([]);
            setLoading(false);
        }
    }


    const handlePrint = (params) => {
        const data = cityDataList.filter(x => x.id === params);
        // console.log('data', data)
        // alert(JSON.stringify(data));
        // printInvoice(data[0].invoice_number)
    }

    const handleEdit = (params) => {
        // alert(JSON.stringify(params));
        scrollToTop()
        const data = cityDataList.filter(x => x.id === params);
        handleEditData(data)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <LoaderNew />
            </Box>
        )
    }
    else {
        return (
            <>
            <Button onClick={abc}>abc</Button>
                <DataGridTable rows={cityList} columns={columns} />
            </>
        );
    }
};

export default CityTable;
