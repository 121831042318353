import React, { memo, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import configServ from "../../services/config";
import { useSelector } from "react-redux";
import useDeviceDetect from "../../Components/Detect/useDeviceDetect ";
import LoaderNew from '../Loader/LoaderNew';
import MobileView from "../views/MobileView";
import DesktopView from "../views/DesktopView";
import { useNavigate } from "react-router-dom";

//FUNCTION
const SubscriptionsTable = ({ isChanged, handleEdit, pagination = true }) => {
  //#region code
  const navigate = useNavigate();
  const { company_id, is_super, is_admin } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [count, setCount] = useState(0);
  const [searchBy, setSearchBy] = useState("customer_name");
  const [search, setSearch] = useState("");
  const isFirstRender = useRef(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchSubscriptions = async () => {
    try {
      setLoading(true);
      const dataToSend = {
        company_id: company_id,
        search_by: searchBy,
        search: search,
        per_page: rowsPerPage,
        page: page,
      };
      const res = await configServ.getSubscriptions(dataToSend);
      if (res.status === 200) {
        setSubscriptions(res.data);
        setCount(res.total);
      }
      else {
        setSubscriptions([]);
        setCount(0);
      }
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setLoading(false);
    }
  };

  // Debounce implementation for fetchSubscriptions
  const debounceFetch = (fn, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => fn(...args), delay);
    };
  };

  const debouncedFetchSubscriptions = debounceFetch(fetchSubscriptions, 300);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      fetchSubscriptions(); // Initial fetch only once on mount
    } else {
      debouncedFetchSubscriptions(); // Fetch on dependency change
    }
  }, [isChanged, page, rowsPerPage, search]);

  const headers = ["CUSTOMER", "SUBSCRIPTION", "AMOUNT", "STATUS", "ACTION"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
    py: 1,
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const data = event.target.value;
      if (data === "") {
        setSearch(null);
      } else {
        setSearch(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCustomerClick = (params) => {
    try {
      const data = params;
      navigate("/account-master-entry/customers", { state: data });
    }
    catch (e) {
      console.log(e);
    }
  };
  //#endregion


  return (
    <>
      {/* search starts here */}
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={6} ml={0} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Subscription List: {count}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"customer_name"}>Customer</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          mr={{ xs: 0, md: 0 }}
          justifyContent={"flex-end"}
        >
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            placeholder="search"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "grey" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {/* search ends here */}

      <MobileView>
        <Grid container spacing={2}>
          {
            subscriptions.length > 0 ? (
              subscriptions.map((row, rowIndex) => (
                <Grid item xs={12} key={rowIndex}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={1}>
                      <Box sx={{ fontWeight: "bold" }}>
                        {page * rowsPerPage + rowIndex + 1}.
                        <span
                          className="linkStyle"
                          onClick={() => handleCustomerClick(row.customer.fname)}
                        >{row.customer.fname}</span>
                        <span style={{ color: 'grey' }}>{row.customer.address ? `-${row.customer.address}` : ''}</span>
                      </Box>
                      <Box>
                        Subscription:{""}
                        {row.subscription_plan.name}
                      </Box>
                      <Box>
                        Amount:{""}
                        ₹{row.total_amount}
                      </Box>
                      <Box>
                        Status:{" "}
                        {row.status === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Box>
                      <Box display={Boolean(is_super || is_admin) ? 'block' : 'none'}>
                        <Stack direction="row">
                          <EditIcon
                            sx={{
                              borderRadius: 10,
                              width: "fit-content",
                              transition: "100ms",
                              cursor: "pointer",
                              padding: 0,
                              m: 0.5,
                              ":hover": { backgroundColor: "#99999955" },
                            }}
                            color="primary"
                            onClick={() => {
                              handleEdit(row);
                              scrollToTop();
                            }}
                          />
                        </Stack>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} textAlign="center">
                <Box sx={{ color: "red", fontWeight: 600 }}>
                  No data found.
                </Box>
              </Grid>
            )
          }
        </Grid>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </MobileView>

      <DesktopView>
        <TableContainer component={Paper} sx={{ py: 1 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index} sx={headerCellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading ?
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      sx={{ textAlign: "center" }}
                    >
                      <Box>
                        <LoaderNew />
                      </Box>
                    </TableCell>
                  </TableRow>
                  :
                  (
                    subscriptions.length === 0 ?
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box textAlign={"center"}>
                            <Typography variant="body1" color={"red"}>
                              No data found.
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                      :
                      subscriptions.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          sx={{
                            ...rowStyle,
                            ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                          }}
                        >
                          <TableCell sx={cellStyle}>
                            {page * rowsPerPage + rowIndex + 1}
                          </TableCell>
                          <TableCell sx={cellStyle}>
                            <span
                              className="linkStyle"
                              onClick={() => handleCustomerClick(row.customer.fname)}
                            >{row.customer.fname}</span>
                            <span style={{ color: 'grey' }}>{row.customer.address ? `-${row.customer.address}` : ''}</span></TableCell>
                          <TableCell sx={cellStyle}>{row.subscription_plan.name}</TableCell>
                          <TableCell sx={cellStyle}>₹{row.total_amount}</TableCell>
                          <TableCell sx={cellStyle}>
                            {row.status === 1 ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <CancelIcon color="error" />
                            )}
                          </TableCell>
                          <TableCell sx={cellStyle}>
                            <Box
                              sx={{
                                borderRadius: 10,
                                width: "fit-content",
                                transition: "100ms",
                                cursor: "pointer",
                                padding: 0.5,
                                ":hover": { backgroundColor: "#99999955" },
                              }}
                              onClick={() => {
                                handleEdit(row);
                                scrollToTop();
                              }}
                              display={Boolean(is_super || is_admin) ? 'block' : 'none'}
                            >
                              <Tooltip title="Edit" arrow>
                                <EditIcon color="primary" />
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
            </TableBody>
          </Table>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </DesktopView>

    </>
  );

};

export default memo(SubscriptionsTable);
