import React, { useEffect, useState } from "react";
import {
    Typography,
    Stack,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Box,
    Grid,
    TextField,
    Button,
    IconButton
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import configServ from "../../../../services/config";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const EwayBill = ({ handleClose }) => {

    const [formData, setFormData] = useState({});
    const [isEdit, setIsEdit] = useState(false)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        // console.log(formData)
    }

    const clear = () => {
        setFormData({})
    }

    return (
        <div>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Transporter Name'
                            name='transporter_name'
                            onChange={handleChange}
                            value={formData.transporter_name || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type="number"
                            size='small'
                            label='Mobile Number'
                            name='mobile_no'
                            onChange={handleChange}
                            value={formData.mobile_no || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Vehicle Number'
                            name='vehicle_number'
                            onChange={handleChange}
                            value={formData.vehicle_number || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Transportation Mode'
                            name='transportation_mode'
                            onChange={handleChange}
                            value={formData.transportation_mode || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            type="number"
                            size='small'
                            label='Distance(KM)'
                            name='distance'
                            onChange={handleChange}
                            value={formData.distance || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Vehicle Type'
                            name='vehicle_type'
                            onChange={handleChange}
                            value={formData.vehicle_type || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type="date"
                            size='small'
                            label='Date'
                            name='date'
                            onChange={handleChange}
                            value={formData.date || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            size='small'
                            label='E-Way Bill Number'
                            name='ewaybill_number'
                            onChange={handleChange}
                            value={formData.ewaybill_number || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                        direction='row'
                        justifyContent='flex-end'
                        spacing={2}
                        >
                            <Tooltip title='Reset'>
                                <IconButton onClick={clear} >
                                    <RotateLeftIcon />
                                </IconButton>
                            </Tooltip>
                            <Button
                                onClick={() => { handleSubmit(); handleClose() }}
                                autoFocus
                                variant='contained'
                                sx={{
                                    minWidth:100,
                                    width:{
                                        xs:'100%',
                                        md:'15%'
                                    }
                                }}
                            >
                                {isEdit ? 'Update' : 'Save'}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>

        </div>
    )
}
export default EwayBill