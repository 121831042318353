import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  IconButton,
  Typography,
  Tooltip,
  Divider,
  Grid,
  Box,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  Paper,
  Stack,
} from "@mui/material";
import AlertDialog from "../../AlertDialog/AlertDialog";
import RoleTable from "./RoleTable";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import AddBoxIcon from "@mui/icons-material/AddBox";
import configServ from "../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import Permissions from "../../AccessMaster/permissions/Permissions";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateRole = () => {
  //#region code
  const dispatch = useDispatch();
  const { user_id, is_super } = useSelector((state) => state.GlobalVariables);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [permissionList, setPermissionList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permissionOpen, setPermissionOpen] = useState(false);
  const [getPageAccessControlsList, setGetPageAccessControlsList] = useState(
    []
  );
  const [selectedPermission, setSelectedPermission] = useState("");
  const [formData, setFormData] = useState({
    name: "",
  });

  const [selectedPermissions, setSelectedPermissions] = useState({});
  // console.log(selectedPermissions)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchgetPageAccessControls = async () => {
    try {
      const res = await configServ.getPermissionsGroupedByCategory({
        admin_id: user_id,
      });
      if (res.status === 200) {
        // Transform the data object into an array
        const transformedData = Object.entries(res.data).map(
          ([key, value]) => ({
            PageAccessControl: key,
            permissions: value,
          })
        );
        setGetPageAccessControlsList(transformedData);
      } else {
        setGetPageAccessControlsList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchgetPageAccessControls();
  }, []);

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSelectAll = (page) => {
    const updated = { ...selectedPermissions };
    updated[page] = getPageAccessControlsList
      .find((item) => item.PageAccessControl === page)
      .permissions.map((perm) => perm.id);
    setSelectedPermissions(updated);
  };

  const handleDeselectAll = (page) => {
    const updated = { ...selectedPermissions };
    updated[page] = [];
    setSelectedPermissions(updated);
  };

  const handleCheckboxChange = (page, permissionId) => {
    const updated = { ...selectedPermissions };
    if (!updated[page]) updated[page] = [];
    if (updated[page].includes(permissionId)) {
      updated[page] = updated[page].filter((id) => id !== permissionId);
    } else {
      updated[page].push(permissionId);
    }
    setSelectedPermissions(updated);
  };

  const handlePermissionChange = (event) => {
    const {
      target: { value },
    } = event;
    setPermissions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = async () => {
    try {
      dispatch(triggerLoader());
      const permissions = Object.values(selectedPermissions).flat();
      const dataToSend = {
        name: formData.name,
        admin_id: user_id,
        permissions,
        is_super: is_super,
        permissions: permissions,
      };
      const error = await handleFormValidation();
      if (error === false) {
        return;
      }
      if (!isEdit) {
        const res = await configServ.createRole(dataToSend);
        if (res.status === 200) {
          // setDialogOpen(true);
          // setDialogContent(res.message);

          Swal.fire({
            position: "top",
            icon: "success",
            title: "res.message",
            showConfirmButton: false,
            timer: 1500,
          });

          setIsChanged(!isChanged);
        } else {
          setDialogContent(res.message || "Failed to create Role.");
        }
      } else {
        const res = await configServ.updateRole({
          name: formData.name,
          role_id: formData.id,
          admin_id: user_id,
          is_super: is_super,
          permissions: permissions,
        });
        if (res.status === 200) {
          // setDialogOpen(true);
          // setDialogContent(res.message);
          Swal.fire({
            position: "top",
            icon: "success",
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
            didOpen: () => {
              // SweetAlert ke popup ka z-index adjust karein
              document.querySelector(".swal2-container").style.zIndex =
                "100001";
            },
          });
          setIsChanged(!isChanged);
        } else {
          setDialogContent(res.message || "An error occurred.");
        }
      }
      clear();
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(triggerLoader());
    }
  };

  const handleFormValidation = async () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "required";
    }

    // Validate Permissions
    const permissions = Object.values(selectedPermissions).flat();
    if (permissions.length === 0) {
      errors.permissions = "required";
      toast.error("At least one permission must be selected.");
    }

    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleEdit = (param) => {
    try {
      scrollToTop();
      setFormData(param);
      // Map permissions to match getPageAccessControlsList structure
      const updatedPermissions = {};
      param.permissions.forEach((permission) => {
        const pageAccessControl = permission.PageAccessControl;
        if (!updatedPermissions[pageAccessControl]) {
          updatedPermissions[pageAccessControl] = [];
        }
        updatedPermissions[pageAccessControl].push(permission.id);
      });

      setSelectedPermissions(updatedPermissions);
      setIsEdit(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePermission = () => {
    setPermissionOpen(true);
  };

  const handleClosePermission = () => {
    setPermissionOpen(false);
  };

  const clear = () => {
    setFormData({ name: "" });
    setIsEdit(false);
    setValidationErrors({});
    setPermissionOpen(false);
    setSelectedPermissions({}); // Reset all selected permissions
    scrollToTop();
  };

  //#endregion

  return (
    <>
      <Permissions open={permissionOpen} handleClose={handleClosePermission} />
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
      <motion.div
        style={{
          width: "100%",
          backgroundColor: "#e3f3fc",
          padding: 20,
          borderRadius: "10px",
          border: "none",
        }}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.1, delay: 0.1 }}
      >
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={5} md={4}>
            <TextField
              fullWidth
              size="small"
              sx={{ backgroundColor: "white" }}
              label="Role Name*"
              name="name"
              onChange={handleChange}
              value={formData.name}
              error={!!validationErrors.name}
            />
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: "#fff8f9",
              borderRadius: "10px",
              border: "none",
            }}
            sx={{ marginTop: 2, marginLeft: 2 }}
          >
            {getPageAccessControlsList.map((pageAccess) => (
              <Grid item xs={12} key={pageAccess.PageAccessControl}>
                {/* Card-Like Container */}
                <Paper
                  elevation={3}
                  sx={{ padding: 3, marginBottom: 3, borderRadius: 2 }}
                >
                  {/* Title and Buttons */}
                  <Stack direction="row" spacing={2} alignItems="flex-start">
                    {/* Page Access Title */}
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        color: "darkblue",
                        borderBottom: "2px solid #ccc",
                        paddingBottom: "4px",
                        marginBottom: "8px",
                      }}
                    >
                      {pageAccess.PageAccessControl.toUpperCase()}
                    </Typography>

                    {/* Action Buttons */}
                    <Stack direction="row" spacing={2}>
                      <Button
                        size="small"
                        onClick={() =>
                          handleSelectAll(pageAccess.PageAccessControl)
                        }
                        sx={{
                          backgroundColor: "lightpink",
                          color: "white",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          "&:hover": { backgroundColor: "hotpink" },
                        }}
                      >
                        Select All
                      </Button>
                      <Button
                        size="small"
                        onClick={() =>
                          handleDeselectAll(pageAccess.PageAccessControl)
                        }
                        sx={{
                          backgroundColor: "lightpink",
                          color: "white",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          "&:hover": { backgroundColor: "hotpink" },
                        }}
                      >
                        Deselect All
                      </Button>
                    </Stack>
                  </Stack>

                  {/* Permissions Grid */}
                  <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    {pageAccess.permissions.map((permission) => (
                      <Grid item xs={6} sm={4} md={3} key={permission.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedPermissions[
                                  pageAccess.PageAccessControl
                                ]?.includes(permission.id) || false
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  pageAccess.PageAccessControl,
                                  permission.id
                                )
                              }
                            />
                          }
                          label={permission.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="space-between" px={2}>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button
                onClick={clear}
                variant="contained"
                color="secondary"
                startIcon={<RotateLeftIcon />}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSubmit}
                autoFocus
                variant="contained"
                color={isEdit ? "success" : "primary"}
                startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
              >
                {isEdit ? "Update" : "Save"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>

      <Divider
        sx={{
          my: "2rem",
          backgroundColor: "#33333377",
        }}
      />

      <RoleTable handleEdit={handleEdit} isChanged={isChanged} />
    </>
  );
};

export default CreateRole;
