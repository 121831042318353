import React, { memo, useEffect, useState } from "react";
import SalesOrderCard from "../../Components/Card/SalesOrderCards/SalesOrderCard";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import SalesOrderTable from "../../Components/Card/SalesOrderCards/SalesOrderTable";
import { useDispatch } from "react-redux";
import { rSetShowFab } from "../../redux/reducers/GlobalVariables";

const SalesOrder = () => {
  //#region code
  const { company_id, is_super, is_admin, permissions } = useSelector(
    (state) => state.GlobalVariables
  );
  const open = useSelector((state) => state.sideExpand.open);
  const [tableData, setTableData] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [editData, setEditData] = useState({});
  const dispatch = useDispatch();

  // const fetchSalesOrderList = async () => {
  //     const data = {
  //         'company_id': company_id
  //     }
  //     try {
  //         const result = await configServ.salesOrderList(data);
  //     }
  //     catch (err) {
  //         console.log(err)
  //         // setMiniLoader(false)
  //     }
  // }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    //     fetchSalesOrderList();
    scrollToTop();
  }, []);

  const handlePopup = () => {
    if (isCreate === true) {
      clear();
    }
    dispatch(rSetShowFab(isCreate));
    setIsCreate(!isCreate);
  };

  const handleEditData = (data) => {
    dispatch(rSetShowFab(isCreate));
    setIsEdit(true);
    setIsCreate(true);
    setEditData(data);
  };

  const clear = () => {
    setEditData({});
  };
  //#endregion

  return (
    <>
      <Container>
       
          <CardHeadMenu
            prefix={"Na"}
            suffix={"Na"}
            title={"Sales Order"}
            handlePopup={handlePopup}
            isEdit={isEdit}
            create={isCreate}
            showCreate={Boolean(is_super || is_admin || permissions?.includes(10))}
          />
      
        {isCreate && (
          <SalesOrderCard
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editData={editData}
            clear={clear}
            setIsChanged={setIsChanged}
            isChanged={isChanged}
            isCreate={isCreate} // State passed as a prop
            setIsCreate={setIsCreate} // Function passed as a prop
          />
        )}
        <SalesOrderTable
          handleEditData={handleEditData}
          isChanged={isChanged}
        />
      </Container>
    </>
  );
};
export default SalesOrder;
