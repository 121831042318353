import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const DownloadLeadsPdf = async(companyName, leadList, logo) => {
  const headers = [
    "S.No.",
    "NAME",
    "ADDRESS",
    "MOBILE NO.",
    "SOURCE",
    "DATE",
  ];

  // Format data with S.No and formatted currency
  const data = leadList.map((order, index) => [
    (index + 1).toString(), // S.No. column
    order.name,
    order.address,
    order.mobile,
    order.source,
    order.lead_date,
  ]);

  const doc = new jsPDF();

  // Add report title (e.g., "Sales Summary")
  doc.setFontSize(18);
  doc.text("Lead List", 105, 10, { align: "center" }); // Centered title at the top

  // Add company logo (position: x=14, y=10)
  if (logo) {
    doc.addImage(logo, "PNG", 5, 5, 10, 10); // Adjust width and height as per your logo size
  }

  // Add company name (position: below the title and logo)
  doc.setFontSize(15);
  doc.text(companyName, 17, 12); // Adjust x, y for better positioning

  // Add a line break for spacing before the table
  doc.setFontSize(12); // Reset font size after company name

  // Add the table with headers and data (position: below the logo and company name)
  doc.autoTable({
    startY: 20, // Adjust the starting Y position to leave space for logo, company name, and title
    head: [headers],
    body: data,
  });

  // // Add total amount at the end
  // const finalY = doc.autoTable.previous.finalY;
  // doc.setFontSize(12);
  // doc.text(`Total Amount: Rs. ${Number(totalAmount).toFixed(2)}`, 14, finalY + 10);

  // Save the PDF
  const date = new Date().toLocaleDateString();
  doc.save(`LeadList_${date}.pdf`);

  // Hide backdrop after PDF generation
  //   setOpenBackdrop(false);
};

export default DownloadLeadsPdf;
