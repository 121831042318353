import React, { memo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  TableSortLabel,
  Grid,
  Typography,
  NativeSelect,
  InputLabel,
  FormControl,
  CircularProgress,
  Stack,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import SaveIcon from "@mui/icons-material/Save";
import configServ from "../../../services/config";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import AddLineItem from "../../masterEntry/ItemEditOptions/AddLineItem";
import useDebounce from "../../hooks/useDebounce";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchBox from "../../Search/SearchBox";
import ItemLedgerPopup from "../../Inventory/ItemLedgerPopup";
import { useLocation, useNavigate } from "react-router-dom";

import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";

import useDeviceDetect from "../../../Components/Detect/useDeviceDetect ";
import CardHeadMenu from "../CardHeadMenu/CardHeadMenu";
import { toast } from "react-hot-toast";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import LoaderNew from "../../Loader/LoaderNew";
import MobileView from "../../views/MobileView";
import DesktopView from "../../views/DesktopView";
import * as XLSX from "xlsx";
import LoaderBackdrop from "../../Loader/LoaderBackdrop";
import DownloadStockUpdate from "../../Download/DownloadStockUpdate";

//FUNCTION
const StockUpdate = ({ handleEdit, isChanged, pagination = true }) => {
  //#region code
  const { company_id, user_id, is_admin, companyName } = useSelector(
    (state) => state.GlobalVariables
  );
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [itemList, setItemList] = useState([]);
  //const [filterItemList, setFilterItemList] = useState([]);
  const [searchBy, setSearchBy] = useState("item_name");
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [openGenerateLineItems, setOpenGenerateLineItems] = useState(false);
  // const [lineItem, setLineItem] = useState({});
  const [openItemLedger, setOpenItemLedger] = useState(false);
  const [itemLedger, setItemLedger] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemLedgerLoading, setItemLedgerLoading] = useState(true);

  const location = useLocation();
  const [redirectItemName, setRedirectItemName] = useState(location.state);
  const [orderBy, setOrderBy] = useState("fname");
  const [order, setOrder] = useState("asc");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (redirectItemName) {
      setSearchBy("item_name");
      setSearch(redirectItemName);
      fetchItemList("item_name", redirectItemName);
    } else {
      setSearchBy("item_name");
      setSearch("");
    }
  }, [redirectItemName]);

  useEffect(() => {
    if (!redirectItemName) {
      fetchItemList();
    }
  }, [isChanged, page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    if (!redirectItemName) {
      if (page === 0) {
        fetchItemList();
      } else {
        setPage(0);
      }
    }
  }, [debounceSearch]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchItemList = async (searchByItem = null, ItemName = null) => {
    try {
      setLoading(true);
      const dataToSend = {
        company_id: company_id,
        search_by: searchByItem ? searchByItem : searchBy,
        search: ItemName ? ItemName : debounceSearch,
        per_page: rowsPerPage,
        page: page,
        sort_by: orderBy,
        order,
      };
      const res = await configServ.getStockList(dataToSend);
      if (res.status === 200) {
        const result = res.data;
        if (result.length > 0) {
          setItemList(result);
          setCount(res.total);
        } else {
          setItemList([]);
          setCount(0);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // download excel
  const downloadExcel = async () => {
    try {
      // Show loading indicator
      setOpenBackdrop(true);

      // Prepare data to send to the backend
      const dataToSend = {
        company_id: company_id,
        download: true, // Fetch all data
      };

      // Fetch data from backend
      const res = await configServ.getStockList(dataToSend);
      // console.log(res);
      if (res.status === 200) {
        const allData = res.data; // Access the data array
        if (!allData || allData.length === 0) {
          Swal.fire({
            icon: "info",
            title: "No data available to download",
          });
          setOpenBackdrop(false);
          return;
        }

        // Map the data to include necessary fields for Excel
        const filteredData = allData.map((item) => ({
          ITEM_NAME: item.item_name,
          ITEM_CODE: item.item_code,
          UNIT: item.unit,
          ACTUAL_QTY: item.actualquantity, // Ensure actualquantity is coming from backend
        }));

        // Create a new workbook and add a worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        // Add title and company name
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            ["Stock Update"], // Title
            [`Company Name: ${companyName}`], // Company Name
          ],
          { origin: "A1" } // Start from cell A1
        );

        // Add filtered data to worksheet starting from row 4
        XLSX.utils.sheet_add_json(worksheet, filteredData, { origin: "A4" });

        // Set column widths for better readability
        const wscols = [
          { wch: 30 }, // ITEM_NAME
          { wch: 20 }, // ITEM_CODE
          { wch: 15 }, // UNIT
          { wch: 15 }, // ACTUAL_QTY
        ];
        worksheet["!cols"] = wscols;

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Summary");

        // Generate the file name with the current date
        const currentDate = new Date().toLocaleDateString().replace(/\//g, "-");
        XLSX.writeFile(workbook, `stock_summary_${currentDate}.xlsx`);

        // Success popup
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your Excel file has been downloaded",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to fetch data",
        });
      }
    } catch (error) {
      console.error("Error downloading Excel:", error);
      Swal.fire({
        icon: "error",
        title: "An error occurred while downloading the file",
      });
    } finally {
      // Hide loading indicator
      setOpenBackdrop(false);
    }
  };

  const handleDownloadPdf = async () => {
    // Prepare data to send to the backend
    const dataToSend = {
      company_id: company_id,
      download: true, // Fetch all data
    };

    try {
      setOpenBackdrop(true); // Show loading
      const res = await configServ.getStockList(dataToSend);

      if (res.status === 200) {
        const stockData = res.data;

        if (stockData.length === 0) {
          Swal.fire({
            icon: "info",
            title: "No data available to download",
          });
          setOpenBackdrop(false);
          return;
        }

        // Call the function to generate the PDF
        DownloadStockUpdate(stockData, companyName, setOpenBackdrop);
      } else {
        alert('Failed to fetch stock data');
        setOpenBackdrop(false);
      }
    } catch (error) {
      console.error('Error downloading stock update PDF:', error);
      alert('An error occurred while fetching stock data');
      setOpenBackdrop(false);
    }
  };

  const handleCustomerClick = (params) => {
    try {
      const data = params;
      navigate("/master-entry/item", { state: data });
    } catch (e) {
      console.log(e);
    }
  };

  const headers = [
    { id: "itemName", label: "ITEM NAME" },
    { id: "itemCode", label: "ITEM CODE" },
    { id: "unit", label: "UNIT" },
    { id: "actualQty", label: "ACTUAL QTY" },
    { id: "addQty", label: "ADD QTY" },

    { id: "status", label: "STATUS" },
    { id: "action", label: "ACTION" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5", // Set the header background color to blue
    color: "white",
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleItemLedger = async (params) => {
    try {
      setOpenItemLedger(true);
      const item = itemList.find((x) => x.id === params);
      const dataToSend = {
        item_id: params,
        //per_page: 10,
        //page: 0
      };
      const res = await configServ.getItemLedger(dataToSend);
      if (res.status === 200) {
        setItemLedger(res.data);
        setSelectedItem(item);
      } else {
        setItemLedger([]);
        setSelectedItem(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setItemLedgerLoading(false);
    }
  };

  const handleItemLedgerClose = () => {
    setOpenItemLedger(false);
    setItemLedger([]);
    setSelectedItem(null);
    setItemLedgerLoading(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleQuantityChange = (event, rowIndex, data) => {
    try {
      const newQuantity = event.target.value;

      // Update the new quantity for the specific row
      const updatedItemList = [...itemList];
      const newStatus = CheckAndReturn.isNullOrUndefined(
        updatedItemList[rowIndex].newStatus
      )
        ? Boolean(data.status)
        : updatedItemList[rowIndex].newStatus;
      updatedItemList[rowIndex].newQuantity = newQuantity;
      updatedItemList[rowIndex].newStatus = newStatus;
      updatedItemList[rowIndex].status = newStatus;

      setItemList(updatedItemList);
    } catch (e) {
      console.log(e);
    }
  };

  const handleStatusChange = (event, rowIndex, data) => {
    try {
      const newStatus = event.target.checked;

      // Update the new quantity for the specific row
      const updatedItemList = [...itemList];
      const newQuantity = CheckAndReturn.isNullOrUndefined(
        updatedItemList[rowIndex].newQuantity
      )
        ? 0
        : updatedItemList[rowIndex].newQuantity;
      updatedItemList[rowIndex].newQuantity = newQuantity;
      updatedItemList[rowIndex].newStatus = newStatus;
      updatedItemList[rowIndex].status = newStatus;

      setItemList(updatedItemList);
    } catch (e) {
      console.log(e);
    }
  };

  const confirmUpdate = () => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        // If confirmed, call the handleUpdateQuantity function
        handleUpdateQuantity();
        // Swal.fire("Saved!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleUpdateQuantity = async () => {
    // Filter the rows where the newQuantity has been changed and is a valid number
    const itemsToUpdate = itemList
      .filter(
        (row) =>
          (row.newQuantity && !isNaN(row.newQuantity)) ||
          row.newStatus === true ||
          row.newStatus === false
      )
      .map((row) => ({
        id: row.id,
        quantity: parseInt(row.newQuantity ?? 0),
        status: Boolean(row.newStatus ?? 0),
      }));

    if (itemsToUpdate.length > 0) {
      // Prepare the data to send to the backend
      const data = {
        user_id: user_id,
        stocks: itemsToUpdate,
      };

      try {
        // Send all changes in a single API call
        const res = await configServ.updateStockQuantity(data);
        if (res.status === 200) {
          fetchItemList();
          toast.success("Quantities updated successfully!");
        } else {
          toast.error("Failed to update quantities.");
        }
      } catch (error) {
        toast.error("Error updating quantities.");
        console.error("Error updating quantities:", error);
      }
    }
  };
  //#endregion

  return (
    <>
      <CardHeadMenu
        prefix={"Na"}
        suffix={"Na"}
        title={"STOCK UPDATE"}
        showCreate={false}
      />
      <LoaderBackdrop open={openBackdrop} />
      <ItemLedgerPopup
        openItemLedger={openItemLedger}
        handleItemLedgerClose={handleItemLedgerClose}
        itemLedger={itemLedger}
        selectedItem={selectedItem}
        itemLedgerLoading={itemLedgerLoading}
      />

      {/* <AddLineItem itemData={lineItem} handleClose={handleCloseLineItems} open={openGenerateLineItems} /> */}
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={6} ml={0} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Item List: {count}
          </Typography>
        </Grid>
        {redirectItemName ? (
          <>
            <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                title="Referesh"
                color="success"
                startIcon={<RefreshIcon sx={{ ml: 1, color: "white" }} />}
                onClick={() => {
                  setRedirectItemName(null);
                }}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={3}>
              <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Search By
                  </InputLabel>
                  <NativeSelect
                    defaultValue={"contact_name"}
                    onChange={handleSearchBy}
                    inputProps={{
                      name: "searchBy",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={"item_name"}>Item Name</option>
                    <option value={"item_code"}>Item Code</option>
                    <option value={"location_name"}>Location</option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              mr={{ xs: 0, md: 0 }}
              display="flex"
              justifyContent={"flex-end"}
              alignItems="center"
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <SearchBox search={search} setSearch={setSearch} />

                <IconButton
                  sx={{
                    borderRadius: 10,
                    width: "fit-content",
                    transition: "100ms",
                    cursor: "pointer",
                    padding: 0,
                    m: 0.5,
                    ":hover": { backgroundColor: "#99999955" },
                  }}
                  color="#success"
                  onClick={() => confirmUpdate()}
                  // Disable if no data or invalid data
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: 10,
                    width: "fit-content",
                    transition: "100ms",
                    cursor: "pointer",
                    padding: 0,
                    m: 0.5,
                    ":hover": { backgroundColor: "#99999955" },
                  }}
                  color="primary"
                  onClick={handleMenuClick}
                  // onClick={() => confirmUpdate()}
                  // Disable if no data or invalid data
                >
                  <BrowserUpdatedIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      downloadExcel();
                      handleMenuClose();
                    }}
                  >
                    Excel Download
                  </MenuItem>
                  <MenuItem
                  onClick={() => {
                    handleDownloadPdf();
                    handleMenuClose();
                  }}
                  >
                    PDF Download
                  </MenuItem>
                </Menu>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>

      <MobileView>
        <Grid container spacing={2}>
          {loading ? (
            <Grid item xs={12} textAlign="center">
              <LoaderNew />
            </Grid>
          ) : itemList.length > 0 ? (
            itemList.map((row, rowIndex) => (
              <Grid item xs={12} key={rowIndex}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Stack spacing={1}>
                    <Box sx={{ fontWeight: "bold" }}>
                      <strong>S.No.:</strong>{" "}
                      {page * rowsPerPage + rowIndex + 1}.
                    </Box>
                    <Box>
                      {" "}
                      <strong>Item Name:</strong>{" "}
                      <span
                        className="linkStyle"
                        onClick={() => handleCustomerClick(row.item_name)}
                      >
                        {row.item_name}
                      </span>
                    </Box>
                    <Box>
                      {" "}
                      <strong>Item Code:</strong> {row.item_code}
                    </Box>
                    {/* <FetchLocationName id={row.location_id} /> */}
                    <Box>
                      {" "}
                      <strong>Unit: </strong> {row.unit}
                    </Box>
                    <Box>
                      {" "}
                      <strong>Quantity:</strong> {row.actualquantity}
                    </Box>
                    <Box>
                      <strong>Add Quantity:</strong>{" "}
                      <TextField
                        type="number"
                        value={row.newQuantity || ""}
                        onChange={(e) => handleQuantityChange(e, rowIndex, row)}
                        sx={{
                          width: "70px", // Adjust width if needed
                          backgroundColor: "white", // white background
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "black", // Transparent border by default
                            },
                            "&:hover fieldset": {
                              borderColor: "green", // Transparent border on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "blue", // Blue border when focused
                              borderWidth: "1px", // Border width on focus
                            },
                          },
                          "& .MuiInputBase-input": {
                            padding: "5px", // Adjust padding if needed
                          },
                        }}
                        inputProps={{ min: 0 }}
                      />
                    </Box>
                    <Box>
                      <strong>Status:</strong>{" "}
                      <FormControlLabel
                        disabled={Boolean(!is_admin)}
                        control={<Checkbox />}
                        label="Status"
                        name="status"
                        checked={!!row.status}
                        onChange={(e) => handleStatusChange(e, rowIndex, row)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            // Toggle the Checkbox value on Enter key press
                            handleStatusChange({
                              target: {
                                name: "status",
                                value: "on",
                                type: "checkbox",
                                checked: !row.status,
                              },
                              rowIndex,
                              row,
                            });
                          }
                        }}
                      />
                    </Box>

                    {/* <Box>Cost Price: {row.costprice}</Box> */}
                    {/* <Box>
                        Status:{" "}
                        {row.status === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Box> */}
                    <Box>
                      <Stack direction="row">
                        <VisibilityIcon
                          sx={{
                            borderRadius: 10,
                            width: "fit-content",
                            transition: "100ms",
                            cursor: "pointer",
                            padding: 0,
                            m: 0.5,
                            ":hover": { backgroundColor: "#99999955" },
                          }}
                          color="primary"
                          onClick={() => handleItemLedger(row.id)}
                        />
                        {/* Additional buttons or actions */}
                      </Stack>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} textAlign="center">
              <Box sx={{ color: "red", fontWeight: 600 }}>No data found.</Box>
            </Grid>
          )}
        </Grid>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </MobileView>

      <DesktopView>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            // height: "100vh", // Set full height
            // display: { xs: "none", sm: "none", lg: "block" },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                    sx={headerCellStyle}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading === true ? (
              <TableRow>
                <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                  <Box>
                    <LoaderNew />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {itemList.length > 0 ? (
                  itemList.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell sx={cellStyle}>
                        {page * rowsPerPage + rowIndex + 1}
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        <span
                          className="linkStyle"
                          onClick={() => handleCustomerClick(row.item_name)}
                        >
                          {row.item_name}
                        </span>
                      </TableCell>

                      <TableCell sx={cellStyle}>{row.item_code}</TableCell>
                      {/* <FetchLocationName id={row.location_id} /> */}
                      <TableCell sx={cellStyle}>{row.unit}</TableCell>
                      <TableCell sx={cellStyle}>{row.actualquantity}</TableCell>
                      <TableCell sx={cellStyle}>
                        <TextField
                          type="number"
                          value={row.newQuantity || ""}
                          onChange={(e) =>
                            handleQuantityChange(e, rowIndex, row)
                          }
                          sx={{
                            width: "70px", // Adjust width if needed
                            backgroundColor: "transparent", // Transparent background
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "black", // Transparent border by default
                              },
                              "&:hover fieldset": {
                                borderColor: "green", // Transparent border on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "blue", // Blue border when focused
                                borderWidth: "1px", // Border width on focus
                              },
                            },
                            "& .MuiInputBase-input": {
                              padding: "5px", // Adjust padding if needed
                            },
                          }}
                          inputProps={{ min: 0 }}
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          disabled={Boolean(!is_admin)}
                          control={<Checkbox />}
                          label="Status"
                          name="status"
                          checked={!!row.status}
                          onChange={(e) => handleStatusChange(e, rowIndex, row)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              // Toggle the Checkbox value on Enter key press
                              handleStatusChange({
                                target: {
                                  name: "status",
                                  value: "on",
                                  type: "checkbox",
                                  checked: !row.status,
                                },
                                rowIndex,
                                row,
                              });
                            }
                          }}
                        />
                      </TableCell>

                      {/* <TableCell sx={cellStyle}>{row.costprice}</TableCell> */}
                      {/* <TableCell sx={cellStyle}>
                          {row.status === 1 ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </TableCell> */}
                      <TableCell sx={cellStyle}>
                        <Stack direction={"row"}>
                          {/* <IconButton
                              sx={{
                                borderRadius: 10,
                                width: "fit-content",
                                transition: "100ms",
                                cursor: "pointer",
                                padding: 0,
                                m: 0.5,
                                ":hover": { backgroundColor: "#99999955" },
                              }}
                              color="primary"
                              onClick={() => handleUpdateQuantity(rowIndex)}
                              disabled={
                                !row.newQuantity || isNaN(row.newQuantity)
                              }
                            >
                              <BrowserUpdatedIcon />
                            </IconButton> */}

                          <VisibilityIcon
                            sx={{
                              borderRadius: 10,
                              width: "fit-content",
                              transition: "100ms",
                              cursor: "pointer",
                              padding: 0,
                              m: 0.5,
                              ":hover": { backgroundColor: "#99999955" },
                            }}
                            color="primary"
                            onClick={() => handleItemLedger(row.id)}
                          />
                          {/* <Tooltip title='Generate Line Item' placement='top' arrow>
                                                                <IconButton onClick={()=>handleLineItems(row)} sx={{'&hover':{backgroundColor:'#99999955'}}}>
                                                                <Inventory2Icon color='secondary'/>
                                                                </IconButton>
                                                            </Tooltip> */}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                      <Box sx={{ color: "red", fontWeight: 600 }}>
                        No data found.
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 1 * emptyRows }}>
                    <TableCell colSpan={headers.length + 2} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </DesktopView>
    </>
  );
};

export default memo(StockUpdate);
