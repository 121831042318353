import { Box } from '@mui/material';
import React, { memo } from 'react';

const DesktopView = ({children}) => {
  return (
    <Box display={{ xs: 'none', sm: 'block' }} width={'100%'}>
      {children}
    </Box>
  )
}

export default memo(DesktopView);
