import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import CreateRoles from './CreateRoles';

//FUNCTION
const RolePopup = ({ open, handleClose }) => {
  const handleCreateRoleClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleCreateRoleClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{display:'flex', alignItems:'center', justifyContent:'center', fontWeight:'bolder'}}>Roles & Permissions</DialogTitle>
      <DialogContent>
        <CreateRoles handleClose={handleCreateRoleClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreateRoleClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RolePopup;
