import { Box } from '@mui/material';
import React, { memo } from 'react';

const MobileView = ({children}) => {
  return (
    <Box display={{ xs: 'block', sm: 'none' }} width={'100%'}>
      {children}
    </Box>
  )
}

export default memo(MobileView);
