import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  IconButton,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { changeNoRoomService } from "../../../redux/reducers/GlobalVariables";

//FUNCTION
const CardHeadMenu = ({
  prefix,
  suffix,
  title,
  handleReset,
  handleSave,
  handlePopup,
  isEdit,
  handleOTI,
  create,
  showCreate = true,
}) => {
  return (
    <>
      <Grid container alignItems="center" sx={{ pt: 0 }} spacing={1}>
        <Grid item xs={2} md={2}>
          {/* <FormControl fullWidth variant="standard" sx={{ mb: 1 }}>
                        <InputLabel id="demo-simple-select-standard-label">Application</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={app}
                            label="Application"
                            onChange={(e) => handleApplication(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'dcrm'}>DCRM</MenuItem>
                            <MenuItem value={'noroom'}>NoRoom</MenuItem>
                        </Select>
                    </FormControl> */}
        </Grid>
        <Grid
          container
          item
          xs={8}
          md={8}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            variant="button"
            sx={{
              textAlign: "center",
              fontWeight: "bolder", // Add the fontWeight property to make the text bolder
              fontSize: { xs: "16px", md: "24px" }, // Add the fontSize property to increase the text size
              color: "#1e61b4",
            }}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1 }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {showCreate === true && (
            <Button
              variant="contained"
              color={!create ? "primary" : "error"}
              onClick={handlePopup}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: {
                  xs: "2px 4px", // smaller padding for mobile
                  sm: "5px 10px", // slightly larger padding for small devices
                  md: "5px 10px", // default padding for medium devices and up
                },
                fontSize: {
                  xs: "0.15rem", // smaller font size for mobile
                  sm: "0.55rem", // default font size for small devices
                  md: "0.65rem", // default font size for medium devices and up
                },
                minWidth: {
                  xs: "auto", // smaller min-width for mobile
                  sm: "auto", // default min-width for small devices
                  md: "auto", // default min-width for medium devices and up
                },
              }}
              title={!create ? "Add" : "Close"}
            >
              {!create ? <AddIcon /> : <CloseIcon />}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CardHeadMenu;
