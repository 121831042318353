import React, { useState } from "react";
import LeadsTable from "../../Components/leads/LeadsTable";
import { useNavigate } from "react-router-dom";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import LeadsForm from "../../Components/leads/LeadsForm";
import { useSelector, useDispatch } from "react-redux";

const LeadsPage = () => {
  //#region code
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const { is_super, is_admin, permissions } = useSelector(
    (state) => state.GlobalVariables
  );

  const handleEdit = (data) => {
    setEditData(data);
    setOpen(true);
  };

  const handleOpen = () => {
    try {
      setOpen(!open);
    } catch (e) {
      console.log(e);
    }
  };

  //#endregion

  return (
    <>
      <CardHeadMenu
        prefix={"Na"}
        suffix={"Na"}
        title={"LEADS"}
        handlePopup={handleOpen}
        create={open}
        showCreate={Boolean(is_super || is_admin || permissions?.includes(42))}
      />
      <LeadsForm
        open={open}
        setOpen={setOpen}
        editData={editData}
        setEditData={setEditData}
      />
      <LeadsTable handleEdit={handleEdit} isChanged={open} />
    </>
  );
};

export default LeadsPage;
